import { format } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';

import { Actions as AUTHENTICATION_ACTIONS } from '../../store/actions/authenticationActions';

const OrderForm = ({ billingDetails, organisation, promotionCode }) => {
  const ukVAT = 0.2;
  const dateOfToday = format(new Date(), 'dd MMMM yyyy');

  let priceWithCoupon = 0.0;

  if (organisation?.['stripeCoupon'] && promotionCode) {
    priceWithCoupon = organisation['subscriptionPrice'] * (promotionCode['percent_off'] / 100);
  }

  console.log('priceWithCoupon', priceWithCoupon);

  const grossFinalPrice = organisation.subscriptionPrice - priceWithCoupon;
  const tax = billingDetails['country'] === 'United Kingdom' ? grossFinalPrice * ukVAT : 0.0;
  const netFinalPrice = grossFinalPrice + tax;

  const vatNumber = billingDetails['vatNumber'] === '' ? 'Not specified' : billingDetails['vatNumber'];

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <img
          src='https://s3.eu-west-2.amazonaws.com/downloads.compareyourfootprint.com/branding/compareyourfootprint/Picture+1.jpg'
          alt=''></img>
      </div>
      <div>
        <p style={{ fontSize: 11, color: 'black', textAlign: 'justify' }} className='orderForm-intro'>
          This Order Form ("Order Form") is between <strong>COMPARE YOUR FOOTPRINT LTD</strong>, a company incorporated in
          Scotland (Registered No. SC689618) with its registered office at 7 Ferrymuir Lane, South Queensferry, Scotland,
          EH30 9PA (<strong>“CYF”</strong>) and <strong>{organisation.name}</strong> a company incorporated in{' '}
          <strong>{billingDetails.country}</strong> under number <strong>{vatNumber}</strong> with its registered office at{' '}
          <strong>{billingDetails.address}</strong> (<strong>"the Customer"</strong>) and relates to the Services Agreement
          entered into between CYF and the Customer dated <strong>{dateOfToday}</strong> (<strong>the "Agreement"</strong>).
        </p>
        <ol>
          <li style={{ textAlign: 'justify', fontSize: 11, color: 'black', paddingBottom: 10 }}>
            <strong>The parties hereby agree as follows:</strong> Order Form. This document constitutes an "Order Form" under
            the Contract, and this Order Form and the Services and Professional Services contemplated herein are subject to
            the terms and provisions of the Contract.
            <ol style={{ paddingLeft: 10 }}>
              <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                1.1 In this Order Form, unless specified otherwise, words and phrases shall have the same meanings as those
                in the Contract.
              </li>
              <li style={{ listStyle: 'none' }}>
                1.2 If there is any conflict between the terms of this Order form and the terms of the Contract, then the
                terms of this Order Form shall prevail.
              </li>
            </ol>
          </li>
          <li style={{ textAlign: 'justify', fontSize: 11, color: 'black' }}>
            <strong>Subscription Services</strong>
            <ol style={{ paddingLeft: 10 }}>
              <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                2.1 Subscription Type: <strong>{organisation.subscriptionPlan} plan. </strong>
              </li>
              <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                2.2 Subscription Level: <strong>{organisation.revenueRange} </strong>
              </li>
              <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                2.3 Subscription Term: 12 months. Your subscription will renew automatically once the term has ended, unless
                a new Order Form supersedes this one or the Subscription Services are terminated by either party.
              </li>
              <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                2.4 Deliverables:
                <ol style={{ paddingLeft: 10 }}>
                  <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                    2.4.1 Access to enter data into Compare Your Footprint online carbon footprint calculator and
                    benchmarking software and receive carbon emissions results and reports - available at
                    app.compareyourfootprint.com.
                  </li>
                  <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                    2.4.2 Unlimited facilities / project / usage data and carbon emissions reporting
                  </li>
                  <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                    2.4.3 Benchmark your organisations against others in your industry (if data is available)
                  </li>
                  <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                    2.4.4 Access to dedicated support team at support@compareyourfootprint.com
                  </li>
                  <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                    2.4.4 Access to dedicated support team at support@compareyourfootprint.com
                  </li>
                  <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                    2.4.5 If your organisation reduces its carbon emissions after the first subscription term, you could be
                    eligible for an equivalent % discount to future subscription fees (if applicable)
                  </li>
                  <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                    2.4.6 Other features and benefits available at compareyourfootprint.com/register
                  </li>
                  <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                    2.4.7 Invitation to Sustainability Solved online community sustainabilitysolved.org offering
                    <ul style={{ paddingLeft: 30 }}>
                      <li style={{ paddingBottom: 5 }}>
                        Free support, guidance and resources on carbon footprinting, carbon reduction and environmental
                        management
                      </li>
                      <li style={{ paddingBottom: 5 }}>Access to sustainable business podcasts</li>
                      <li style={{ paddingBottom: 5 }}>Access to online sustainability training</li>
                      <li style={{ paddingBottom: 5 }}>
                        Opportunity to network with professionals across different industries.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                2.5 Subscription Service Fees:
                <ol>
                  <li style={{ listStyle: 'none', paddingBottom: 5 }}>2.5.1 Fees set out in the table below</li>
                  <li style={{ listStyle: 'none', paddingBottom: 5 }}>
                    2.5.2 Full Payment Terms set out in CYF Software License Agreement (Clause 13).
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <table style={{ margin: 'auto' }}>
          <thead style={{ border: '1px solid black', fontWeight: 'bold', color: 'black' }}>
            <tr>
              <th style={{ borderRight: '1px solid black', padding: 5, fontSize: 12 }}>Subscription</th>
              <th style={{ borderRight: '1px solid black', padding: 5, fontSize: 12 }}>Fee</th>
              <th style={{ borderRight: '1px solid black', padding: 5, fontSize: 12 }}>Term</th>
              <th style={{ borderRight: '1px solid black', padding: 5, fontSize: 12 }}>Subtotal</th>
            </tr>
          </thead>
          <tbody style={{ border: '1px solid black', fontWeight: 'bold', color: 'black' }}>
            <tr>
              <td id='subscription-details' style={{ borderRight: '1px solid black', padding: 5, fontSize: 10 }}>
                Subscription services <br /> Type: {organisation.subscriptionPlan ? 'Unlimited' : 'Annual'} plan. <br />{' '}
                Level: {organisation.revenueRange}{' '}
              </td>
              <td style={{ borderRight: '1px solid black', padding: 5, fontSize: 10 }}>£ 0.00</td>
              <td style={{ borderRight: '1px solid black', padding: 5, fontSize: 10 }}>1</td>
              <td id='subscription-subtotal' style={{ borderRight: '1px solid black', padding: 5, fontSize: 10 }}>
                Subtotal <br /> £ {organisation.subscriptionPrice}.00
              </td>
            </tr>
          </tbody>
        </table>
        <p
          style={{
            color: 'red',
            fontSize: 11,
            fontWeight: 'bold',
            marginTop: 10,
            display: promotionCode?.['duration_in_months'] ? 'block' : 'none',
          }}>
          This coupon has a duration of {promotionCode?.['duration_in_months']} months in your first subscription
        </p>
        <div style={{ margin: '20px 20px 10px 10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <p style={{ color: 'black', fontSize: 11 }}>
            Subtotal{' '}
            <span style={{ paddingLeft: 40, fontWeight: 'bold', color: 'black', fontSize: 11 }}>
              £ {organisation.subscriptionPrice}.00{' '}
            </span>
          </p>
          <p style={{ color: 'black', fontSize: 11 }}>
            Discount{' '}
            <span style={{ paddingLeft: 40, fontWeight: 'bold', color: 'black', fontSize: 11 }}>
              £{priceWithCoupon.toFixed(2)}
            </span>
          </p>
          <p style={{ color: 'black', fontSize: 11 }}>
            Tax{' '}
            <span id='subscription-tax' style={{ paddingLeft: 40, fontWeight: 'bold', color: 'black', fontSize: 11 }}>
              £ {tax.toFixed(2)}
            </span>
          </p>
          <p style={{ color: 'black', fontSize: 11 }}>
            <strong>Total</strong>
            <span id='subscription-netPrice' style={{ paddingLeft: 40, fontWeight: 'bold', color: 'black', fontSize: 11 }}>
              £ {netFinalPrice.toFixed(2)}
            </span>
          </p>
        </div>
        <div style={{ fontSize: 11 }}>
          <ol start='3' style={{ fontSize: 11, color: 'black', textAlign: 'justify' }}>
            <li>
              <div>
                <p style={{ fontSize: 11, color: 'black' }}>
                  <strong>Definitions</strong>
                </p>
              </div>
              <p style={{ fontSize: 11, color: 'black', fontWeight: 'normal' }}>
                In this Order Form, unless specified otherwise, words and phrases shall have the same meanings as in the
                Contract, notwithstanding the forgoing, the following words and phrases shall have the meanings set opposite
                them as follows:{' '}
              </p>
              <ol style={{ listStyle: 'none', fontSize: 11, color: 'black', paddingLeft: 10 }}>
                <li style={{ paddingBottom: 5 }}>
                  1.1 <strong>"Annual Subscription"</strong> means a level of software license that allows the Customer to
                  create an annual set of carbon emissions results and download related PDF and Excel reports. For full list
                  of features and benefits visit{' '}
                  <a href='compareyourfootprint.com/register'>compareyourfootprint.com/register</a>
                </li>
                <li style={{ paddingBottom: 5 }}>
                  1.2 <strong>"CYF"</strong> means Compare Your Footprint, a carbon footprint calculation and benchmarking
                  software app.compareyourfootprint.com{' '}
                </li>
                <li style={{ paddingBottom: 5 }}>
                  1.3 <strong>“Benchmarking”</strong> means that the Customer can compare their organisation’s carbon
                  emissions against other companies in their industry, if the data is available. This will be shown in the
                  form of a ranking and chart within the carbon emissions results.
                </li>
                <li style={{ paddingBottom: 5 }}>
                  1.4 <strong>“Scope 1, 2 and 3 emissions”</strong> means Emissions are broken down into three categories by
                  the Greenhouse Gas Protocol in order to better understand the source. Breakdown of each source:
                  <ol style={{ listStyle: 'none', paddingLeft: 10 }}>
                    <li style={{ paddingBottom: 5 }}>
                      <strong>Scope 1</strong> – All Direct Emissions from the activities of an organisation or under their
                      control. Including fuel combustion on site such as gas boilers, fleet vehicles and air-conditioning
                      leaks.
                    </li>
                    <li style={{ paddingBottom: 5 }}>
                      <strong>Scope 2</strong> – Indirect Emissions from electricity purchased and used by the organisation.
                      Emissions are created during the production of the energy and eventually used by the organisation.
                    </li>
                    <li style={{ paddingBottom: 5 }}>
                      <strong>Scope 3</strong> – All Other Indirect Emissions from activities of the organisation, occuring
                      from sources that they do not own or control. These are usually the greatest share of the carbon
                      footprint, covering emissions associated with business travel, procurement, waste and water.
                    </li>
                  </ol>
                </li>
                <li>
                  1.5 <strong>“Unlimited Subscription”</strong> means a level of license that includes the ability to create
                  unlimited results (e.g. every month, quarter), ability to create unlimited facilities and access to an
                  account manager. For full list of features and benefits visit{' '}
                  <a href='compareyourfootprint.com/register'>compareyourfootprint.com/register</a>.
                </li>
              </ol>
            </li>
          </ol>
          <div style={{ marginLeft: 10 }}>
            <p style={{ color: 'black', fontSize: 12 }}>
              <strong>Special Conditions:</strong>
            </p>
            <p style={{ color: 'black', fontSize: 11 }}>
              If there is any conflict or inconsistency between the terms of this Order Form and the Agreement, the terms of
              the Order Form shall have precedence.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  newUserSubscription: (user, organisation) => dispatch(AUTHENTICATION_ACTIONS.newUserSubscription(user, organisation)),
});

const mapStateToProps = (state) => {
  const billingDetails = {
    address: state.payment.billingDetails && state.payment.billingDetails['address'],
    country: state.payment.billingDetails && state.payment.billingDetails['country'],
    vatNumber: state.payment.billingDetails && state.payment.billingDetails['vatNumber'],
  };

  const organisation = {
    name: state?.organisation?.details?.name,
    revenue: state?.profile?.registrationDetails?.organisation.revenue,
    revenueRange: state.profile?.registrationDetails?.organisation?.revenueRange,
    stripeCoupon: state.profile?.registrationDetails?.organisation?.stripeCoupon,
    subscriptionPlan: state.profile?.registrationDetails?.organisation?.subscriptionPlan,
    subscriptionPrice: state.profile?.registrationDetails?.organisation?.subscriptionPrice,
  };

  return {
    billingDetails,
    organisation,
    promotionCode: state?.payment?.stripeCoupon,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
