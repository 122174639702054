import {
  AccountBox,
  AdminPanelSettings,
  AlignHorizontalLeft,
  Apartment,
  Assessment,
  AutoGraph,
  BarChart,
  Bolt,
  Co2,
  ConnectingAirports,
  Construction,
  CorporateFare,
  CurrencyPound,
  DataUsage,
  DeleteRounded,
  DirectionsCarFilled,
  Download,
  EventSeat,
  ExpandMore,
  Flag,
  Forest,
  Help,
  LocalAirport,
  LocalGasStation,
  MenuBook,
  Park,
  PersonAdd,
  PrecisionManufacturingRounded,
  PriceChange,
  Public,
  ScaleRounded,
  Settings,
  TrendingUp,
  Upload,
  Waves,
} from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Switch, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Actions as ADMIN_ACTIONS } from '../../store/actions/adminActions';
import { Actions as SIDEBARACTIONS } from '../../store/actions/sidebarActions';
import { SidebarLink, SidebarMailto } from '../../styledComponents/GlobalStyle';

const borderTopStyle = { borderTop: '1px solid rgba(255,255,255,0.05)' };

const AdminPortalSideBar = ({
  show,
  branding,
  adminView,
  setAdminView,
  cyfAdmin,
  whiteLabelAdmin,
  processingStepFunction,
  getAdminReportsStepFunctionStatus,
  fileProcessing,
  getAdminReports,
  adminSideBarOptions,
  consultantAdmin,
  contractAdmin,
  toggleModal,
}) => {
  const sidebarClass = `nav-collapse ${!show && `hide-left-bar`}`;
  const { sidebarBackground, sidebarLinkColor } = branding.colors || {};
  const [statusIndex, setStatusIndex] = useState(0);

  const execArn = processingStepFunction?.executionArn;

  useEffect(() => {
    if (execArn?.includes('ReportHelper')) {
      const newStatusIndex = statusIndex + 1;
      processingStepFunction &&
        processingStepFunction.executionArn &&
        (!processingStepFunction.status ||
          (processingStepFunction.status !== 'SUCCEEDED' && processingStepFunction.status !== 'FAILED')) &&
        setTimeout(async () => {
          await getAdminReportsStepFunctionStatus(processingStepFunction.executionArn);
          setStatusIndex(newStatusIndex);
        }, 1500);
    }
  }, [execArn, statusIndex, processingStepFunction, getAdminReportsStepFunctionStatus]);

  return (
    <aside>
      <div id='sidebar' className={sidebarClass}>
        <div className='leftside-navigation'>
          <ul className='sidebar-menu admin-view' id='nav-accordion' style={{ backgroundColor: sidebarBackground }}>
            <div>
              {Object.keys(adminSideBarOptions)
                .filter((o) => {
                  if (whiteLabelAdmin) return adminSideBarOptions[o]?.exclusiveFor?.includes(whiteLabelAdmin);
                  else if (consultantAdmin) return adminSideBarOptions[o]?.exclusiveFor?.includes(consultantAdmin);
                  else if (cyfAdmin && !contractAdmin)
                    return !adminSideBarOptions[o]?.exclusiveFor || adminSideBarOptions[o]?.exclusiveFor?.includes(cyfAdmin);
                  else
                    return (
                      !adminSideBarOptions[o]?.exclusiveFor ||
                      adminSideBarOptions[o]?.exclusiveFor.includes(cyfAdmin) ||
                      adminSideBarOptions[o]?.exclusiveFor.includes(contractAdmin)
                    );
                })
                .map((key, index) => {
                  const type = adminSideBarOptions[key]?.type;
                  return (
                    <li style={borderTopStyle} key={index}>
                      {type === 'accordion' && (
                        <Accordion className={'sidebar-accordion'}>
                          <AccordionSummary
                            className={'sidebar-label list'}
                            expandIcon={
                              <ExpandMore sx={{ marginRight: 4, '--sidebar-accordion-icon-color': sidebarLinkColor }} />
                            }>
                            <Typography
                              className={'sidebar-label'}
                              sx={{ '--sidebar-accordion-label-color': sidebarLinkColor }}>
                              {adminSideBarOptions[key]?.icon}
                              <span>{adminSideBarOptions[key]?.title}</span>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {adminSideBarOptions[key]?.options
                              .filter(
                                (opt) =>
                                  opt?.exclusiveFor?.includes(cyfAdmin) ||
                                  opt?.exclusiveFor?.includes(whiteLabelAdmin) ||
                                  !opt?.exclusiveFor
                              )
                              .map((option, index) => {
                                if (key === 'downloads') {
                                  if (option.type === 'link') {
                                    return (
                                      <SidebarLink
                                        key={index}
                                        to={''}
                                        onClick={() =>
                                          !fileProcessing?.[option.flag] && getAdminReports(option.flag, option.flag)
                                        }>
                                        <div style={{ display: 'flex' }}>
                                          {fileProcessing?.[option.flag] ? (
                                            <i style={{ paddingLeft: 10 }} className='fa fa-spin fa-spinner' />
                                          ) : option.flag === 'activity' ? (
                                            <Assessment sx={{ marginRight: 0.8 }} />
                                          ) : option.flag === 'entity' ? (
                                            <Forest sx={{ marginRight: 0.8 }} />
                                          ) : option.flag === 'conversionFactors' ? (
                                            <Settings sx={{ marginRight: 0.8 }} />
                                          ) : (
                                            <BarChart sx={{ marginRight: 0.8 }} />
                                          )}
                                          <span>{option.name}</span>
                                        </div>
                                      </SidebarLink>
                                    );
                                  } else {
                                    return (
                                      <Accordion className={'sidebar-accordion'} key={index}>
                                        <AccordionSummary
                                          className={'sidebar-label list'}
                                          expandIcon={
                                            <ExpandMore
                                              sx={{ marginRight: 4, '--sidebar-accordion-icon-color': sidebarLinkColor }}
                                            />
                                          }>
                                          <Typography
                                            className={'sidebar-label'}
                                            sx={{ '--sidebar-accordion-label-color': sidebarLinkColor }}>
                                            {option.icon}
                                            <span>{option.title}</span>
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          {option?.options.map((option, index) => {
                                            return (
                                              <SidebarMailto
                                                style={{ display: 'flex', alignItems: 'center' }}
                                                href={option?.href}
                                                target='_blank'>
                                                {option?.icon}
                                                <span>{option?.name}</span>
                                              </SidebarMailto>
                                            );
                                          })}
                                        </AccordionDetails>
                                      </Accordion>
                                    );
                                  }
                                } else if (key === 'uploads') {
                                  return (
                                    <SidebarLink
                                      key={index}
                                      to={''}
                                      onClick={() => {
                                        if (option.flag === 'deleteOrgs') {
                                          toggleModal(true, 'deleteOrgs');
                                        } else if (option.flag === 'importMixedTemplates') {
                                          toggleModal(true, 'importMixedTemplates');
                                        }
                                      }}>
                                      <div style={{ display: 'flex' }}>
                                        {option.icon}
                                        <span>{option.name}</span>
                                      </div>
                                    </SidebarLink>
                                  );
                                } else if (option.type === 'accordion') {
                                  return (
                                    <Accordion className={'sidebar-accordion'} key={index}>
                                      <AccordionSummary
                                        className={'sidebar-label list'}
                                        expandIcon={
                                          <ExpandMore
                                            sx={{ marginRight: 4, '--sidebar-accordion-icon-color': sidebarLinkColor }}
                                          />
                                        }>
                                        <Typography
                                          className={'sidebar-label'}
                                          sx={{ '--sidebar-accordion-label-color': sidebarLinkColor }}>
                                          {option.icon}
                                          <span>{option.name}</span>
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        {option?.options.map((option, index) => {
                                          return (
                                            <SidebarLink to={option.to} key={index} id={option.id}>
                                              <div style={{ display: 'flex' }}>
                                                {option.icon}
                                                <span>{option.name}</span>
                                              </div>
                                            </SidebarLink>
                                          );
                                        })}
                                      </AccordionDetails>
                                    </Accordion>
                                  );
                                } else {
                                  return (
                                    <SidebarLink
                                      to={option.to}
                                      key={index}
                                      id={option.id}
                                      onClick={() => {
                                        if (option.flag === 'linkStripeAccount') {
                                          toggleModal(true, 'linkStripeAccount');
                                        }
                                      }}>
                                      <div style={{ display: 'flex' }}>
                                        {option.icon}
                                        <span>{option.name}</span>
                                      </div>
                                    </SidebarLink>
                                  );
                                }
                              })}
                          </AccordionDetails>
                        </Accordion>
                      )}
                      {type === 'link' && (
                        <SidebarLink to={adminSideBarOptions[key]?.to} style={{ display: 'flex', alignItems: 'center' }}>
                          {adminSideBarOptions[key]?.icon}
                          <span style={{ marginLeft: 5 }}>{adminSideBarOptions[key]?.title}</span>
                        </SidebarLink>
                      )}
                      {type === 'mailto' && (
                        <SidebarMailto
                          style={{ display: 'flex', alignItems: 'center' }}
                          href={adminSideBarOptions[key].href}
                          target='_blank'>
                          {adminSideBarOptions[key].icon}
                          <span>{adminSideBarOptions[key].title}</span>
                        </SidebarMailto>
                      )}
                    </li>
                  );
                })}
            </div>
            <div>
              <li style={{ borderTop: '1px solid rgba(255,255,255,0.05)' }}>
                <FormControlLabel
                  className={'sidebar-toggle'}
                  control={<Switch checked={adminView} onClick={() => setAdminView(!adminView)} />}
                  label={
                    <Typography sx={{ '--sidebar-toggle-color': sidebarLinkColor }} className={'sidebar-label'}>
                      Admin Portal
                    </Typography>
                  }
                />
              </li>
            </div>
          </ul>
        </div>
      </div>
    </aside>
  );
};

const mapStateToProps = (state) => {
  const { cyfAdmin, whiteLabelAdmin, consultantAdmin, contractAdmin } = state.profile?.details || {};
  const adminSideBarOptions = {};
  adminSideBarOptions.organsations = {
    title: 'Organisations',
    type: 'accordion',
    icon: <CorporateFare sx={{ marginRight: 0.8 }} />,
    exclusiveFor: ['whiteLabelAdmin', 'cyfAdmin', 'consultantAdmin'],
    options: [
      { type: 'link', name: 'List', to: '/admin/organisations', icon: <DataUsage sx={{ marginRight: 0.8 }} /> },
      {
        type: 'link',
        name: 'Metrics',
        to: '/admin/organisationMetrics',
        icon: <i className='fa fa-users' />,
        exclusiveFor: ['cyfAdmin'],
      },
    ],
  };
  adminSideBarOptions.dataTeamTables = {
    title: 'Data Team',
    type: 'accordion',
    icon: <i className='fa fa-solid fa-chart-area' style={{ marginRight: 6.4, fontSize: 15 }} />,
    options: [
      { type: 'link', name: 'Usage Data', to: '/admin/usageData', icon: <i className='fa-regular fa-chart-bar' /> },
      { type: 'link', name: 'Industries', to: '/admin/industries', icon: <Construction /> },
      { type: 'link', name: 'Industry Sub-Categories', to: '/admin/industrySubcategory', icon: <Construction /> },
      { type: 'link', name: 'Users', to: '/admin/profiles', icon: <i className='fa fa-users' /> },
      { type: 'link', name: 'User Permissions', to: '/admin/userPermissions', icon: <i className='fa fa-users' /> },
      { type: 'link', name: 'Reports', to: '/admin/reports', icon: <Assessment sx={{ marginRight: 0.8 }} /> },
      {
        type: 'link',
        name: 'Conversion Factors',
        to: '/admin/conversionfactors',
        icon: <Settings sx={{ marginRight: 0.8 }} />,
      },
      { type: 'link', name: 'Sea Distances', to: '/admin/seaDistances', icon: <Waves sx={{ marginRight: 0.8 }} /> },
      { type: 'link', name: 'Airports', to: '/admin/airports', icon: <LocalAirport sx={{ marginRight: 0.8 }} /> },
      {
        type: 'link',
        name: 'Closest Airport',
        to: '/admin/closestAirport',
        icon: <ConnectingAirports sx={{ marginRight: 0.8 }} />,
      },
      {
        type: 'link',
        name: 'Help',
        to: '/admin/help',
        icon: <Help sx={{ marginRight: 0.8 }} />,
      },
      {
        name: 'Electricity Sources',
        type: 'link',
        to: '/admin/electricitySources',
        icon: <Bolt sx={{ marginRight: 0.8 }} />,
      },
      {
        name: 'Countries',
        type: 'link',
        to: '/admin/countries',
        icon: <Flag sx={{ marginRight: 0.8 }} />,
      },
      {
        name: 'Scope 3 Categories',
        type: 'link',
        to: '/admin/scope3Categories',
        icon: <Co2 sx={{ marginRight: 0.8 }} />,
      },
      {
        name: 'Market Factors',
        type: 'link',
        to: '/admin/marketFactors',
        icon: <BarChart sx={{ marginRight: 0.8 }} />,
      },
      {
        name: 'Entities',
        type: 'link',
        to: '/admin/entities',
        icon: <Forest sx={{ marginRight: 0.8 }} />,
      },
      {
        name: 'Sub Entities',
        type: 'link',
        to: '/admin/subEntities',
        icon: <Park sx={{ marginRight: 0.8 }} />,
      },
      {
        name: 'Benchmark',
        type: 'link',
        to: '/admin/benchmark',
        icon: <AlignHorizontalLeft sx={{ marginRight: 0.8 }} />,
      },
      {
        name: 'Facilities',
        type: 'accordion',
        icon: <Apartment sx={{ marginRight: 0.8 }} />,
        options: [
          { type: 'link', name: 'List', to: '/admin/facilities', icon: <Apartment sx={{ marginRight: 0.8 }} /> },
          {
            type: 'link',
            name: 'Types',
            to: '/admin/facilitiesType',
            icon: <i className='fa fa-users' />,
          },
        ],
      },
      {
        name: 'Usage Types',
        type: 'accordion',
        icon: <AdminPanelSettings sx={{ marginRight: 0.8 }} />,
        options: [
          { type: 'link', name: 'Seat', to: '/admin/seatType', icon: <EventSeat sx={{ marginRight: 0.8 }} /> },
          {
            type: 'link',
            name: 'Vehicle',
            to: '/admin/vehicleType',
            icon: <DirectionsCarFilled sx={{ marginRight: 0.8 }} />,
          },
          { type: 'link', name: 'Fuel', to: '/admin/fuelType', icon: <LocalGasStation sx={{ marginRight: 0.8 }} /> },
          {
            type: 'link',
            name: 'Production',
            to: '/admin/productionType',
            icon: <PrecisionManufacturingRounded sx={{ marginRight: 0.8 }} />,
          },
          { type: 'link', name: 'Load', to: '/admin/loadType', icon: <ScaleRounded sx={{ marginRight: 0.8 }} /> },
          { type: 'link', name: 'Waste', to: '/admin/wasteType', icon: <DeleteRounded sx={{ marginRight: 0.8 }} /> },
          { type: 'link', name: 'Price', to: '/admin/priceType', icon: <PriceChange sx={{ marginRight: 0.8 }} /> },
          {
            type: 'link',
            name: 'Origin Location',
            to: '/admin/originlocation',
            icon: <Public sx={{ marginRight: 0.8 }} />,
          },
          {
            type: 'link',
            name: 'Final Location',
            to: '/admin/finallocation',
            icon: <Public sx={{ marginRight: 0.8 }} />,
          },
          {
            type: 'link',
            name: 'Factor Provider',
            to: '/admin/factorprovider',
            icon: <i className='fa-solid fa-store'></i>,
          },
          { type: 'link', name: 'Metric', to: '/admin/metrics', icon: <AutoGraph sx={{ marginRight: 0.8 }} /> },
        ],
      },
      {
        name: 'Add Ons',
        type: 'link',
        to: '/admin/addOns',
        icon: <i className='fa-solid fa-cart-plus' />,
      },
      {
        name: 'Usage Type Details',
        type: 'link',
        to: '/admin/usageTypeDetails',
        icon: <AdminPanelSettings sx={{ marginRight: 0.8 }} />,
      },
      {
        name: 'Integration Mappings',
        type: 'link',
        to: '/admin/integrationMappings',
        icon: <i className='fa-solid fa-sliders'></i>,
      },
      {
        name: 'Currencies',
        type: 'link',
        to: '/admin/currencies',
        icon: <i className='fa-solid fa-sterling-sign'></i>,
      },
      {
        name: 'Stripe',
        type: 'link',
        to: '/admin/stripe',
        icon: <i className='fa-brands fa-stripe-s'></i>,
      },
      {
        name: 'Currency Rates',
        type: 'link',
        to: '/admin/currency-rates',
        icon: <i class='fa-solid fa-percent'></i>,
      },
    ],
  };
  adminSideBarOptions.downloads = {
    title: 'Downloads',
    type: 'accordion',
    icon: <Download sx={{ marginRight: 0.8 }} />,
    exclusiveFor: ['whiteLabelAdmin', 'cyfAdmin', 'consultantAdmin'],
    options: [
      { type: 'link', name: 'Activity Report', to: 'zz', icon: <EventSeat sx={{ marginRight: 0.8 }} />, flag: 'activity' },
      {
        type: 'link',
        name: 'Entities & Subentities',
        to: 'zz',
        icon: <DirectionsCarFilled sx={{ marginRight: 0.8 }} />,
        flag: 'entity',
        exclusiveFor: ['cyfAdmin'],
      },
      {
        type: 'link',
        name: 'Conversion Factors',
        to: 'zz',
        icon: <Settings sx={{ marginRight: 0.8 }} />,
        flag: 'conversionFactors',
        exclusiveFor: ['cyfAdmin'],
      },
      {
        type: 'link',
        name: 'Market Factors',
        to: 'zz',
        icon: <BarChart sx={{ marginRight: 0.8 }} />,
        flag: 'marketFactors',
        exclusiveFor: ['cyfAdmin'],
      },
      {
        title: 'White Labels',
        type: 'accordion',
        icon: <MenuBook sx={{ marginRight: 0.8 }} />,
        exclusiveFor: ['cyfAdmin'],
        options: [
          {
            type: 'mailto',
            name: 'White Label Colours PDF',
            href: 'https://s3.eu-west-2.amazonaws.com/downloads.compareyourfootprint.com/White_Label_Resources/Whitelabel.Colors.pdf',
            icon: <i className='fa-solid fa-palette'></i>,
            exclusiveFor: ['cyfAdmin'],
          },
          {
            type: 'mailto',
            name: 'White Label Form',
            href: 'https://s3.eu-west-2.amazonaws.com/downloads.compareyourfootprint.com/White_Label_Resources/WhiteLabelForm.xlsx',
            icon: <i className='fa-regular fa-newspaper'></i>,
            exclusiveFor: ['cyfAdmin'],
          },
        ],
      },
    ],
  };
  adminSideBarOptions.uploads = {
    title: 'Uploads',
    type: 'accordion',
    icon: <Upload sx={{ marginRight: 0.8 }} />,
    options: [
      {
        type: 'link',
        name: 'Delete Organisations',
        to: 'zz',
        icon: <DeleteRounded sx={{ marginRight: 0.8 }} />,
        flag: 'deleteOrgs',
      },
      {
        type: 'link',
        name: 'Import Mixed Template',
        to: 'zz',
        icon: <Download sx={{ marginRight: 0.8 }} />,
        flag: 'importMixedTemplates',
      },
    ],
  };
  adminSideBarOptions.transferPortal = {
    title: 'Transfer Portal',
    type: 'link',
    to: '/admin/transferPortal',
    icon: <i className='fas fal fa-random' />,
  };
  adminSideBarOptions.whiteLabelResources = {
    title: 'White Label Resources',
    type: 'mailto',
    href: 'https://www.compareyourfootprint.com/partners/white-label/training-hub/',
    icon: <MenuBook sx={{ marginRight: 0.8 }} />,
    exclusiveFor: ['whiteLabelAdmin', 'cyfAdmin'],
  };
  adminSideBarOptions.salesPortal = {
    title: 'Sales Portal',
    type: 'accordion',
    icon: <TrendingUp sx={{ marginRight: 0.8 }} />,
    exclusiveFor: ['contractAdmin'],
    options: [
      {
        type: 'link',
        name: 'White Label / Consultant / Ambassador Form',
        to: '/admin/sales/newContract',
        icon: <PersonAdd sx={{ marginRight: 0.8 }} />,
      },
      { type: 'link', name: 'Contracts', to: '/admin/sales/contracts', icon: <AccountBox sx={{ marginRight: 0.8 }} /> },
      {
        type: 'link',
        name: 'Pricing Bands',
        to: '/admin/sales/pricingBands',
        icon: <CurrencyPound sx={{ marginRight: 0.8 }} />,
      },
      {
        type: 'link',
        name: 'Un/Link Stripe Account To Organisation',
        to: '',
        icon: <i className='fa-solid fa-link' />,
        flag: 'linkStripeAccount',
      },
    ],
  };
  adminSideBarOptions.settings = {
    title: 'Settings',
    type: 'link',
    to: '/admin/settings',
    icon: <i className='fa fa-cog' />,
    exclusiveFor: ['whiteLabelAdmin', 'cyfAdmin'],
  };

  return {
    processingStepFunction: state.processingImport?.status?.data || state.processingImport?.status,
    fileProcessing: state?.admin?.export?.downloading,
    show: state.sidebar.show,
    branding: state.branding,
    cyfAdmin: cyfAdmin && 'cyfAdmin',
    whiteLabelAdmin: whiteLabelAdmin && 'whiteLabelAdmin',
    consultantAdmin: consultantAdmin && 'consultantAdmin',
    contractAdmin: contractAdmin && 'contractAdmin',
    adminSideBarOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch(SIDEBARACTIONS.toggleSidebar()),
  toggleModal: (status, action) => dispatch(ADMIN_ACTIONS.toggleModal(status, action)),
  getAdminReports: (tableName, fileType) => dispatch(ADMIN_ACTIONS.getAdminReports(tableName, fileType)),
  getAdminReportsStepFunctionStatus: (importArn) => dispatch(ADMIN_ACTIONS.getAdminReportsStepFunctionStatus(importArn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPortalSideBar);
