import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

const formatNumber = (value) => parseFloat(value || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 });

const EmissionsDisaggregatedByFacilityRow = ({ facility, facilityID, facilityData }) => {
  return (
    <tr>
      <td width='20%' style={{ textAlign: 'left' }}>
        {facility && facility.name}
      </td>
      <td width='20%'>
        <span className='toggle-location'>{facilityData && facilityData.co2e ? facilityData.co2e.toFixed(3) : '0.00'}</span>
        <span className='toggle-market'>
          {formatNumber(facilityData && facilityData.marketScope ? _.sum(Object.values(facilityData.marketScope)) : 0)}
        </span>
      </td>
      <td width='20%'>
        <span className='toggle-location'>
          {facilityData && facilityData.scope[1] ? facilityData.scope[1].toFixed(3) : '0.00'}
        </span>
        <span className='toggle-market'>
          {facilityData && facilityData.marketScope[1]
            ? facilityData.marketScope[1].toFixed(3)
            : facilityData && facilityData.scope[1]
            ? facilityData.scope[1].toFixed(3)
            : '0.00'}
        </span>
      </td>
      <td width='20%'>
        <span className='toggle-location'>
          {facilityData && facilityData.scope[2] ? facilityData.scope[2].toFixed(3) : '0.00'}
        </span>
        <span className='toggle-market'>
          {facilityData && facilityData.marketScope[2] ? facilityData.marketScope[2].toFixed(3) : '0.00'}
        </span>
      </td>
      <td width='20%'>
        <span className='toggle-location'>
          {facilityData && facilityData.scope[3] ? facilityData.scope[3].toFixed(3) : '0.00'}
        </span>
        <span className='toggle-market'>
          {facilityData && facilityData.marketScope[3] ? facilityData.marketScope[3].toFixed(3) : '0.00'}
        </span>
      </td>
    </tr>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { emissionsByFacility } = state.consumption;

  const { facilityID } = ownProps;

  const facilityData =
    state.report &&
    state.report.details &&
    state.report.details.year &&
    emissionsByFacility[state.report.details.year] &&
    emissionsByFacility[state.report.details.year][facilityID];
  const facility =
    emissionsByFacility &&
    state.facility &&
    state.facility.list &&
    state.facility.list.find((item) => item.id === facilityID);

  return {
    facilityData,
    facility,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsDisaggregatedByFacilityRow);
