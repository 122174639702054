import { Auth } from '@aws-amplify/auth';
import cleanDeep from 'clean-deep';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Actions as AUTHENTICATION_ACTIONS } from '../../store/actions/authenticationActions';
import { Actions as ORGANISATIONACTIONS } from '../../store/actions/organisationActions';
import { Actions as PAYMENTACTIONS } from '../../store/actions/paymentActions';
import { AuthBtn } from '../../styledComponents/GlobalStyle';
import LogoSide from '../Register/LogoSide';
import ValidationMessage from '../utils/ValidationMessage';
import BillingDetails from './BillingDetails';
import RegistrationAgreements from './RegistrationAgreements';

const signupValidationSchema = yup.object().shape({
  acceptTNCs: yup
    .boolean()
    .required()
    .oneOf([true], 'Accepting the Software License Agreement & Privacy Policy is required'),
});

const ConfirmEmail = ({
  username,
  branding,
  password,
  getUserCredentials,
  user,
  organisation,
  newUser,
  currentOrganisation,
  loggingIn,
  processing,
  newConfirmationCode,
  newPassword,
  newUserSubscription,
  getOrganisation,
}) => {
  const { signInFormBg, primaryHeaderText } = branding.colors;

  const [editedState, setEditedState] = useState({});
  const [extraDetails, setExtraDetails] = useState(false);
  const [error, setError] = useState(false);
  const [acceptTNCs, setAcceptTNCs] = useState(false);
  const [showSLA, setShowSLA] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const updateField = (e) => {
    const newEditedState = { ...editedState };
    newEditedState[e.target.name] = e.target.value;
    setEditedState(newEditedState);
  };

  const confirmSignUp = async () => {
    await signupValidationSchema
      .validate({ acceptTNCs: acceptTNCs }, { abortEarly: false })
      .then(async () => {
        try {
          loggingIn(true);
          await Auth.confirmSignUp(username, editedState['confirmationCode']);
          let signIn = await Auth.signIn(username, password || newPassword);
          await getUserCredentials();

          if (newConfirmationCode) {
            //In case user resend confirmation code
            const { family_name, given_name, email } = signIn.attributes;

            const user = {
              firstName: given_name,
              lastName: family_name,
              email: email,
              priceId: signIn.attributes['custom:priceId'],
            };

            const organisation = {
              name: signIn.attributes['custom:companyName'],
              revenue: signIn.attributes['custom:organisationRevenue'],
              subscriptionPlan: signIn.attributes['custom:subscriptionPlan'],
              revenueRange: signIn.attributes['custom:revenueRange'],
              subscriptionPrice: signIn.attributes['custom:subscriptionPrice'],
              stripeCoupon: signIn.attributes['custom:coupon'],
              stripeCouponDurationMonths: signIn.attributes['custom:couponDurationMonths'],
              sripeCouponPercetOff: signIn.attributes['custom:couponPercentOff'],
            };

            newUserSubscription(cleanDeep(user), { ...cleanDeep(organisation) });
            await newUser(user, organisation);
          } else {
            newUserSubscription(cleanDeep(user), { ...cleanDeep(organisation) });
            await newUser(user, organisation);
          }

          await getOrganisation();
          loggingIn(false);
          setExtraDetails(true);
        } catch (error) {
          loggingIn(false);
          setError(error);
          console.log('Error confirming sign up', error);
        }
      })
      .catch((error) => {
        loggingIn(false);
        console.log('ValidationError', error);
        setValidationError(error.inner);
      });
  };

  if (extraDetails && currentOrganisation) return <BillingDetails />;

  return (
    <div className='login-body' style={{ height: '100vh', width: '100%', display: 'flex' }}>
      <LogoSide />
      <div className='signInSide' style={{ background: signInFormBg }}>
        <div className='confirmationSide' style={{ width: '35vw' }}>
          <h1 style={{ marginBottom: '1.5vh', color: primaryHeaderText }}>Confirmation Email</h1>
          <div className='col-xs-12 alert-danger alert' style={{ marginBottom: 0, display: error ? 'block' : 'none' }}>
            <h5 style={{ textAlign: 'left' }}>Error: {error?.log || error?.message}</h5>
          </div>

          <h5 style={{ display: 'block', fontSize: 15, color: primaryHeaderText, marginBottom: '1.5vh' }}>
            We've sent you a confirmation email...
          </h5>

          <input
            type='text'
            style={{ marginBottom: '1.5vh', height: '5vh' }}
            className='form-control'
            id='confirmCode-input'
            name='confirmationCode'
            placeholder='Paste your code here*'
            onChange={updateField}
          />

          <div className='acceptTNCs-checkbox'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input type='checkbox' style={{ margin: 0 }} onChange={() => setAcceptTNCs(!acceptTNCs)} />
              <label style={{ fontWeight: 400, marginBottom: 0, marginLeft: 5 }}>
                By clicking you are accepting to our{' '}
                <button style={{ border: 0, background: 'inherit' }} onClick={() => setShowSLA(!showSLA)}>
                  Software License Agreement
                </button>{' '}
                and our{' '}
                <button
                  style={{ border: 0, background: 'inherit' }}
                  onClick={() => setShowPrivacyPolicy(!showPrivacyPolicy)}>
                  Privacy Policy
                </button>
                .
              </label>
            </div>
          </div>

          <ValidationMessage
            message={validationError && _.find(validationError, { path: 'softwareAgreementAccepted' })?.message}
          />

          <AuthBtn
            style={{ color: 'white' }}
            className='btn btn-lg btn-login btn-block onceonly'
            disabled={processing ? true : false}
            onClick={confirmSignUp}>
            {processing && <i className='fa fa-spin fa-spinner icon-spinner' />}
            Confirm
          </AuthBtn>

          {(showSLA || showPrivacyPolicy) && (
            <div>
              <RegistrationAgreements
                setShowSoftwareLicenseAgreement={setShowSLA}
                showSoftwareLicenseAgreement={showSLA}
                setShowPrivacyPolicy={setShowPrivacyPolicy}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    branding: state.branding,
    currentOrganisation: state.currentOrganisation,
    processing: state.authentication.processing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserCredentials: () => dispatch(AUTHENTICATION_ACTIONS.getUserCredentials()),
    newUser: (user, organisation) => dispatch(AUTHENTICATION_ACTIONS.newUser(user, organisation)),
    loggingIn: (saving) => dispatch(AUTHENTICATION_ACTIONS.loggingIn(saving)),
    newUserSubscription: (user, organisation) => dispatch(AUTHENTICATION_ACTIONS.newUserSubscription(user, organisation)),
    selectPrice: (price) => dispatch(PAYMENTACTIONS.selectPrice(price)),
    subscriptionPlan: (subscriptionPlan) => dispatch(PAYMENTACTIONS.subscriptionPlan(subscriptionPlan)),
    getOrganisation: () => dispatch(ORGANISATIONACTIONS.getOrganisation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);
