import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import EmissionsBySourceRow from './EmissionsBySourceRow';

const EmissionsBySourceSubentity = ({ subentity, consumption, types, entity, usageTypeDetails }) => {
  const options = Object.keys(consumption);

  return options.map((id) => {
    const option = consumption[id]._allFacilities;
    const usageDetail = [];

    const itemDetails = Object.values(option)[0];

    Object.keys(types).forEach((key) => {
      if (itemDetails[key]) {
        if (usageDetail.length > 0) {
          usageDetail.push(<br />);
        }
        const label = usageTypeDetails && _.find(usageTypeDetails, { type: key })?.displayName;
        const type = types && _.find(types[key], { id: itemDetails[key] });
        const typeName = type && (type?.name || type?.priceType);
        usageDetail.push(`${label}: ${typeName}`);
      }
    });

    return <EmissionsBySourceRow key={id} entity={entity} subentity={subentity} usageDetail={usageDetail} option={option} />;
  });
};

const mapStateToProps = (state, ownProps) => {
  const usageTypeDetails = state?.admin?.adminPortal?.usageTypeDetails;
  const { data, years } = state.consumption;

  const { organisation, facility, metric } = state;

  const { entity, subentity } = ownProps;

  const consumption = data[entity.id][subentity.id];

  return {
    consumption,
    years: years || [],
    organisationDetails: organisation?.details,
    organisationMetrics: organisation?.metrics,
    organisationFacilities: facility?.list,
    metrics: metric?.list,
    types: metric?.types,
    usageTypeDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsBySourceSubentity);
