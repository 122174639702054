import React from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';
import Select from 'react-select';
import _ from 'lodash';
import { customStyles } from '../codeStyles';
import { toggleModal } from '../../store/actions/adminActions';

const Tenants = ({ tenants, tenant, toggleModal }) => {
  if (!tenants) {
    return false;
  }

  const options = tenants.map((tenant) => ({
    value: tenant.tenantId,
    label: tenant.tenantName,
  }));

  const handleTenantChange = (selectedOption) => {
    toggleModal(true, 'saveXero-tenant', { tenant: selectedOption?.value }, null);
  };

  const selectedTenant = _.find(options, { value: tenant });

  if (tenants.length <= 1) return null;

  return (
    <li style={{ padding: '10px 15px' }}>
      Select Xero Organisation
      <span className='pull-right' style={{ marginRight: '47px', lineHeight: '0' }}>
        <Select
          id='selectTenant'
          value={selectedTenant}
          onChange={handleTenantChange}
          options={options}
          styles={customStyles}
        />
      </span>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    tenants: state.tenants,
    tenant: state.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTenant: (tenant) => dispatch(ACTIONS.saveTenant(tenant)),
  toggleModal: (status, action, item, modalProps) => dispatch(toggleModal(status, action, item, modalProps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tenants);
