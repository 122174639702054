import { Box, FormControlLabel, LinearProgress } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { AddButton, CalendarBtn, bulkEditTextInput } from '../../styledComponents/GlobalStyle';
import Breadcrumb from '../Common/Breadcrumb';
import { itemToOptions } from '../utils/GlobalFunctions';

function allAreNull(array) {
  let result = true;

  for (const value of array) {
    if (value !== null) {
      result = false;
      break;
    }
  }

  return result;
}

function dateDiff(first, second) {
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

const BulkEditPage = ({ entityName, entityId, checkedUsage, options, saveUsageData, branding, progressState }) => {
  const [checkedConfirm, setCheckedConfirm] = useState(false);
  const [selected, setSelected] = useState({});
  const [editAreaActive, setEditAreaActive] = useState(false);
  const [progressBarActive, setProgressBarActive] = useState(false);
  const [invalidUsageDate, setInvalidUsageDate] = useState(false);
  const [progress, setProgress] = useState(0);
  const history = useHistory();
  const { primaryColor } = branding.colors;

  useEffect(() => {
    if (progressState !== undefined) {
      setProgress(progressState);
      setProgressBarActive(true);
    } else {
      setProgressBarActive(false);
    }
  }, [progressState]);

  useEffect(() => {
    if (entityId === undefined && !progressBarActive) {
      history.push('/usage');
    }
    //eslint-disable-next-line
  }, [entityId]);

  const updateDateOrSelect = (selectedInput, e) => {
    if (e) {
      const newSelected = { ...selected };
      newSelected[e.name] = selectedInput;
      setSelected(newSelected);
    }
  };

  const updateTextInput = (e) => {
    if (e) {
      const newSelected = { ...selected };
      const result = e.target.name === 'Usage Value' ? e.target.value.replace(/[^0-9_.]/g, '') : e.target.value;
      if (typeof result === 'string' && result.trim().length === 0) {
        newSelected[e.target.name] = null;
      } else {
        newSelected[e.target.name] = result;
      }
      setSelected(newSelected);
    }
  };

  const newData = () => {
    const newItem = {};
    Object.keys(selected).forEach((optionType) => {
      if (optionType === 'Start Date') {
        newItem['dateStart'] = selected[optionType];
      } else if (optionType === 'End Date') {
        newItem['dateEnd'] = selected[optionType];
      } else if (optionType === 'Custom top-level category') {
        newItem['userLabel'] = selected[optionType];
      } else if (optionType === 'Usage Value') {
        newItem['usage'] = selected[optionType];
      } else {
        newItem[optionType] = selected[optionType]['value']?.toString();
      }
    });
    return checkedUsage?.map((oldItem) => {
      const newUD = { id: oldItem.id.toString(), ...oldItem, ...newItem };
      return newUD;
    });
  };

  return (
    <>
      {!progressBarActive && (
        <Breadcrumb
          items={[
            { label: 'Usage', link: '/usage' },
            { label: entityName, link: `/usage/` + entityId },
          ]}
        />
      )}

      {!editAreaActive && (
        <div className='row'>
          <div className='col-lg-12'>
            <section className='panel'>
              <aside className='profile-nav alt'>
                <section className='panel'>
                  <header className='panel-heading'>Bulk Edit - {entityName}</header>
                  <ul className='nav nav-pills nav-stacked'>
                    {Object.keys(options).map((optionType, index) => (
                      <>
                        {options[optionType] === 'date' && (
                          <li key={index}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '10px 15px',
                              }}>
                              <div>
                                <i className='fa fa-calendar' style={{ paddingRight: 20 }}></i>
                                {optionType}
                              </div>
                              <span style={{ float: 'right' }}>
                                <style> {`.react-datepicker__input-container{ width: 20vh }`} </style>
                                <label className='input-group date datepicker'>
                                  <DatePicker
                                    showMonthDropdown
                                    showYearDropdown
                                    dateFormat='dd/MM/yyyy'
                                    name={optionType}
                                    selected={selected[optionType]}
                                    onChange={(selectedDate, e) => {
                                      e.name = optionType;
                                      updateDateOrSelect(selectedDate, e);
                                      setInvalidUsageDate(false);
                                    }}
                                    minDate={optionType === 'End Date' && selected['Start Date']}
                                    maxDate={new Date()}
                                    className='form-control date'
                                    wrapperClassName='date-picker'
                                    popperPlacement='bottom-end'
                                    popperModifiers={{
                                      offset: {
                                        enabled: true,
                                        offset: '77px, 0px',
                                      },
                                    }}
                                  />
                                  <span className='input-group-btn'>
                                    <CalendarBtn className='btn btn-primary date-set'>
                                      {' '}
                                      <i className='fa fa-calendar'></i>{' '}
                                    </CalendarBtn>
                                  </span>
                                </label>
                              </span>
                            </div>
                          </li>
                        )}
                        {options[optionType] === 'text' && (
                          <li key={index}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '10px 15px',
                              }}>
                              <div>
                                <i className='fa fa-cogs' style={{ paddingRight: 20 }}></i>
                                {optionType}
                              </div>
                              <span style={{ float: 'right' }}>
                                <div className='form-group' style={{ marginBottom: '0' }}>
                                  <div style={{ width: '23.5vh' }}>
                                    <input
                                      className='form-control'
                                      type='text'
                                      style={bulkEditTextInput}
                                      name={optionType}
                                      onChange={(selectedOption, e) => {
                                        updateTextInput(selectedOption, e);
                                      }}
                                      value={selected[optionType] || ''}
                                    />
                                  </div>
                                </div>
                              </span>
                            </div>
                          </li>
                        )}
                        {Array.isArray(options[optionType]) && (
                          <li key={index}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '10px 15px',
                              }}>
                              <div>
                                {optionType === 'seat' && <i className='fa-solid fa-chair' style={{ paddingRight: 20 }}></i>}
                                {optionType === 'vehicle' && (
                                  <i className='fa-solid fa-car' style={{ paddingRight: 20 }}></i>
                                )}
                                {optionType === 'fuel' && (
                                  <i className='fa-solid fa-gas-pump' style={{ paddingRight: 20 }}></i>
                                )}
                                {optionType === 'production' && (
                                  <i className='fa-solid fa-industry' style={{ paddingRight: 20 }}></i>
                                )}
                                {optionType === 'load' && (
                                  <i className='fa-solid fa-scale-balanced' style={{ paddingRight: 20 }}></i>
                                )}
                                {optionType === 'waste' && (
                                  <i className='fa-solid fa-trash' style={{ paddingRight: 20 }}></i>
                                )}
                                {optionType === 'facility' && (
                                  <i className='fa-solid fa-map-marker' style={{ paddingRight: 20 }}></i>
                                )}
                                {optionType === 'entity' && (
                                  <i className='fa-solid fa-tree' style={{ paddingRight: 20 }}></i>
                                )}
                                {optionType === 'subentity' && (
                                  <i className='fa fa-pagelines' style={{ paddingRight: 20 }}></i>
                                )}
                                {optionType === 'metric' && (
                                  <i className='fa-solid fa-chart-line' style={{ paddingRight: 20 }}></i>
                                )}
                                {optionType.charAt(0).toUpperCase() + optionType.slice(1)}
                              </div>
                              <span style={{ float: 'right' }}>
                                <div style={{ width: '23.5vh' }}>
                                  <Select
                                    name={optionType}
                                    options={options[optionType]}
                                    onChange={(selectedOption, e) => {
                                      updateDateOrSelect(selectedOption, e);
                                    }}
                                    value={selected[optionType]}
                                    isClearable={true}
                                  />
                                </div>
                              </span>
                            </div>
                          </li>
                        )}
                      </>
                    ))}
                  </ul>
                </section>
              </aside>
            </section>
            {!allAreNull(Object.values(selected)) && (
              <div style={{ display: 'grid', placeItems: 'center' }}>
                <span
                  id='dateSpan-warning'
                  style={{
                    display: invalidUsageDate ? 'block' : 'none',
                    color: 'red',
                    fontWeight: 'bold',
                    marginBottom: '2vh',
                  }}>
                  <i className='fa-solid fa-triangle-exclamation' style={{ color: 'red' }} />
                  {''}Your date span cannot exceed 1 year!
                </span>
                <AddButton
                  className='btn btn-success'
                  onClick={() => {
                    var datesDiff = 0;

                    if (selected['Start Date'] && selected['End Date']) {
                      datesDiff = dateDiff(selected['Start Date'], selected['End Date']);
                    }

                    if (datesDiff > 364) {
                      setInvalidUsageDate(true);
                    } else {
                      setEditAreaActive(true);
                    }
                  }}>
                  Next
                </AddButton>
              </div>
            )}
          </div>
        </div>
      )}

      {editAreaActive && (
        <>
          {!progressBarActive && (
            <div className='changesArea'>
              <div className='row'>
                <div className='col-lg-12'>
                  <section className='panel'>
                    <aside className='profile-nav alt'>
                      <section className='panel'>
                        <header className='panel-heading'>Your Selected Changes for {entityName}</header>
                        <ul className='nav nav-pills nav-stacked'>
                          {Object.keys(selected).map((key, index) => (
                            <>
                              {selected[key] !== null && (
                                <li key={key}>
                                  <div style={{ display: 'flex', padding: '10px 15px' }}>
                                    <div style={{ width: '45%', marginRight: '5%', textAlign: 'end' }}>
                                      {key.charAt(0).toUpperCase() + key.slice(1)}
                                    </div>
                                    <div style={{ marginRight: '5%' }}>
                                      <i className='fa-solid fa-arrow-right'></i>
                                    </div>
                                    <div style={{ width: '45%' }}>
                                      {selected[key]?.label && <div>{selected[key]?.label}</div>}
                                      {key.includes('Date') && <div>{moment(selected[key]).format('DD/MM/YYYY')}</div>}
                                      {!key.includes('Date') && !selected[key]?.label && <div>{selected[key]}</div>}
                                    </div>
                                  </div>
                                </li>
                              )}
                            </>
                          ))}
                        </ul>
                      </section>
                    </aside>
                  </section>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <section className='panel'>
                    <aside className='profile-nav alt'>
                      <section className='panel'>
                        <header className='panel-heading'>Your Previous Records for {entityName}</header>
                        <style>
                          {`
                        .css-1ndpvdd-MuiTableCell-root{
                          font-family: inherit;
                          font-size: inherit;
                          color: inherit;
                        }
                        td{
                          font-family: inherit;
                          font-size: inherit;
                          color: inherit;
                        }
                      `}
                        </style>
                        <TableContainer style={{ maxHeight: '50vh' }} className='panel-body'>
                          <Table sx={{ width: '100%' }} aria-label='simple table'>
                            <TableHead sx={{ width: '100%' }}>
                              <TableRow>
                                <TableCell align='center'>Facility</TableCell>
                                <TableCell align='center'>Start Date</TableCell>
                                <TableCell align='center'>End Date</TableCell>
                                <TableCell align='center'>Entity Name</TableCell>
                                <TableCell align='center'>Subentity Name</TableCell>
                                <TableCell align='center'>Usage Value</TableCell>
                                <TableCell align='center'>Seat</TableCell>
                                <TableCell align='center'>Vehicle</TableCell>
                                <TableCell align='center'>Fuel</TableCell>
                                <TableCell align='center'>Production</TableCell>
                                <TableCell align='center'>Waste</TableCell>
                                <TableCell align='center'>Metric</TableCell>
                                <TableCell align='center'>Load</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {checkedUsage?.map((row) => (
                                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                  <TableCell align='center'>{row.facilityName}</TableCell>
                                  <TableCell align='center'>{moment(row.dateStart).format('DD/MM/YYYY')}</TableCell>
                                  <TableCell align='center'>{moment(row.dateEnd).format('DD/MM/YYYY')}</TableCell>
                                  <TableCell align='center'>{row.entityName}</TableCell>
                                  <TableCell align='center'>{row.subentityName}</TableCell>
                                  <TableCell align='center'>{row.usage + ' ' + row.metricName}</TableCell>
                                  <TableCell align='center'>{row.seatName}</TableCell>
                                  <TableCell align='center'>{row.vehicleName}</TableCell>
                                  <TableCell align='center'>{row.fuelName}</TableCell>
                                  <TableCell align='center'>{row.productionName}</TableCell>
                                  <TableCell align='center'>{row.wasteName}</TableCell>
                                  <TableCell align='center'>{row.metricName}</TableCell>
                                  <TableCell align='center'>{row.loadName}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </section>
                    </aside>
                  </section>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                  <FormControlLabel
                    control={<Checkbox checked={checkedConfirm} />}
                    onChange={(e) => setCheckedConfirm(e.target.checked)}
                    label={'By clicking you confirm the changes above are correct.'}
                  />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <AddButton className='btn btn-danger' onClick={() => setEditAreaActive(false)}>
                  Back
                </AddButton>
                <AddButton
                  style={{ marginLeft: 10 }}
                  className='btn btn-success'
                  disabled={!checkedConfirm}
                  onClick={() => {
                    setProgressBarActive(true);
                    const dataToBePassed = newData();
                    saveUsageData(dataToBePassed);
                  }}>
                  Confirm
                </AddButton>
              </div>
            </div>
          )}
          {progressBarActive && (
            <div className='progressBarArea'>
              <div className='row'>
                <div className='col-lg-12'>
                  <section className='panel'>
                    <aside className='profile-nav alt'>
                      <section className='panel'>
                        <Box sx={{ width: '100%', padding: '50px' }}>
                          <LinearProgressWithLabel
                            sx={{ height: 7, borderRadius: 5 }}
                            value={progress}
                            primarycolor={primaryColor}
                          />
                          {progress === 100 && (
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                              <p>Usage has been bulk edited Successfully!</p>
                              <AddButton className='btn btn-success' onClick={() => history.push('/usage')}>
                                Back to Usage Data
                              </AddButton>
                            </div>
                          )}
                        </Box>
                      </section>
                    </aside>
                  </section>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '10vh' }}>
      <Box sx={{ width: '90%', mr: 4, marginLeft: '5vh', color: props.primarycolor }}>
        <LinearProgress color='inherit' variant='determinate' {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' fontSize='2vh' color='text.secondary'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /*
   * The value of the progress indicator for the determinate and buffer variants. Value between 0
   * and 100.
   */
  value: PropTypes.number.isRequired,
  primarycolor: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const checkedUsage = state?.admin?.checkboxes?.checkedUsage;

  const entityId = checkedUsage?.[0]?.entity;
  const entityName = checkedUsage?.[0]?.entityName;

  const options = { 'Start Date': 'date', 'End Date': 'date', 'Custom top-level category': 'text', 'Usage Value': 'text' };
  const optionTypes = state?.metric?.types;
  const subentity = state?.subentities;
  const metric = state?.metric?.list;

  optionTypes &&
    Object.keys(optionTypes)?.map((item) => {
      options[item] = optionTypes[item].map(itemToOptions)?.sort((a, b) => a.label.localeCompare(b.label));
      return options;
    });

  options.facility =
    state?.facility?.list &&
    _.uniqBy(state?.facility?.list, 'id')
      .map(itemToOptions)
      .sort((a, b) => (a.label > b.label ? 1 : -1));

  options.entity = state?.entities?.map(itemToOptions)?.sort((a, b) => (a.label > b.label ? 1 : -1));

  options.subentity = subentity?.map(itemToOptions)?.sort((a, b) => a.label.localeCompare(b.label));
  options.metric = metric?.map(itemToOptions)?.sort((a, b) => a.label.localeCompare(b.label));

  return {
    entityId,
    entityName,
    subentities: state?.subentities,
    cyfAdmin: state.profile?.details?.cyfAdmin,
    checkedUsage,
    options,
    branding: state?.branding,
    progressState: state?.progress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveUsageData: (usageData) => dispatch(USAGEACTIONS.saveUsageData(usageData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkEditPage);
