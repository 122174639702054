import { format } from 'date-fns';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';
import { Actions as CONSUMPTION_ACTIONS } from '../../store/actions/consumptionActions';
import { Actions as ORGANISATION_ACTIONS } from '../../store/actions/organisationActions';
import { Actions as REPORT_ACTIONS } from '../../store/actions/reportActions';
import { ReportOverviewBtn } from '../../styledComponents/GlobalStyle';
import { parseDateToTz } from '../utils/GlobalFunctions';

const ReportOverview = ({
  reportDetails,
  organisationDetails,
  mode,
  getReportPDF,
  getReportExcel,
  excelProcessing,
  pdfProcessing,
  getConsumption,
  getReport,
  currentOrganisation,
  queryMetrics,
  industriesList,
  getOrganisation,
}) => {
  useEffect(() => {
    // If open in a new browser tab, wait until get the current org and then get the report
    if (!reportDetails) {
      const reportId = window.location.pathname.split('/')[3];
      getReport(reportId);
    }

    if (!organisationDetails) {
      //  In case on first render, org details are not set, force it
      getOrganisation();
    }
  }, [currentOrganisation, getOrganisation, getReport, reportDetails, organisationDetails]);

  useEffect(() => {
    if (reportDetails?.id) {
      getConsumption(mode === 'monthly');
    }
  }, [reportDetails?.id, mode, getConsumption]);

  return (
    <div>
      <div className='row'>
        <div className='col-lg-12'>
          <section className='panel'>
            <aside className='profile-nav alt'>
              <section id='reportOverview-panel' className='panel'>
                <header className='panel-heading'> {reportDetails?.name} </header>
                <ul className='nav nav-pills nav-stacked'>
                  <li>
                    <div style={{ padding: '10px 15px', color: '#89817f' }}>
                      <i style={{ paddingRight: '10px' }} className='fa fa-building' /> Organisation
                      <span id='organisation-name' style={{ float: 'right' }}>
                        {organisationDetails?.name}
                      </span>
                    </div>
                  </li>

                  <li>
                    <div style={{ padding: '10px 15px', color: '#89817f' }}>
                      <i style={{ paddingRight: '10px' }} className='fa fa-calendar'></i>
                      Reporting Period
                      <span id='reporting-period' style={{ float: 'right' }}>
                        {reportDetails && format(parseDateToTz(reportDetails.startDate, 'yyyy-MM-dd'), 'do MMM yyyy')} to{' '}
                        {reportDetails && format(parseDateToTz(reportDetails.endDate, 'yyyy-MM-dd'), 'do MMM yyyy')}
                      </span>
                    </div>
                  </li>

                  <li>
                    <div style={{ padding: '10px 15px', color: '#89817f' }}>
                      <i style={{ paddingRight: '10px' }} className='fa fa-bookmark' />
                      Industry Sector{' '}
                      <span id='industry-sector' style={{ float: 'right' }}>
                        {industriesList?.find((i) => i.id === organisationDetails?.industry)?.name}
                      </span>
                    </div>
                  </li>

                  <li>
                    <section style={{ color: '#89817f', padding: '10px 15px', fontSize: 14 }}>
                      <i className='fa fa-file' style={{ fontSize: 16, paddingRight: 10, color: '#bcb3aa' }} /> Reports
                      {mode === 'consumption' && (
                        <>
                          <ReportOverviewBtn
                            to={`/report/${currentOrganisation && currentOrganisation}/${
                              reportDetails && reportDetails.id
                            }/`}>
                            <i className='fa fa-arrow-right report-icon' />
                            Annual Carbon Footprint Report
                          </ReportOverviewBtn>

                          <ReportOverviewBtn
                            to={'#'}
                            onClick={() => {
                              !excelProcessing && queryMetrics?.length > 0 && getReportExcel(reportDetails.id);
                            }}
                            style={{ cursor: queryMetrics?.length > 0 ? 'pointer' : 'not-allowed' }}>
                            <Tooltip
                              content={'You need to select at least one metric to download the report'}
                              tipContentClassName='addUsageData-tooltip-content'
                              color='white'
                              useHover={queryMetrics?.length > 0 ? false : true}
                              distance={20}>
                              <i
                                className={`${excelProcessing ? `fa fa-spin fa-spinner` : `fa fa-file-excel-o`} report-icon`}
                              />
                              Excel Report
                            </Tooltip>
                          </ReportOverviewBtn>

                          <ReportOverviewBtn
                            to={'#'}
                            onClick={() => !pdfProcessing && getReportPDF(reportDetails.id, true, false)}>
                            <i className={`${pdfProcessing ? `fa fa-spin fa-spinner` : `fa fa-file-pdf-o`} report-icon`} />
                            PDF Report
                          </ReportOverviewBtn>
                        </>
                      )}
                      {mode === 'monthly' && (
                        <ReportOverviewBtn
                          to={`/report/${currentOrganisation && currentOrganisation}/${reportDetails && reportDetails.id}/`}
                          className='label label-success label-mini'>
                          <i className='fa fa-arrow-right report-icon' /> Annual Carbon Footprint Report
                        </ReportOverviewBtn>
                      )}
                      {mode !== 'consumption' && mode !== 'monthly' && reportDetails && (
                        <>
                          <ReportOverviewBtn
                            to={`/consumption/${currentOrganisation && currentOrganisation}/${
                              reportDetails && reportDetails.id
                            }/`}>
                            <i className='fa fa-arrow-right report-icon' /> Annual Consumption Report
                          </ReportOverviewBtn>

                          <ReportOverviewBtn
                            to={'#'}
                            onClick={() => !excelProcessing && queryMetrics?.length > 0 && getReportExcel(reportDetails.id)}
                            style={{ cursor: queryMetrics?.length > 0 ? 'pointer' : 'not-allowed' }}>
                            <Tooltip
                              content={'You need to select at least one metric to download the report'}
                              tipContentClassName='addUsageData-tooltip-content'
                              color='white'
                              useHover={queryMetrics?.length > 0 ? false : true}
                              distance={20}>
                              <i
                                className={`${excelProcessing ? `fa fa-spin fa-spinner` : `fa fa-file-excel-o`} report-icon`}
                              />
                              Excel Report
                            </Tooltip>
                          </ReportOverviewBtn>

                          <ReportOverviewBtn
                            to={'#'}
                            onClick={() => !pdfProcessing && getReportPDF(reportDetails.id, false, false)}>
                            <i className={`${pdfProcessing ? `fa fa-spin fa-spinner` : `fa fa-file-pdf-o`} report-icon`} />
                            PDF Report
                          </ReportOverviewBtn>
                        </>
                      )}
                    </section>
                  </li>
                </ul>
              </section>
            </aside>
          </section>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { organisation, report } = state;

  const filters = state.consumption?.filter;

  const metricName =
    organisation?.details &&
    ['intensityRatioMetric1ID', 'intensityRatioMetric2ID', 'intensityRatioMetric3ID'].map((ir, index) => {
      const orgIndustry = _.find(organisation?.industries, { id: organisation.details?.industry });
      const metricID = orgIndustry?.[ir];
      const metric = state.metric?.list?.find((metric) => metric?.id !== 230393 && metric?.id === metricID);

      const metricName = metric && `metric${index + 1}`;

      return metricName;
    });

  if (window.location.pathname.split('/')[1] === 'consumption') {
    metricName?.unshift('absolute', 'tCO2Million');
  } else if (window.location.pathname.split('/')[1] === 'report') {
    metricName?.unshift('orgEmissions', 'tCO2Million');
  }

  const updateMetricsName = [];

  filters?.forEach((metric) => {
    if (metric === 'm1') {
      updateMetricsName.push('metric1');
    } else if (metric === 'm2') {
      updateMetricsName.push('metric2');
    } else if (metric === 'm3') {
      updateMetricsName.push('metric3');
    } else if (metric === 'co2e') {
      updateMetricsName.push('orgEmissions');
    } else if (metric === 'revenue') {
      updateMetricsName.push('tCO2Million');
    } else if (metric === 'absolute') {
      updateMetricsName.push('absolute');
    }
  });

  const queryMetrics = metricName?.filter((metric) => !updateMetricsName?.includes(metric) && metric !== undefined);

  return {
    industriesList: organisation?.industries,
    reportDetails: report?.details,
    excelProcessing: report?.download?.excel,
    pdfProcessing: report?.download?.pdf,
    organisationDetails: organisation?.details,
    currentOrganisation: state.currentOrganisation,
    queryMetrics,
    updateMetricsName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getReportPDF: (id, consumption, groupByMonth) => dispatch(REPORT_ACTIONS.getReportPDF(id, consumption, groupByMonth)),
  getReportExcel: (id) => dispatch(REPORT_ACTIONS.getReportExcel(id)),
  getReport: (id) => dispatch(REPORT_ACTIONS.getReport(id)),
  getConsumption: (monthly) => dispatch(CONSUMPTION_ACTIONS.getConsumption(monthly)),
  getOrganisation: () => dispatch(ORGANISATION_ACTIONS.getOrganisation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportOverview);
