import React from 'react';
import _ from 'lodash';

import { connect } from 'react-redux';

const EmissionsByGreenhouseGasesRow = ({
  report,
  totalEmissionsByScope,
  organisationDetails,
  organisationMetrics,
  scope,
  isMarket,
}) => {
  if (!report || !organisationDetails || !organisationMetrics || !totalEmissionsByScope) return null;

  const { year } = report;

  const hasComparative = totalEmissionsByScope && totalEmissionsByScope[year - 1];

  const co2eLabel = isMarket ? 'co2e_market' : 'co2e';
  const co2Label = isMarket ? 'co2_market' : 'co2';
  const ch4Label = isMarket ? 'ch4_market' : 'ch4';
  const n2oLabel = isMarket ? 'n2o_market' : 'n2o';

  const co2e = scope
    ? (totalEmissionsByScope &&
        totalEmissionsByScope[year] &&
        totalEmissionsByScope[year][scope] &&
        totalEmissionsByScope[year][scope][co2eLabel]) ||
      0
    : (totalEmissionsByScope &&
        totalEmissionsByScope[year] &&
        _.sumBy(Object.values(totalEmissionsByScope[year]), co2eLabel)) ||
      0;

  const co2eComparative =
    hasComparative && scope
      ? (hasComparative && hasComparative[scope] && hasComparative[scope][co2eLabel]) || 0
      : (hasComparative && _.sumBy(Object.values(hasComparative), co2eLabel)) || 0;

  const co2 = scope
    ? (totalEmissionsByScope &&
        totalEmissionsByScope[year] &&
        totalEmissionsByScope[year][scope] &&
        totalEmissionsByScope[year][scope][co2Label]) ||
      0
    : (totalEmissionsByScope &&
        totalEmissionsByScope[year] &&
        _.sumBy(Object.values(totalEmissionsByScope[year]), co2Label)) ||
      0;

  const co2Comparative =
    hasComparative && scope
      ? (hasComparative && hasComparative[scope] && hasComparative[scope][co2Label]) || 0
      : (hasComparative && _.sumBy(Object.values(hasComparative), co2Label)) || 0;

  const ch4 = scope
    ? (totalEmissionsByScope &&
        totalEmissionsByScope[year] &&
        totalEmissionsByScope[year][scope] &&
        totalEmissionsByScope[year][scope][ch4Label]) ||
      0
    : (totalEmissionsByScope &&
        totalEmissionsByScope[year] &&
        _.sumBy(Object.values(totalEmissionsByScope[year]), ch4Label)) ||
      0;

  const ch4Comparative =
    hasComparative && scope
      ? (hasComparative && hasComparative[scope] && hasComparative[scope][ch4Label]) || 0
      : (hasComparative && _.sumBy(Object.values(hasComparative), ch4Label)) || 0;

  const n2o = scope
    ? (totalEmissionsByScope &&
        totalEmissionsByScope[year] &&
        totalEmissionsByScope[year][scope] &&
        totalEmissionsByScope[year][scope][n2oLabel]) ||
      0
    : (totalEmissionsByScope &&
        totalEmissionsByScope[year] &&
        _.sumBy(Object.values(totalEmissionsByScope[year]), n2oLabel)) ||
      0;

  const n2oComparative =
    hasComparative && scope
      ? (hasComparative && hasComparative[scope] && hasComparative[scope][n2oLabel]) || 0
      : (hasComparative && _.sumBy(Object.values(hasComparative), n2oLabel)) || 0;

  return (
    <tr>
      <td align='left'>{scope ? `Scope ${scope}` : `All Scopes`}</td>
      {hasComparative && (
        <td className='toggle-comparative'>
          {parseFloat(co2eComparative || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
        </td>
      )}
      <td>{parseFloat(co2e || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</td>
      {hasComparative && (
        <td className='toggle-comparative'>
          {parseFloat(co2Comparative || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
        </td>
      )}
      <td>{parseFloat(co2 || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</td>
      {hasComparative && (
        <td className='toggle-comparative'>
          {parseFloat(ch4Comparative || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
        </td>
      )}
      <td>{parseFloat(ch4 || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</td>
      {hasComparative && (
        <td className='toggle-comparative'>
          {parseFloat(n2oComparative || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
        </td>
      )}
      <td>{parseFloat(n2o || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  const { totalEmissionsByScope, filter, withMarketFactors } = state.consumption || {};

  const { organisation, report } = state;

  const isMarket = withMarketFactors && !filter.includes('market');

  return {
    report: report?.details,
    totalEmissionsByScope: totalEmissionsByScope,
    metrics: state.metric?.list,
    organisationDetails: organisation?.details,
    organisationMetrics: organisation?.metrics,
    metricValues: organisation?.metrics ? organisation.metrics : null,
    isMarket,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsByGreenhouseGasesRow);
