import React, { useState } from 'react';
import { underline, explainerModal, explainerModalArrow } from '../codeStyles';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

const Explainer = ({ description, children, id }) => {
  const [show, setShow] = useState(false);

  return (
    <span
      id={`${id}-selectMarkdown`}
      style={underline}
      onClick={() => {
        setShow(!show);
      }}>
      {children}
      {show && (
        <div style={explainerModal}>
          <div style={explainerModalArrow}></div>
          {/* <div dangerouslySetInnerHTML={{ __html: description }}></div> */}
          <div style={{ maxHeight: 400, overflowY: 'scroll' }} id={`${id}-markdown`}>
            <ReactMarkdown remarkPlugins={[gfm]} children={description} />
          </div>
        </div>
      )}
    </span>
  );
};

export default Explainer;
