import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../components/Common/Breadcrumb';
import { AddButton } from '../styledComponents/GlobalStyle';
import { Button, IconButton } from '@mui/material';
import { ContentCopy, VisibilityOutlined, Close } from '@mui/icons-material';
import axios from 'axios';
import createSignedRequest from '../store/actions/createSignedRequest';
import { Waveform } from '@uiball/loaders';
import { Alert } from '@mui/material';
const { apiURL } = require('../store/actions/apiURL');

const Developer = (currentOrganisation) => {
  const [hasClientID, setHasClientID] = React.useState(false);
  const [clientId, setClientId] = React.useState(''); // Add your Client ID here
  const [clientSecret, setClientSecret] = React.useState(''); // Add your Client Secret here
  const [showLoader, setShowLoader] = React.useState(false);
  const [pageLoader, setPageLoader] = React.useState(true);
  const [alertMessage, setAlertMessage] = React.useState({});
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(false);
    setPageLoader(true);
    setAlertMessage({});
    setClientId('');
    setClientSecret('');
    const getClientIdByOrgId = async (orgId) => {
      try {
        const response = await axios(
          await createSignedRequest('GET', apiURL + `/my/developer/getClientIdByOrgId`, null, {
            Organisation: String(orgId),
          })
        );
        console.log('response:', response);
        const data = response.data;
        if (data !== null) {
          setClientId(data);
          setHasClientID(true);
        } else {
          setHasClientID(false);
        }
      } catch (error) {
        // Handle any errors that occur during the fetch request
        console.error('Error:', error);
      }
      setPageLoader(false);
    };

    getClientIdByOrgId(currentOrganisation.currentOrganisation);
  }, [currentOrganisation]);

  const rows = [
    {
      name: 'Client ID',
      description:
        "Click on 'Generate Client ID' in order to generate your client Id to be used with OAuth 2.0 authentication.",
      field: clientId,
    },
    {
      name: 'Client Secret',
      description: 'This is the client secret needed in order to authenticate with the OAuth 2.0 provider.',
      field: clientSecret === '' ? '******************' : clientSecret,
    },
    {
      name: 'Auth URL',
      description: '',
      field: apiURL.includes('archerirm') ? 'https://cyf-archer.auth.eu-west-2.amazoncognito.com/oauth2/authorize' : 'https://auth.compareyourfootprint.com/oauth2/authorize' // Add your Auth URL here
    },
    {
      name: 'Access Token URL',
      description: '',
      field: apiURL.includes('archerirm') ? 'https://cyf-archer.auth.eu-west-2.amazoncognito.com/oauth2/token' : 'https://auth.compareyourfootprint.com/oauth2/token' // Add your Access Token URL here
    },
  ];

  const generateClientId = async () => {
    setShowLoader(true);
    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/developer/createApp`, null, {
          Organisation: String(currentOrganisation.currentOrganisation),
        })
      );
      const data = response.data;
      // Handle the data or the response here
      if (data?.clientId) {
        setOpen(true);
        setAlertMessage({ message: data?.message, severity: 'success' });
        setClientId(data?.clientId);
        setHasClientID(true);
      } else {
        setHasClientID(false);
      }
    } catch (error) {
      // Handle any errors that occur during the fetch request
      console.error('Error:', error);
      setAlertMessage({ message: error.message, severity: 'error' });
      setOpen(true);
    }
    setShowLoader(false);
  };

  const getClientSecret = async () => {
    setShowLoader(true);
    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/developer/getClientSecret?clientId=${clientId}`, null, {
          Organisation: String(currentOrganisation.currentOrganisation),
        })
      );
      console.log('response:', response);
      const data = response.data;
      if (data?.error) {
        setAlertMessage({ message: data?.message, severity: 'error' });
        setOpen(true);
      } else {
        setClientSecret(data);
      }
    } catch (error) {
      // Handle any errors that occur during the fetch request
      console.error('Error:', error);
    }
    setShowLoader(false);
  };

  const getDocsUrl = () => {
    const currUrl = window.location.href;
    if (currUrl.split('.')[0].includes('dev' || 'localhost')) {
      return 'https://api.app.compareyourfootprint.com/dev/api-docs/';
    } else if (currUrl.split('.')[0].includes('staging2')) {
      return 'https://api.app.compareyourfootprint.com/stage2/api-docs/';
    } else if (currUrl.split('.')[0].includes('staging')) {
      return 'https://api.app.compareyourfootprint.com/stage/api-docs/';
    } else if (currUrl.split('.')[0].includes('archerirm')) {
      return 'https://api.archerirm.compareyourfootprint.com/v1/api-docs/';
    } else {
      return 'https://api.app.compareyourfootprint.com/v1/api-docs/';
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb items={[{ label: 'Developer Dashboard', link: '/developer' }]} />
      <div className='col-lg-12'>
        <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'space-between' }}>
          <Button
            size='large'
            startIcon={<i className='fa-solid fa-book'></i>}
            disableRipple
            variant='contained'
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              width: '18%',
              minWidth: '205px',
              height: '80px',
              fontSize: '15px',
              justifyContent: 'flex-start',
              color: 'black',
              textTransform: 'none',
              paddingLeft: '20px',
              backgroundColor: 'white',
            }}
            onClick={() => {
              let url = getDocsUrl();
              window.open(url, '_blank');
            }}>
            API Documentation
          </Button>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <Alert
              severity={alertMessage.severity}
              style={{
                display: open ? 'flex' : 'none',
                fontFamily: 'inherit',
                width: '100%',
                borderRadius: '10px',
                fontSize: '12px',
                textTransform: 'none',
                justifyContent: 'center',
                alignContent: 'center',
              }}
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setOpen(false);
                  }}>
                  <Close fontSize='inherit' />
                </IconButton>
              }>
              {alertMessage.message}
            </Alert>
          </div>
        </div>
        {pageLoader ? (
          <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
            <Waveform size={40} lineWeight={3.5} speed={1} color='black' />
          </div>
        ) : (
          <section className='panel'>
            <header className='panel-heading' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <b>API Information</b>
              </div>
              <AddButton
                className='btn btn-success'
                onClick={() => {
                  generateClientId();
                }}
                bordercolor='true'
                disabled={showLoader}
                style={{
                  display: hasClientID ? 'none' : 'flex',
                  marginRight: 10,
                  alignContent: 'center',
                }}>
                Generate Client ID
                {'  '}
                <i
                  style={{
                    display: showLoader ? 'flex' : 'none',
                    marginLeft: '10px',
                    marginTop: '2px',
                    justifyContent: 'center',
                  }}
                  className='fa fa-spin fa-spinner'
                />
              </AddButton>
            </header>
            <div className='panel-body'>
              <table
                id='api-settings'
                className='table table-hover general-table'
                style={{ borderCollapse: 'collapse', justifyContent: 'center' }}>
                <tbody>
                  {rows.map((row, i) => (
                    <React.Fragment key={i}>
                      <tr border='2px'>
                        <td width='20%' height='70px' style={{ paddingRight: '20px' }}>
                          {rows[i].name}
                        </td>
                        <td width='55%' style={{ paddingRight: '20px' }}>
                          {rows[i].description}
                        </td>
                        <td width='25%'>
                          {rows[i].field === '' ? (
                            <>{rows[i].field}</>
                          ) : i === 1 ? (
                            <div style={{ display: clientId === '' ? 'none' : 'flex' }}>
                              <code style={{ color: 'black', backgroundColor: 'inherit' }}>{rows[i].field}</code>
                              {'   '}
                              {/* Show secret button visible only when ClientId is set and Client Secret has not been fetched yet */}
                              <IconButton
                                sx={{
                                  position: 'relative',
                                  display: clientSecret !== '' || showLoader === true ? 'none' : 'flex',
                                }}
                                onClick={(e) => {
                                  getClientSecret();
                                }}>
                                <VisibilityOutlined />
                              </IconButton>
                              {/* Spinner icon shown only when show secret button is pressed until function finishes */}
                              <div
                                style={{
                                  display: showLoader ? 'flex' : 'none',
                                  width: '20px',
                                  height: '20px',
                                  paddingLeft: 15,
                                }}>
                                <i className='fa fa-spin fa-spinner' />
                              </div>
                              {'   '}
                              {/* Copy button shown only when Client secret has been fetched */}
                              <IconButton
                                sx={{ position: 'relative', display: clientSecret === '' ? 'none' : 'flex' }}
                                onClick={(e) => {
                                  navigator.clipboard.writeText(rows[i].field);
                                }}>
                                <ContentCopy />
                              </IconButton>
                            </div>
                          ) : (
                            <>
                              <code style={{ color: 'black', backgroundColor: 'inherit' }}>{rows[i].field}</code>
                              {'   '}
                              <IconButton
                                sx={{ position: 'relative' }}
                                onClick={(e) => {
                                  navigator.clipboard.writeText(rows[i].field);
                                }}>
                                <ContentCopy />
                              </IconButton>
                            </>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    currentOrganisation: state.currentOrganisation,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Developer);
