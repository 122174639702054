import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { Actions as ADMIN_ACTIONS } from '../../store/actions/adminActions';
import { useHistory } from 'react-router-dom';
import UsageGroupRowData from './UsageGroupRowData';
import { Checkbox } from '@mui/material';
import { AdminPortalBulkChange } from '../../styledComponents/GlobalStyle';

const EntityData = ({
  usageTotals,
  cyfAdmin,
  entityChecked,
  updateEntityCheckBox,
  entityName,
  entityId,
  toggleModal,
  submitBulkEditForEntity,
  checkboxes,
  initializeCheckBoxes,
}) => {
  let count = 0;

  const history = useHistory();

  usageTotals.forEach((item) => {
    count += item.count;
  });

  useEffect(() => {
    if (!checkboxes) {
      initializeCheckBoxes();
    }
  }, []);

  return (
    <React.Fragment>
      <thead>
        <tr id={`usage-${entityName?.split(' ').join('').replace(':', '-')}-table-header-row`}>
          <th colSpan='5' style={{ paddingLeft: '0' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <div>
                {cyfAdmin && (
                  <Checkbox id='bulkOptions-btn' checked={entityChecked} onChange={() => updateEntityCheckBox(entityId)} />
                )}
                <span id='usage-entityName'>{entityName}</span>
                <span id='usage-count' style={{ marginLeft: 5 }}>
                  ({count})
                </span>
              </div>

              {entityChecked && cyfAdmin && (
                <div style={{ display: 'flex' }}>
                  <AdminPortalBulkChange
                    to={'#'}
                    style={{ marginTop: '2px' }}
                    className='btn'
                    id='bulk-edit-btn'
                    onClick={() => {
                      submitBulkEditForEntity(entityId);
                      history.push(`/usage/${entityId}`);
                    }}>
                    {' '}
                    Bulk Edit {entityName} Data
                  </AdminPortalBulkChange>
                  <AdminPortalBulkChange
                    to={'#'}
                    style={{ marginTop: '2px', display: 'flex', marginLeft: '1vw' }}
                    className='btn'
                    id='bulk-delete-btn'
                    onClick={() => {
                      submitBulkEditForEntity(entityId);
                      toggleModal(true, 'usageData_entity-bulk_delete');
                    }}>
                    {' '}
                    Bulk Delete {entityName} Data
                  </AdminPortalBulkChange>
                </div>
              )}
            </div>
          </th>
        </tr>

        <tr id='usage-table-common-headers-row'>
          <th>Type</th>
          <th>Usage</th>
          <th>Facility</th>
          <th>Data starts between</th>
          <th>View breakdown</th>
        </tr>
      </thead>
      <tbody id={`usage-${entityName?.split(' ').join('').replace(':', '-')}-table-body`}>
        {usageTotals?.map((usageTotal, index) => {
          return <UsageGroupRowData key={index} rowIndex={index} usageTotal={usageTotal} />;
        })}
      </tbody>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  const { entity, entityName } = props.usageTotals[0];

  return {
    entityId: entity,
    entityName,
    entityChecked: state?.admin?.checkboxes?.entities[entity]?.checked || false,
    cyfAdmin: state?.profile?.details?.cyfAdmin,
    currentOrganisation: state?.currentOrganisation,
    branding: state?.branding,
    checkedEntityData: state?.admin?.checkboxes?.checkedUsage,
    checkboxes: state?.admin?.checkboxes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  initializeCheckBoxes: () => dispatch(ADMIN_ACTIONS.initializeCheckBoxes()),
  submitBulkEditForEntity: (entity) => dispatch(ADMIN_ACTIONS.submitBulkEditForEntity(entity)),
  updateEntityCheckBox: (entity) => dispatch(ADMIN_ACTIONS.updateEntityCheckBox(entity)),
  toggleModal: (status, action, item, modalProps) => dispatch(ADMIN_ACTIONS.toggleModal(status, action, item, modalProps)),
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(EntityData));
