const customStyle = {
  container: (base) => ({
    ...base,
    display: 'inline-block',
    float: 'right',
    width: '260px',
    marginTop: '40px',
  }),
};

// Admin Portal
const adminPortalSelect = {
  container: (provided) => ({
    ...provided,
    float: 'right',
    marginLeft: 40,
    paddingBottom: 10,
  }),
  control: (base) => ({
    ...base,
    height: '35.69px',
    minHeight: 30,
    width: 200,
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    };
  },
};

// New Organisation Component styles
const selectStyles = {
  container: (provided, state) => ({
    ...provided,
    display: 'inline-block',
    float: 'right',
    width: '30vw',
  }),
  control: (base) => ({
    ...base,
    minHeight: '2.5vw',
    padding: '2px 8px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
  }),
};

const flexBetweenCenter = { display: 'flex', justifyContent: 'space-between', alignItems: 'center' };

//Invite User Form
const whiteLabelSelect = {
  container: (base) => ({
    ...base,
    display: 'inline-block',
    float: 'right',
    width: '350px',
  }),
};

//Payment Method Select Component
const paymentMethodSelectStyles = {
  container: (provided) => ({
    ...provided,
    width: '65%',
  }),
  control: (css) => ({
    ...css,
    fontFamily: 'Open Sans,sans-serif',
    fontSize: 12,
    color: '#156823',
    cursor: 'pointer',
    borderRadius: 15,
    ':hover': {
      boxShadow: 'none',
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: '200px',
  }),
  option: (provided) => ({
    ...provided,
    padding: 12,
    fontSize: 12,
    cursor: 'pointer',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 1,
    marginBottom: 11,
  }),
};

//Facility Component

const facilitySelect = {
  container: (provided, state) => ({
    ...provided,
    width: '165px',
    float: 'right',
    bottom: 7,
  }),
  control: (base) => ({
    ...base,
    height: 30,
    minHeight: 30,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    /* color: 'rgb(137, 129, 127)',
    lineHeight: '14px',
    fontSize: 14,
    padding: '0 6px',
    position: 'auto', */
  }),

  clearIndicator: (provided, state) => ({
    ...provided,
    padding: '0 4px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: '0 4px',
  }),
};

const accountCodeSelectStyle = {
  clearIndicator: (defaultStyles) => ({
    ...defaultStyles,
    top: '0',
    right: '-47px',
    position: 'absolute',
    background: 'red',
    color: 'white',
    borderRadius: '4px',
  }),
  indicatorSeparator: () => {},
  indicatorContainer: () => ({}),
  dropdownIndicator: (defaultStyles) => ({
    ...defaultStyles,
    position: 'absolute',
    top: '0',
    right: '0',
    padding: '15px',
    backgroundImage: "url('https://maze.taxtaker.com/assets/images/gray-arrow-down.svg')",
    backgroundSize: '10px',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    backgroundPosition: 'center',
    marginLeft: 10,
    '& svg': { display: 'none' },
    ':hover': {
      cursor: 'pointer',
    },
  }),
  container: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    backgroundColor: 'rgb(241, 241, 242)',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: 0,
    borderRadius: 3,
    color: 'rgb(0, 0, 0)',
    cursor: 'default',
    display: 'inline-block',
    fontFamily: 'sans-serif',
    fontSize: 14,
    fontWeight: '400',
    height: '37px',
    lineHeight: '21px',
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    padding: '3px',
    textAlign: 'start',
    textIndent: 0,
    whiteSpace: 'pre',
    maxWidth: 1200,
    minWidth: 287.594,
  }),
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    // width: 200,
    // background: "transparent",
  }),
  multiValue: (provided, state) => ({
    borderRadius: '2px',
    display: 'flex',
    minWidth: 0,
    boxSizing: 'border-box',
    marginRight: 4,
  }),
  multiValueRemove: () => ({
    alignItems: 'center',
    borderRadius: 2,
    display: 'flex',
    paddingLeft: 4,
    paddingRight: 5,
    boxSizing: 'border-box',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: '#FFBDAD',
      color: '#DE350B',
      paddingRight: 5,
    },
  }),
};

const xeroAccountCodeSelectStyle = {
  clearIndicator: (defaultStyles) => ({
    ...defaultStyles,
    top: '0',
    right: '-47px',
    position: 'absolute',
    background: 'red',
    color: 'white',
    borderRadius: '4px',
  }),
  indicatorSeparator: () => {},
  indicatorContainer: () => ({}),
  dropdownIndicator: (defaultStyles) => ({
    ...defaultStyles,
    position: 'absolute',
    top: '0',
    right: '0',
    padding: '15px',
    backgroundImage: "url('https://maze.taxtaker.com/assets/images/gray-arrow-down.svg')",
    backgroundSize: '10px',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    backgroundPosition: 'center',
    marginLeft: 10,
    '& svg': { display: 'none' },
    ':hover': {
      cursor: 'pointer',
    },
  }),
  container: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    backgroundColor: 'rgb(241, 241, 242)',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: 0,
    borderRadius: 3,
    color: 'rgb(0, 0, 0)',
    cursor: 'default',
    display: 'inline-block',
    fontFamily: 'sans-serif',
    fontSize: 14,
    fontWeight: '400',
    height: '37px',
    lineHeight: '21px',
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    padding: '3px',
    textAlign: 'start',
    textIndent: 0,
    whiteSpace: 'pre',
    maxWidth: 1200,
    minWidth: 287.594,
  }),
  control: (provided, state) => ({
    // none of react-select's styles are passed to <Control />
    // width: 200,
    // background: "transparent",
  }),
  multiValue: (provided, state) => ({
    borderRadius: '2px',
    display: 'flex',
    minWidth: 0,
    boxSizing: 'border-box',
    marginRight: 4,
  }),
  multiValueRemove: (provided, state) => ({
    //-webkit-align-items: center,
    //-webkit-box-align: center,
    //-ms-flex-align: center,
    alignItems: 'center',
    borderRadius: 2,
    display: 'flex',
    paddingLeft: 4,
    paddingRight: 5,
    boxSizing: 'border-box',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: '#FFBDAD',
      color: '#DE350B',
      paddingRight: 5,
    },
  }),
};

export {
  customStyle,
  adminPortalSelect,
  selectStyles,
  flexBetweenCenter,
  whiteLabelSelect,
  paymentMethodSelectStyles,
  facilitySelect,
  accountCodeSelectStyle,
  xeroAccountCodeSelectStyle,
};
