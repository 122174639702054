import LinearProgress from '@mui/material/LinearProgress';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AddButton } from '../../styledComponents/GlobalStyle';
import Modal from '../Organisations/Modal';
import InviteUsersForm from './InviteUsersForm';
import UsersListRow from './UsersListRow';

const UsersListTable = ({ branding, sortedUserList, adminUser }) => {
  const [showInviteUserForm, setShowInviteUserForm] = useState(false);

  const { body } = branding.fonts?.[0].font;

  if (!sortedUserList?.length > 0 && !sortedUserList) {
    return <LinearProgress color='inherit' />;
  }

  return (
    <div className='row' style={{ fontFamily: body }}>
      <div className='col-lg-12'>
        <section className='panel'>
          <header className='panel-heading'>Your Users</header>
          <div className='panel-body'>
            <table id='users-table' className='table table-hover general-table'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Surname</th>
                  <th>Email</th>
                  {adminUser && <th>Status</th>}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(sortedUserList) &&
                  sortedUserList.map((userDetails, index) => (
                    <UsersListRow key={index} userDetails={userDetails} adminUser={adminUser} />
                  ))}
                {branding && branding?.inviteUsers ? (
                  <tr>
                    <td style={{ border: 'none' }}>
                      <AddButton id='invite-user-button' onClick={() => setShowInviteUserForm(!showInviteUserForm)}>
                        Invite User
                      </AddButton>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </section>
      </div>
      {showInviteUserForm && (
        <InviteUsersForm setShowInviteUserForm={setShowInviteUserForm} showInviteUserForm={showInviteUserForm} />
      )}
      <Modal />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { cyfAdmin, whiteLabelAdmin, consultantAdmin } = state?.profile?.details || {};
  const adminUser = cyfAdmin || whiteLabelAdmin || consultantAdmin;

  const sortedUserList =
    state.organisation &&
    state.organisation.users &&
    _.uniqBy(state.organisation.users, 'sub').sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  return {
    currentOrganisation: state.currentOrganisation,
    branding: state.branding,
    sortedUserList,
    adminUser,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UsersListTable);
