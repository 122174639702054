import axios from 'axios';
import createSignedRequest from './createSignedRequest';
const { apiURL } = require('./apiURL');

const Types = {
  GET: 'METRICS_GET',
  GET_TYPES: 'METRICS_GET_TYPES',
  LIST_SCOPE3_CATEGORIES: 'METRICS_LIST_SCOPE3_CATEGORIES',
};

const updateMetrics = (response) => ({
  type: Types.GET,
  payload: response,
});

const getMetrics = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios(await createSignedRequest('GET', apiURL + `/impact/metrics`, null));

      console.log('getMetrics', response);
      dispatch(updateMetrics(response.data));
    } catch (e) {
      console.log('getMetrics Error:', e);
    }
  };
};

const updateTypes = (response) => ({
  type: Types.GET_TYPES,
  payload: response,
});

export const getTypes = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios(await createSignedRequest('GET', apiURL + `/impact/types`, null));

      console.log('getTypes', response);
      dispatch(updateTypes(response.data));
    } catch (e) {
      console.log('getTypes Error:', e);
    }
  };
};

const updateScope3Categories = (response) => ({
  type: Types.LIST_SCOPE3_CATEGORIES,
  payload: response,
});

const getScope3Categories = () => {
  return async (dispatch, getState) => {
    try {
      const response = await axios(await createSignedRequest('GET', apiURL + `/impact/scope3Categories`, null));

      console.log('getScope3Categories', response);
      dispatch(updateScope3Categories(response.data));
    } catch (e) {
      console.log('getScope3Categories Error:', e);
    }
  };
};

const Actions = {
  getMetrics,
  getTypes,
  getScope3Categories,
};

export { Actions, Types };
