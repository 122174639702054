import _ from 'lodash';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import gfm from 'remark-gfm';
import * as yup from 'yup';
import { Actions as AUTHENTICATION_ACTIONS } from '../../store/actions/authenticationActions';
import ValidationMessage from '../utils/ValidationMessage';

import { AuthBtn } from '../../styledComponents/GlobalStyle';

const UserLicenseAgreementModal = ({
  branding,
  confirmUserAgreement,
  userLicenseAgreement,
  privacyPolicy,
  whiteLabelName,
  whiteLabelId,
}) => {
  const { primaryColor } = branding.colors || {};
  const [validationError, setValidationError] = useState(null);
  const [userLicenseConfirmed, setUserLicenseConfirmed] = useState(false);

  const validationSchema = yup.object().shape({
    userLicenseConfirmed: yup
      .boolean()
      .oneOf([true], 'Accepting the User License Agreement and Privacy Policy is required')
      .required(),
  });

  const isWhiteLabelApp = whiteLabelId ? (whiteLabelId !== '0' ? true : false) : undefined;

  const confirmUserLicenseAgreement = async () => {
    await validationSchema
      .validate(
        {
          userLicenseConfirmed: userLicenseConfirmed,
          softwareLicenseConfirmed: true,
        },
        { abortEarly: false }
      )
      .then(async () => {
        confirmUserAgreement(false);
      })
      .catch((error) => {
        console.log('ValidationError', error);
        setValidationError(error.inner);
      });
  };

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)', zIndex: 2000000 }}>
      <div className='modal-dialog userLicenseAgreementModal'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>User License Agreement and Privacy Policy</h4>
          </div>
          <div
            className='modal-body'
            style={{ height: '85vh', overflow: 'auto', fontSize: '12px', background: 'white', borderRadius: '6px' }}>
            <div style={{ textAlign: 'justify', color: 'black' }}>
              <div style={{ display: isWhiteLabelApp && isWhiteLabelApp === true ? 'block' : 'none' }}>
                <p>Powered by Compare Your Footprint Ltd for {whiteLabelName}.</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {isWhiteLabelApp === false ? (
                  <ReactMarkdown>
                    ![ScreenShot](https://s3.eu-west-2.amazonaws.com/downloads.compareyourfootprint.com/branding/compareyourfootprint/Picture+1.jpg)
                  </ReactMarkdown>
                ) : (
                  <p>Powered by Compare Your Footprint Ltd for {whiteLabelName}.</p>
                )}
              </div>
              <p className='warning-agreement-text' style={{ '--warning-bgColor': primaryColor }}>
                Please review these agreements and click to accept to continue.
              </p>
              <ReactMarkdown remarkPlugins={[gfm]} className={'userAgreement-text'} children={userLicenseAgreement} />
              <ReactMarkdown
                remarkPlugins={[gfm]}
                className={'privacyPolicy-text privacy-policy-text'}
                children={privacyPolicy}
              />
            </div>
            <div style={{ margin: '20 px 10px' }}>
              <div id='accept-ula-checkbox' style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type='checkbox'
                  style={{ marginBottom: 10 }}
                  onChange={() => setUserLicenseConfirmed(!userLicenseConfirmed)}
                />
                <label style={{ fontWeight: 400, marginBottom: 7, marginLeft: 5 }}>
                  By clicking you are accepting to our <strong>User License Agreement</strong> and{' '}
                  <strong>Privacy Policy</strong>.
                </label>
              </div>

              <ValidationMessage message={_.find(validationError, { path: 'userLicenseConfirmed' })?.message} />

              <div style={{ display: 'flex', justifyContent: 'center', margin: 20 }}>
                <AuthBtn
                  className='btn btn-lg btn-login btn-block onceonly'
                  style={{ color: 'white', width: 'fit-content', fontSize: 16 }}
                  onClick={(e) => {
                    e.preventDefault();
                    confirmUserLicenseAgreement();
                  }}>
                  Confirm
                </AuthBtn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  confirmUserAgreement: (inclOrg) => dispatch(AUTHENTICATION_ACTIONS.confirmUserAgreement(inclOrg)),
});

const mapStateToProps = (state) => {
  const licenses = state.licenses;
  const { whiteLabelId, name: whiteLabelName } = state.whiteLabelAdmin;
  const userLicenseAgreement =
    licenses && Array.isArray(licenses) && licenses.find((license) => license.type === 'User License Agreement')?.text;
  const privacyPolicy =
    licenses && Array.isArray(licenses) && licenses.find((license) => license.type === 'Privacy Policy')?.text;

  return {
    whiteLabelId,
    whiteLabelName,
    userLicenseAgreement,
    privacyPolicy,
    branding: state.branding,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLicenseAgreementModal);
