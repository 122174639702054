import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import Breadcrumb from '../../Common/Breadcrumb';
import ClosestAirportListTable from './ClosestAirportListTable';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';

const items = [{ label: 'Closest Airport', link: '/admin/closestAirport' }];

const ClosestAirportList = ({ toggleModal, itemCount, filterSize }) => {
  const [page, setPage] = useState(1);
  const [tableSize, setTableSize] = useState(filterSize[0]);
  const [totalItems, setTotalItems] = useState(null);
  const handleChange = (event, value) => setPage(value);
  const total = totalItems ? totalItems : itemCount;

  console.log('pagiTotal', total);
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <Breadcrumb items={items} />

        {/* <div style={{ marginRight: '1.5vh' }}>
          <AddButton
            className='btn btn-success'
            onClick={() => toggleModal(true, 'entities-table-add', null)}
            bgcolor='true'
            bordercolor='true'>
            Add an Airport
          </AddButton>
        </div> */}
      </Box>

      <ClosestAirportListTable page={page} setTableSize={setTableSize} tableSize={tableSize} setTotalItems={setTotalItems} />

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Pagination count={Math.ceil(total / tableSize?.value)} page={page} onChange={handleChange} />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    itemCount: state.admin?.indexItems?.totalCount,
    filterSize: state.admin.filterSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClosestAirportList);
