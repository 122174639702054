import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { getAppVersion } from './store/actions/apiURL';
import configureStore from './store/store';

const reduxStore = configureStore(window.REDUX_INITIAL_DATA);
const version = getAppVersion('production');

Sentry.init({
  dsn: 'https://e3d59f39a797c247dd180a6df044f98f@o4506274951921664.ingest.sentry.io/4506274953428992',
  integrations: [
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    new Sentry.BrowserTracing({ tracePropagationTargets: ['localhost', /^https:\/\/compareyourfootprint\.com/] }),
    Sentry.replayIntegration({ maskAllInputs: true }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  tracesSampleRate: 0.3,
  // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: version,
  maskAllInputs: true,
});

if (window.Cypress) {
  window.store = reduxStore;
}

const AppWrapper = () => {
  return (
    <ReduxProvider store={reduxStore}>
      <App />
    </ReduxProvider>
  );
};

document.addEventListener('DOMContentLoaded', function () {
  const container = document.querySelector('#root');
  if (container) {
    const root = createRoot(container);
    root.render(<AppWrapper />);
  }
});
