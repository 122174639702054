const privacyPolicy = () =>
  `
**Privacy Policy**

**Background:**

**In this Policy, unless specified otherwise, words and phrases shall
have the same meanings as specified in our Terms and Conditions which
can be viewed here [insert link].**

COMPARE YOUR FOOTPRINT LTD, a company incorporated in Scotland
(Registered No. SC689618) with its registered office at 7 Ferrymuir
Lane, South Queensferry, Scotland, EH30 9PA understands that your
privacy is important to you and that you care about how your personal
data is used and shared online. We respect and value the privacy of
Customers and will only collect and use personal data in ways that are
described here, and in a manner that is consistent with Our obligations
and your rights under the law.

Please read this Privacy Policy carefully and ensure that you understand
it. You will be required to read and accept this Privacy Policy when
signing up for an Account. If you do not accept and agree with this
Privacy Policy, you must stop using Our Subscription Services
immediately.

1  **Definitions and Interpretation**

In this Policy, the following terms shall have the following meanings:

1. **"Account"**: means an account required to access and/or use Subscription Service(s);  
2. **"Cookie"** : means a small text file placed on your computer or device by Our Subscription Services when you visit certain parts  Our Subscription Services and/or when  use certain features of Our Subscripti Services. Details of the Cookies used  Our Subscription Services are set out  section 12, below;
3. **“Cookie Law”** : means the relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003
4. **“personal data”*:  means any and all data that relates to an identifiable person who can be directly or indirectly identified from that data.  In this case, it means personal data that you give to Us via Our Subscription Services. This definition shall, where applicable, incorporate the definitions provided in the United Kingdom General Data Protection Regulation (UK GDPR) and  the Data Protection Act 2018 (collectively "**Privacy Legislation**"); and  
5. **"We/Us/Our"**: means the said Compare Your Footprint Ltd. (as above).   

2 **Information About Us**

1.  Data Protection Officer: William Richardson
2. Email address: hello@compareyourfootprint.com
3. Telephone number: +44 (0) 203 545 0992
4. Postal address: 7 Ferrymuir Lane, South Queensferry, Scotland, EH30 9PA.

3  **What Does This Policy Cover?**

This Privacy Policy applies only to our use of data gathered by Us in your use of the Subscription Services.

4  **Your Rights**

1.  As a data subject, you have the following rights under the
        Privacy Legislation, which this Policy and Our use of personal
        data have been designed to uphold:

    1.  The right to be informed about Our collection and use of
            personal data;

    2.  The right of access to the personal data We hold about you
            or your employees and customers/clients (see section 11);

    3.  The right to rectification if any personal data We hold
            about you or your employees and customers/clients is
            inaccurate or incomplete (please contact Us using the
            details in section 13);

    4.  The right to be forgotten -- i.e. the right to ask Us to
            delete any personal data We hold about you or your employees
            or customers/ clients (We only hold personal data for a
            limited time, as explained in section 6 but if you would
            like Us to delete it sooner, please contact Us using the
            details in section 13). Please note that deletion of certain
            personal data may prevent the Subscription Services from
            functioning;

    5.  The right to restrict (i.e. prevent) the processing of
            personal data;

    6.  The right to data portability (obtaining a copy of personal
            data to re-use with another service or organisation);

    7.  The right to object to Us using personal data for particular
            purposes; and

    8.  Rights with respect to automated decision making and
            profiling.

2.  If you have any cause for complaint about Our use of personal
    data, please contact Us using the details provided in section 13
    and We will do Our best to solve the problem for you. If We are unable to help, you also have the right to lodge a complaint  with the UK's supervisory authority, the Information  Commissioner's Office details of which can be found here  *[https://ico.org.uk/]{.ul}*


5  **What Data Do We Collect?**

We may collect some or all of the following personal and non-personal data:
    
1.  first name / last name;

2.  business/company name

3.  job title/role;

4.  contact information such as email addresses and telephone numbers;

5.  demographic information such as business/company address, post code,
user preferences, and interests;

6.  financial information such as credit / debit card numbers;

7.  IP address;

8.  web browser type and version;

9.  operating system;

10.  a list of URLs starting with a referring site, your activity on Our
        Subscription Services, and the site you exit to;
    
   
6  **How Do We Use Your Data?**
    
1.  All personal data is processed and stored securely, for no
    longer than is necessary in light of the reason(s) for which it
    was first collected. We will comply with Our obligations and
    safeguard your rights under the Privacy Legislation at all
    times. For more details on security see section 7, below.
    
       
2.  Our use of personal data will always have a lawful basis, either
    because it is necessary for Our performance of a contract with
    you, because you have consented to Our use of personal data
    (e.g. by subscribing to emails or for delivery of the
    Subscription Services), or because it is in Our legitimate
    interests. Specifically, We may use personal data for the
    following purposes:
    
    1.  Providing and managing your Account;
    
    2.  Providing and managing your access to the Subscription
                Services;
    
    2.  Personalising and tailoring your experience in the Subscription Services;
    
    3.  Supplying Our products and services to you (please note that We require your personal data in order to enter into a contract with you);
    
    4.  Personalising and tailoring Our products and services for you;
    
    5.  Replying to emails from you;
    
    6.  Supplying you with emails that you have opted into (you may
        unsubscribe or opt-out at any time by clicking on the
        unsubscribe link within the email received
    
    7.  Market research;
    
    8.  Analysing your use of Our Subscription Services and
        gathering feedback to enable Us to continually improve Our
        Subscription Services and your user experience;
    
3.  With your permission and/or where permitted by law, We may also
    use your data for marketing purposes which may include
    contacting you by email and telephone and text message and post
    with information, news and offers on Our products and ervices.
    We will not, however, send you any unsolicited marketing or spam
    and will take all reasonable steps to ensure that We fully
    protect your rights and comply with Our obligations under the
    Privacy Legislation and the Privacy and Electronic
    Communications (EC Directive) Regulations 2003.
    
4.  You have the right to withdraw your consent to Us using your
    personal data at any time, and to request that We delete it;
    however this may adversely affect the delivery, use and function
    of the Subscription Services.
    
5.  We do not keep personal data for any longer than is necessary in
    light of the reason(s) for which it was first collected. Data
    will therefore be retained on the following bases:
    
    1.  To ensure that content related to carbon footprint
        calculation data is assigned to a business / company for
        future use. Data retained will be business/company name,
        email address, business/company address.
    
7  **How and Where Do We Store Your Data?**
    
1.  We only keep personal data for as long as We need to in order to
    use it as described above in section 6, and/or for as long as We
    have your permission to keep it.
           
2.  Some or all of your data may be stored or transferred outside of
    the UK/ the European Economic Area (the "EEA"). The EEA consists
    of all EU member states, plus Norway, Iceland, and
    Liechtenstein. These are known as "third countries". We will
    take additional steps in order to ensure that your personal data
    is treated just as safely and securely as it would be within the
    UK/ EEA and under the Privacy Legislation as follows:
    
    1.  We will use specific approved contracts which ensure the
        same levels of personal data protection that apply under the
        Data Protection Legislation. For further information, please
        refer to the Information Commissioner's Office.
    
3.  Please contact Us for further information about the particular
    data protection safeguards used by Us when transferring your personal data to a third country.
    
4.  The security of your personal data is essential to Us, and to protect your data, We take a number of important measures, including the following:
    
    1.  limiting access to your personal data to those employees,
        agents, contractors, and other third parties with a
        legitimate need to know and ensuring that they are subject
        to duties of confidentiality;
    
    2.  procedures for dealing with data breaches (the accidental or
        unlawful destruction, loss, alteration, unauthorised
        disclosure of, or access to, your personal data) including
        notifying you and/or the Information Commissioner's Office
        where We are legally required to do so.


8  **Do We Share Your Data?**

1.  We may share your data with other companies in Our group for
            administrative and internal management. This includes Our
            subsidiaries and/or Our holding company and its subsidiaries.

2.  We may sometimes contract with third parties to supply products
            and services to you on Our behalf. These may include payment
            processing, delivery of goods, search engine facilities,
            advertising, and marketing. In some cases, the third parties may
            require access to some or all of your data. Where any of your
            data is required for such a purpose, We will take all reasonable
            steps to ensure that your data will be handled safely, securely,
            and in accordance with your rights, Our obligations, and the
            obligations of the third party under the law.
    
3.  We may compile statistics about the use of products and services
    including the Subscription Services including data on traffic,
    usage patterns, user numbers, sales, and other information. All
    such data will be anonymised and will not include any personally
    identifying data, or any anonymised data that can be combined
    with other data and used to identify you or your employees [or
    customers]. We may from time to time share such data with third
    parties such as prospective investors, affiliates, partners, and
    advertisers. Data will only be shared and used within the bounds
    of the law.

4.  We contract with the following third parties to supply certain
    products/ services.

|  RECIPIENT   | ACTIVITY CARRIED OUT    | SECTOR              | LOCATION |
| --------------- | --------------- | ----------------- | ------- |
| Amazon Web Services| Website Host |Software|UK|
| Amazon Cognito | Registration portal |Software|UK|
| CircleCo, Inc. | Online community and forum |Software|USA|
| Continually Ltd | Website chatbot and chat function | Software | UK|
| PandaDoc Inc. | E-Contracts | Software | USA|
| Zapier Inc. | Providing automation support between services | Software | USA|
| Calltech Limited| Providing answerphone support | Receptionist | UK|
| Insightly Inc.| Customer relationship management| Software | USA|
| Calendly LLC| Meeting bookings | Software | USA|
| Active Campaign LLC| Webforms, marketing and mail-server| Software | USA|
| Ringcentral Inc.| Providing VOIP telephone services | Software | USA|
| Aidaform| Webforms| Software | Germany|
| GoCardless| Direct debit payments | Finance | UK|
| FreeAgent| Accountancy software | Finance | UK|
| Stripe| Digital payments| Finance| UK|



5.  If any of your personal data is shared with a third party, as
    described above, We will take steps to ensure that your personal
    data is handled safely, securely, and in accordance with your
    rights, Our obligations, and the third party's obligations under the
    law, as described above in section 7.

6.  In certain circumstances, We may be legally required to share
    certain data held by Us, which may include your personal data, for
    example, where We are involved in legal proceedings, where We are
    complying with legal requirements, a court order, or a governmental
    authority.

9  **What Happens If Our Business Changes Hands?**

1.  We may, from time to time, expand or reduce Our business and
    this may involve the sale and/or the transfer of control of all
    or part of Our business. Any personal data that you have
    provided will, where it is relevant to any part of Our business
    that is being transferred, be transferred along with that part
    and the new owner or newly controlling party will, under the
    terms of this Privacy Policy, be permitted to use that data only
    for the same purposes for which it was originally collected by
    Us.

    
2.  In the event that any of your data is to be transferred in such
    a manner, you will not be contacted in advance but you will be
    informed of the changes via our website [www.compareyourfootprint.com](http://www.compareyourfootprint.com).
    You will be given the choice to have your data deleted or withheld at any time.

10 **How Can You Control Your Data?**

1.  When you submit personal data to us, you may be given options to
    restrict Our use of your data. In particular, We aim to give you
    strong controls on Our use of your data for direct marketing
    purposes (including the ability to opt-out of receiving emails
    from Us which you may do by unsubscribing using the links
    provided in Our emails and at the point of providing your
    details and by managing your Account).

2.  You may also wish to sign up to one or more of the preference
    services operating in the UK: The Telephone Preference Service
    ("the TPS"), the Corporate Telephone Preference Service ("the
    CTPS"), and the Mailing Preference Service ("the MPS"). These
    may help to prevent you receiving unsolicited marketing. Please
    note, however, that these services will not prevent you from
    receiving marketing communications that you have consented to
    receiving.

11 **How Can You Access Your Data?**

 You have the right to ask for a copy of any of your personal data held by Us (where such data is held). Please contact Us for more details at
 [**support@compareyourfootprint.com**](mailto:support@compareyourfootprint.com) or using the contact details below in section 13.

12 **Our Use of Cookies**

1.  Our Subscription Services may place and access certain first
    party Cookies on your computer or device. First party Cookies
    are those placed directly by Us and are used only by Us. We use
    Cookies to facilitate and improve your experience of Our
    Subscription Services and to provide and improve Our products
    and services. We have carefully chosen these Cookies and have
    taken steps to ensure that your privacy and personal data is
    protected and respected at all times.

2. By using Our Subscription Services you may also receive certain
    third party Cookies on your computer or device. Third party
    Cookies are those placed by websites, services, and/or parties
    other than Us. For more details, please refer section 12.6
    below.

3. All Cookies used by and on Our Subscription Services are used in
    accordance with current Cookie Law.

4. Before Cookies are placed on your computer or device, you will
    be shown a pop-up requesting your consent to set those Cookies.
    By giving your consent to the placing of Cookies you are
    enabling Us to provide the best possible experience and service
    to you. You may, if you wish, deny consent to the placing of
    Cookies; however certain features of Our Subscription Services
    may not function fully or as intended.

5. Certain features of Our Subscription Services depend on Cookies
    to function. Cookie Law deems these Cookies to be "strictly
    necessary". These Cookies are shown below in section 12.6. Your
    consent will not be sought to place these Cookies, but it is
    still important that you are aware of them. You may still block
    these Cookies by changing your internet browser's settings as
    detailed below in section 12.10, but please be aware that Our
    Subscription Services may not work properly if you do so. We
    have taken great care to ensure that your privacy is not at risk
    by allowing them.

6. The following first party Cookies may be placed on your computer
   or device:

    |  Name of Cookie  | Purpose    | Strictly Necessary          | 
    | --------------- | --------------- | ----------------- | 
    | Compare Your Footprint Authentication | To register your name, company and email address to Amazon Cognito in order for you to create and access your Compare Your Footprint account   |Yes|
   
7.  and the following third-party Cookies may be placed on your computer or device:
    
    |  Name of Cookie  | Purpose    | Strictly Necessary          | 
    | --------------- | --------------- | ----------------- | 
    | Stripe | To accept and confirm your recurring payment for chosen subscription | Yes|
    | ActiveCampaign| To communicate with you through different stages of your user journey through app.compareyourfootprint.com | Yes|

7. **[**We use analytics services provided by provided by **Google Analytics**. Website analytics refers to a set of tools
    used to collect and analyse anonymous usage information, enabling Us
    to better understand how Our Subscription Services are used. This,
    in turn, enables Us to improve Our Subscription Services and the
    products and services offered through it. You do not have to allow
    Us to use these Cookies, however whilst Our use of them does not
    pose any risk to your privacy or your safe use of Our Subscription
    Services, it does enable Us to continually improve Our Subscription
    Services, making it a better and more useful experience for you.
8.  The analytics services used by Our Subscription Services use Cookies
    to gather the required information.

9.  The analytics services used by Our Subscription Services use the
    following Cookies:

    |  Name of Cookie  | First/Third Party| Purpose    | Strictly Necessary          | 
    | --------------- | ------ |--------------- | ----------------- | 
    | Google Analytics | Third Party   |To improve your experience of app.compareyourfootprint.com |No|
  
10. In addition to the controls that We provide, you can choose to enable or disable Cookies in your internet browser. Most internet browsers also enable you to choose whether you wish to disable all cookies or only third party Cookies. By default, most internet browsers accept Cookies but this can be changed. For further details, please consult the help menu in your internet browser or the documentation that came with your device.

11. You can choose to delete Cookies on your computer or device at any time, however you may lose any information that enables you to access Our Subscription Services more quickly and efficiently including, but not limited to, login and personalisation settings.

12. It is recommended that you keep your internet browser and operating system up-to-date and that you consult the help and guidance provided by the developer of your internet browser and manufacturer of your computer or device if you are unsure about adjusting your privacy settings.

13 **Contacting Us**

If you have any questions about Our Subscription Services or this
Privacy Policy, please contact Us by email at
<hello@compareyourfootprint.com>, by telephone on +44 (0) 203 545
0992, or by post at 7 Ferrymuir Lane, South Queensferry, Scotland,
EH30 9PA. Please ensure that your query is clear, particularly if it
is a request for information about the data We hold about you (as
under section 11, above).

14 **Changes to Our Privacy Policy**

We may change this Privacy Policy from time to time (for example, if
the law changes). Any changes will be immediately posted on Our
Subscription Services and you will be deemed to have accepted the
terms of the Privacy Policy on your first use of Our Subscription
Services following the alterations. We recommend that you check this
page regularly to keep up-to-date.





`;

export default privacyPolicy;
