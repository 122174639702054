import React from 'react';
import { connect } from 'react-redux';

import EmissionsDisaggregatedByCustomCategoryRow from './EmissionsDisaggregatedByCustomCategoryRow';

const EmissionsDisaggregatedByCustomCategory = ({ customLabels, emissionsByCustomCategory }) => {
  if (customLabels && customLabels.length === 0) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <div>
      <br />
      <br />
      <h3>Emissions disaggregated by custom category</h3>
      <table className='table table-hover general-table' style={{ textAlign: 'right' }}>
        <thead>
          <tr>
            <th width='20%'>Custom Category</th>
            <th width='20%' style={{ textAlign: 'right' }}>
              Total (tCO2e)
            </th>
            <th width='20%' style={{ textAlign: 'right' }}>
              Scope 1 (tCO2e)
            </th>
            <th width='20%' style={{ textAlign: 'right' }}>
              Scope 2 (tCO2e)
            </th>
            <th width='20%' style={{ textAlign: 'right' }}>
              Scope 3 (tCO2e incl. WTT*)
            </th>
          </tr>
        </thead>
        <tbody>
          {customLabels &&
            customLabels.map((custCat, index) => {
              //console.log('custCat', custCat)

              const categoryData = emissionsByCustomCategory && emissionsByCustomCategory[custCat];

              //console.log('custCat', categoryData)

              return (
                <EmissionsDisaggregatedByCustomCategoryRow
                  key={index}
                  customCategory={custCat}
                  customCategoryData={categoryData}
                />
              );
            })}
        </tbody>
      </table>
      <br></br>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { emissionsByCustomCategory, customCategories } = state.consumption;

  const { report } = state;

  const reportYear = report && report.details && report.details.year;

  const customLabels = customCategories && customCategories.filter((custCat) => custCat !== null);

  return {
    emissionsByCustomCategory: emissionsByCustomCategory && reportYear && emissionsByCustomCategory[reportYear],
    customLabels,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsDisaggregatedByCustomCategory);
