import { Fade, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Actions as ADMINACTIONS, toggleModal } from '../../../store/actions/adminActions';
import { AddButton, CalendarBtn } from '../../../styledComponents/GlobalStyle';
import Breadcrumb from '../../Common/Breadcrumb';
import BulkEditModal from '../../Modals/BulkEditModal';
import AddEditUsage from '../../UsageData/AddEditUsage';
import { itemToOptions, parseDateToTz } from '../../utils/GlobalFunctions';
import { adminPortalSelect } from '../../utils/ReactSelectStyles';
import UsageDataListTable from './UsageDataListTable';

const items = [{ label: 'Usage Data', link: '/admin/usageData' }];

const divStyle = {
  display: 'flex',
  width: '-webkit-fill-available',
  margin: '0px 10px 10px 10px',
  justifyContent: 'space-between',
  alignItems: 'baseline',
};

const UsageDataList = ({
  getAllItemsFromDB,
  bulkEditModal,
  itemCount,
  branding,
  filterSize,
  options,
  getItemsByTermsFromIndex,
  getItemsByTermsFromIndexExport,
  allFacilities,
  adminPortalModal,
  confirmationModal,
  openSearchTerms,
  usageEditModal,
}) => {
  const { headers } = branding?.fonts?.[0]?.font || {};

  const [page, setPage] = useState(1);
  const [tableSize, setTableSize] = useState(filterSize ? filterSize?.[0] : null);
  const [totalItems, setTotalItems] = useState(null);
  const [filter, setFilter] = useState({});
  const [showHide, setShowHide] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const [exporting, setExporting] = useState(false);

  const tableName = 'usageData';

  const handlePageChange = (_, value) => {
    setPage(value);
    getItemsByTermsFromIndex(tableName, filter, value, tableSize?.value);
  };

  const handleTableSizeChange = (selected) => {
    setTableSize(selected);
    setPage(1);
    getItemsByTermsFromIndex(tableName, filter, 1, selected?.value);
  };

  const exportData = async () => {
    setExporting(true);
    const newFilter = { ...filter };
    const filterName = 'generateExport';
    newFilter[filterName] = true;
    await getItemsByTermsFromIndexExport(tableName, newFilter, page, tableSize?.value);
    setExporting(false);
  };

  const handleFilter = (selectedFilter, e) => {
    const newFilter = { ...filter };
    const filterName = e?.target?.name || e.name;

    if (e?.action === 'clear' || !selectedFilter || e?.target?.value === '') {
      delete newFilter[filterName];
    } else if (e?.target?.type === 'text') {
      newFilter[filterName] = e?.target?.value;
    } else if (typeof selectedFilter.getMonth === 'function') {
      const objProp = Object.getOwnPropertyNames(e);
      newFilter[objProp[0]] = { ...newFilter[objProp[0]], ...e[objProp[0]] };
    } else {
      newFilter[filterName] = selectedFilter?.value || selectedFilter;
    }
    setFilter(newFilter);
  };

  const total = totalItems ? totalItems : itemCount;
  useEffect(() => {
    //if (openSearchTerms?.filters && openSearchTerms?.index == tableName) setFilter({ ...openSearchTerms.filters });

    if (!allFacilities) getAllItemsFromDB('facilities');
    if (showHide) setShowPagination(true);
  }, [showHide, page, tableSize, adminPortalModal, confirmationModal, bulkEditModal, allFacilities, getAllItemsFromDB]);

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Breadcrumb items={items} />
      </Box>

      {!showHide && (
        <section className='panel' style={{ fontFamily: headers }}>
          <header
            className='panel-heading'
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            Usage Data Filters
          </header>
          <div className='panel-body' style={{ height: 'auto' }}>
            {Object.keys(options)?.map((optionType, index) => {
              if (options[optionType] === 'SearchByText') {
                return (
                  <div key={index} style={divStyle}>
                    <label style={{ fontSize: 16, marginBottom: 0 }}>
                      {optionType.charAt(0).toUpperCase() + optionType.slice(1)}
                    </label>
                    <div style={{ width: '80%' }}>
                      <TextField
                        id='outlined-basic'
                        onChange={(e) => handleFilter('id', e)}
                        label='Search By Id'
                        variant='outlined'
                        name='id'
                        sx={{ width: '100%' }}
                      />
                    </div>
                  </div>
                );
              } else if (options[optionType] === 'DatePicker') {
                return (
                  <div key={index} style={divStyle}>
                    <label style={{ fontSize: 16, marginBottom: 0 }}>
                      {optionType.charAt(0).toUpperCase() + optionType.slice(1)}
                    </label>
                    <div style={{ width: '80%', display: 'flex', justifyContent: 'space-between', zIndex: 0 }}>
                      <div style={{ width: '40%' }}>
                        <label className='input-group date datepicker'>
                          <DatePicker
                            showMonthDropdown
                            name={options?.[optionType]}
                            showYearDropdown
                            yearDropdownItemNumber={5}
                            scrollableYearDropdown
                            dateFormat='dd/MM/yyyy'
                            className='form-control startDate'
                            popperPlacement='bottom-end'
                            maxDate={new Date()}
                            selected={
                              (filter?.[optionType]?.['gte'] &&
                                parseDateToTz(filter?.[optionType]?.['gte'], 'yyyy-MM-dd')) ||
                              null
                            }
                            isClearable={true}
                            placeholderText='From'
                            onChange={(date) => {
                              if (!date) handleFilter(null, { name: optionType });
                              else handleFilter(date, { [optionType]: { gte: format(date, 'yyyy-MM-dd') } });
                            }}
                            popperModifiers={{ offset: { enabled: true, offset: '77px, 0px' } }}
                          />
                          <span className='input-group-btn'>
                            <CalendarBtn className='btn btn-primary date-set'>
                              <i className='fa fa-calendar' />
                            </CalendarBtn>
                          </span>
                        </label>
                      </div>
                      <div style={{ width: '40%' }}>
                        <label className='input-group date datepicker'>
                          <DatePicker
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            dateFormat='dd/MM/yyyy'
                            className='form-control startDate'
                            popperPlacement='bottom-end'
                            yearDropdownItemNumber={5}
                            isClearable={true}
                            maxDate={new Date()}
                            selected={
                              (filter?.[optionType]?.['lte'] &&
                                parseDateToTz(filter?.[optionType]?.['lte'], 'yyyy-MM-dd')) ||
                              null
                            }
                            placeholderText='To'
                            onChange={(date) => {
                              if (!date) handleFilter(null, { name: optionType });
                              else handleFilter(date, { [optionType]: { lte: format(date, 'yyyy-MM-dd') } });
                            }}
                            popperModifiers={{ offset: { enabled: true, offset: '77px, 0px' } }}
                          />
                          <span className='input-group-btn'>
                            <CalendarBtn className='btn btn-primary date-set'>
                              <i className='fa fa-calendar' />
                            </CalendarBtn>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={index} style={divStyle}>
                    <label style={{ fontSize: 16, marginBottom: 0 }}>
                      {optionType.charAt(0).toUpperCase() + optionType.slice(1)}
                    </label>
                    <div style={{ width: '80%' }}>
                      <Select
                        id={'select-' + optionType}
                        options={options?.[optionType]}
                        name={optionType}
                        style={adminPortalSelect}
                        onChange={(selected, e) => handleFilter(selected, e)}
                        isClearable={true}
                      />
                    </div>
                  </div>
                );
              }
            })}
            <div
              style={{
                display: 'flex',
                placeItems: 'center',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                marginTop: 20,
              }}>
              <AddButton
                id='search-btn'
                className='btn'
                onClick={() => {
                  getItemsByTermsFromIndex(tableName, filter, page, tableSize?.value);
                  setShowHide(true);
                }}
                disabled={_.isEmpty(filter)}
                style={{ width: '20vh', fontSize: 16 }}>
                Search
              </AddButton>
              <AddButton
                id='export-btn'
                className='btn'
                onClick={() => {
                  exportData();
                }}
                disabled={_.isEmpty(filter) || exporting}
                style={{ width: '20vh', fontSize: 16 }}>
                {exporting && <i className={`fa fa-spin fa-spinner`} />} Export
              </AddButton>
            </div>
          </div>
        </section>
      )}

      {showHide && (
        <>
          <UsageDataListTable
            page={page}
            setTableSize={setTableSize}
            tableSize={tableSize}
            setTotalItems={setTotalItems}
            setShowHide={setShowHide}
            handleTableSizeChange={handleTableSizeChange}
            setFilter={setFilter}
            filter={filter}
            setPage={setPage}
            options={options}
            setShowPagination={setShowPagination}
          />

          {total > 0 && showPagination && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Pagination count={Math.ceil(total / tableSize?.value)} page={page} onChange={handlePageChange} />
            </div>
          )}
        </>
      )}

      {bulkEditModal && (
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={bulkEditModal}
          onClose={toggleModal}
          closeAfterTransition>
          <Fade in={bulkEditModal}>
            <div>
              <BulkEditModal bulkType={tableName} options={options} />
            </div>
          </Fade>
        </Modal>
      )}

      {usageEditModal && <AddEditUsage />}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const options = {};
  const optionTypes = state?.metric?.types;
  const subentities = state?.subentities;
  const entities = state?.entities;
  const metrics = state?.metric?.list;
  const organisations = state?.admin?.organisations;
  const facilities = state?.admin?.adminPortal?.facilities;
  options.id = 'SearchByText';
  options.entity = entities
    ?.concat({ name: '<null>', id: 'NA' })
    ?.map(itemToOptions)
    ?.sort((a, b) => a.label.localeCompare(b.label));
  options.subentity = subentities
    ?.concat({ name: '<null>', id: 'NA' })
    ?.map(itemToOptions)
    ?.sort((a, b) => a.label.localeCompare(b.label));

  optionTypes &&
    Object.keys(optionTypes)?.map((item) => {
      options[item] = optionTypes[item]
        ?.concat({ name: '<null>', id: 'NA' })
        ?.map(itemToOptions)
        ?.sort((a, b) => a.label.localeCompare(b.label));
      return options;
    });

  options.metric = metrics
    ?.concat({ name: '<null>', id: 'NA' })
    ?.map(itemToOptions)
    ?.sort((a, b) => a.label.localeCompare(b.label));
  options.organisation = organisations
    ?.concat({ name: '<null>', id: 'NA' })
    ?.map(itemToOptions)
    ?.sort((a, b) => a?.label?.localeCompare(b?.label));
  options.facility = facilities
    ?.concat({ name: '<null>', id: 'NA' })
    ?.map(itemToOptions)
    ?.sort((a, b) => a.label.localeCompare(b.label));
  options.dateStart = 'DatePicker';
  options.dateEnd = 'DatePicker';

  return {
    branding: state?.branding,
    itemCount: state?.admin?.termsItems?.totalCount,
    filterSize: state?.admin?.filterSize,
    options,
    termItems: state?.admin?.termsItems?.items,
    allFacilities: facilities,
    adminPortalModal: state.admin?.modal?.adminPortalModal,
    confirmationModal: state.admin?.modal?.confirmationModal,
    bulkEditModal: state?.admin?.modal?.bulkEditModal,
    usageEditModal: state?.usageDataModal?.status,
    openSearchTerms: state?.admin?.openSearchTerms,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
  getItemsByTermsFromIndex: (index, filters, page, size) =>
    dispatch(ADMINACTIONS.getItemsByTermsFromIndex(index, filters, page, size)),
  getItemsByTermsFromIndexExport: (index, filters, page, size) =>
    dispatch(ADMINACTIONS.getItemsByTermsFromIndexExport(index, filters, page, size)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsageDataList);
