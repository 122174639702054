import React from 'react';
import { connect } from 'react-redux';
import { PeriodMaker } from '../utils/GlobalFunctions';

import EmissionsByMainGreenhouseGasesRow from './EmissionsByMainGreenhouseGasesRow';

const EmissionsByMainGreenhouseGases = ({ report, totalEmissionsByScope }) => {
  const year = report.year;
  let isReportEmpty = Object.keys(report).length === 0;

  const hasComparative = totalEmissionsByScope && totalEmissionsByScope[year - 1];

  let lastPeriod;
  let currentPeriod;

  if (!isReportEmpty) {
    let referenceYear = +report.endDate.split('-')[0];
    lastPeriod = hasComparative ? PeriodMaker(report.startDate, report.endDate, referenceYear - 1) : null;
    currentPeriod = PeriodMaker(report.startDate, report.endDate, referenceYear);
  }
  return (
    <div>
      <br />
      <br />
      <h3>Total emissions disaggregated by main greenhouse gases</h3>
      <table id='emissions-by-main-ghg-table' className='table table-hover general-table' style={{ textAlign: 'right' }}>
        <thead>
          <tr>
            <th></th>
            {hasComparative && (
              <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                Total (tCO2e) <br />({lastPeriod})
              </th>
            )}
            <th style={{ textAlign: 'right' }}>
              Total (tCO2e) <br />({currentPeriod})
            </th>
            {hasComparative && (
              <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                tCO2 <br />({lastPeriod})
              </th>
            )}
            <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>
              tCO2 <br />({currentPeriod})
            </th>
            {hasComparative && (
              <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                tCH4 <br />({lastPeriod})
              </th>
            )}
            <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>
              tCH4 <br />({currentPeriod})
            </th>
            {hasComparative && (
              <th className='toggle-comparative' style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                tN2O <br />({lastPeriod})
              </th>
            )}
            <th style={{ textAlign: 'right', verticalAlign: 'middle' }}>
              tN2O <br />({currentPeriod})
            </th>
          </tr>
        </thead>
        <tbody>
          <EmissionsByMainGreenhouseGasesRow scope='1' />
          <EmissionsByMainGreenhouseGasesRow scope='2' />
          <EmissionsByMainGreenhouseGasesRow scope='3' />
          <EmissionsByMainGreenhouseGasesRow />
        </tbody>
      </table>
      <p>
        tCO2: tonnes of carbon dioxide
        <br />
        tCH4: tonnes of methane
        <br />
        tN2O: tonnes of nitrous oxide
      </p>
      <p style={{ width: '50%', textAlign: 'justify' }}>
        N.B. The total carbon dioxide equivalent GHGs (tCO2e) will not equal the sum of the breakdown into the 3 main gases
        in this table due to the fact that not all emissions intensities are provided split out by individual gas, plus there
        are four other groups of Kyoto Protocol gases not included in the table.
      </p>
      <br></br>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { totalEmissionsByScope, years, filter } = state.consumption || {};

  const { organisation, report } = state;

  return {
    report: report?.details || {},
    totalEmissionsByScope: totalEmissionsByScope,
    consumptionFilter: filter || [],
    years: years || [],
    metrics: state?.metric?.list,
    organisationDetails: organisation?.details,
    organisationMetrics: organisation?.metrics,
    metricValues: organisation.metrics ? organisation.metrics : null,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsByMainGreenhouseGases);
