import { Types } from '../actions/facilityActions';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const defaultState = {};

const facilityReducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.LIST: {
      let newState = _.cloneDeep(state);
      newState.facility = state.facility ? { ...state.facility } : {};
      newState.facility.list = action.payload.facilities;

      return newState;
    }
    case Types.TYPES: {
      let newState = _.cloneDeep(state);

      newState.facility = state.facility ? { ...state.facility } : {};
      newState.facility.types = action.payload;

      return newState;
    }
    case Types.METRICS: {
      let newState = _.cloneDeep(state);

      newState.facility = state.facility ? { ...state.facility } : {};
      newState.facility.metrics = action.payload;

      return newState;
    }
    case Types.ADD_METRIC: {
      let newState = { ...state };

      const uuid = uuidv4();

      newState.facility.metrics = state.facility?.metrics ? [...state.facility?.metrics] : [];
      newState.facility.metrics.push({ facility: action.facility, id: uuid, uuid });

      return newState;
    }
    case Types.SAVE_METRIC: {
      let newState = { ...state };

      const metricID = action.payload.id;
      const metric = action.metric;

      newState.facility.metrics = [...newState.facility.metrics];

      const metricIndex = newState.facility.metrics.findIndex(
        (item) => item.id === metricID || (item.uuid && item.uuid === metric.uuid)
      );
      metric.id = metricID;

      newState.facility.metrics[metricIndex] = metric;

      return newState;
    }
    case Types.SAVE: {
      let newState = { ...state };

      const facilityId = action.payload.id;
      const facility = action.facility;

      const facilityIndex = newState.facility.list.findIndex((item) => item.id === facilityId);

      if (facilityIndex === -1) {
        // Saving New Facility
        newState.facility.list.push({ ...facility, id: facilityId });
      } else {
        // Updating Facility
        newState.facility.list[facilityIndex] = { ...newState.facility.list[facilityIndex], ...facility };
      }

      return newState;
    }

    case Types.FACILITY_METRIC_DELETE_MODAL: {
      const newState = _.cloneDeep(state);

      newState.facility.facilityDataDeleteModal = { status: action.status, item: action.item };

      return newState;
    }

    case Types.FACILITY_METRIC_DATA_DELETE: {
      const id = action.id;

      const newState = _.cloneDeep(state);

      newState.facility.facilityDataDeleteModal = { status: false, item: false };

      const newFacilityState = { ...newState.facility };

      //Deleting the Item From the State
      const updatingFacilityState = newFacilityState?.metrics.filter((item) => item.id !== id);

      newState.facility.metrics = updatingFacilityState;

      return newState;
    }

    case Types.FACILITY_DELETE_MODAL: {
      let newState = _.cloneDeep(state);

      if (action.errorMsg) {
        // Facility has usage assigned, show error Message
        newState.facility.facilitiesDeleteModal = {
          ...newState.facility.facilitiesDeleteModal,
          errorMsg: action.errorMsg,
        };
      } else {
        newState.facility.facilitiesDeleteModal = {
          status: action.status,
          item: action.item,
          errorMsg: null,
        };
      }

      return newState;
    }

    case Types.FACILITY_DATA_DELETE: {
      const id = action.id;

      const newState = _.cloneDeep(state);

      //Close the Modal
      newState.facility.facilitiesDeleteModal = { status: false, item: false };

      const newFacilityState = { ...newState.facility };

      //Deleting the Item From the State
      const updatingFacilityState = newFacilityState?.list?.filter((item) => item.id !== id);

      newState.facility.list = updatingFacilityState;

      return newState;
    }

    case Types.FACILITY_IMPORT_SHOW_MODAL: {
      const newState = _.cloneDeep(state);

      newState.facility.facilityImportModal = action.status;

      return newState;
    }

    case Types.FACILITY_PROCESSING_IMPORTING: {
      const newState = _.cloneDeep(state);

      newState.facility.processingFacilityImport = action.importing;

      return newState;
    }

    case Types.FACILITY_IMPORT: {
      let newState = _.cloneDeep(state);

      const { facilities } = action.payload.data?.facilities;

      if (action.payload.data.successful) {
        newState.facility.successfulImport = action.payload.data.successful;
        newState.facility.errorImport = action.payload.data.errors;
      }

      newState.facility.list = [...facilities];
      newState.facility.processingFacilityImport = false;

      return newState;
    }

    case Types.FACILITY_ANALYSIS_IMPORT_MODAL: {
      const newState = _.cloneDeep(state);

      newState.facility.facilityAnalysisImportModal = action.status;

      if (!action.status) {
        newState.facility.errorImport = [];
        newState.facility.successfulImport = [];
      }

      return newState;
    }

    case Types.FACILITY_ADD_FACILITY_MODAL: {
      const newState = _.cloneDeep(state);
      newState.facilityDataModal = {
        status: action.status,
      };

      if (action.status === false) {
        newState.facilityDataSaved = null;
      }

      return newState;
    }

    default:
      return state;
  }
};

export default facilityReducers;
