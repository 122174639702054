import { Auth } from '@aws-amplify/auth';
import { Signer } from '@aws-amplify/core';

const createSignedRequest = async (method, endpoint, data = '', headers = {}) => {
  const userCredentials = await Auth.currentCredentials();

  // For Local Testing
  if (process.env.REACT_APP_LOCAL_BACK_TEST) {
    const splitEndpoint = endpoint.split(':3001')[1];
    if (['/payment'].some((x) => endpoint.includes(x))) {
      // Run Payment Lambda Local
      endpoint = `http://localhost:3002${splitEndpoint}`;
    } else if (['/registration', '/authentication'].some((x) => endpoint.includes(x))) {
      // Run Auth Lambda Local
      endpoint = `http://localhost:3003${splitEndpoint}`;
    } else {
      // Run xeroAPI Lambda Local
      endpoint = `http://localhost:3001${splitEndpoint}`;
    }
  }

  const request = {
    method: method,
    url: endpoint,
    data: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
  };

  const serviceInfo = {
    service: 'execute-api',
    region: 'eu-west-2',
  };

  const accessInfo = {
    access_key: userCredentials.accessKeyId,
    secret_key: userCredentials.secretAccessKey,
    session_token: userCredentials.sessionToken,
  };

  //console.log('userCredentials', userCredentials)

  const signedRequest = Signer.sign(request, accessInfo, serviceInfo);

  delete signedRequest.headers['host']; //Remove host header

  //console.log('signedRequest',signedRequest)
  return signedRequest;
};

// export default apiURL;
export default createSignedRequest;
