import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';
import { Actions } from '../../store/actions/facilityActions';
import FacilityMetricRow from './FacilityMetricRow';

import { AddButton } from '../../styledComponents/GlobalStyle';

const FacilityMetrics = ({ list, facMetrics, addFacilityMetric, branding, getFacilityMetrics, currentOrganisation }) => {
  const { primaryColor } = branding.colors || {};

  useEffect(() => {
    if (currentOrganisation) {
      getFacilityMetrics();
    }
  }, [currentOrganisation, getFacilityMetrics]);

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <section className='panel'>
          <header id='metric-table-panel-heading' className='panel-heading'>
            Your Facility Metrics
          </header>
          <div id='metric-table' className='panel-body'>
            <table className='table table-hover general-table'>
              <thead>
                <tr>
                  <th>Reporting Period</th>
                  {facMetrics?.metric1 && (
                    <th id='orgMetric1'>
                      <Tooltip
                        styles={{ width: 'fit-content' }}
                        content={facMetrics?.metric1.description}
                        background={primaryColor}
                        color='white'>
                        {facMetrics?.metric1?.name}
                        <i className='fa fa-info-circle' style={{ cursor: 'pointer', marginLeft: 5 }} />
                      </Tooltip>
                    </th>
                  )}
                  {facMetrics.metric2 && (
                    <th id='orgMetric2'>
                      <Tooltip
                        styles={{ width: 'fit-content' }}
                        content={facMetrics?.metric2?.description}
                        background={primaryColor}
                        color='white'>
                        {facMetrics?.metric2?.name}
                        <i className='fa fa-info-circle' style={{ cursor: 'pointer', marginLeft: 5 }} />
                      </Tooltip>
                    </th>
                  )}
                  {facMetrics.metric3 && (
                    <th id='orgMetric3'>
                      <Tooltip
                        styles={{ width: 'fit-content' }}
                        content={facMetrics?.metric3?.description}
                        background={primaryColor}
                        color='white'>
                        {facMetrics?.metric3?.name}
                        <i className='fa fa-info-circle' style={{ cursor: 'pointer', marginLeft: 5 }} />
                      </Tooltip>
                    </th>
                  )}
                  <th></th>
                </tr>
              </thead>
              <tbody id='facMetrics-Rows'>
                {list &&
                  Array.isArray(list) &&
                  list.map((metric, index) => (
                    <FacilityMetricRow key={index} metric={metric} facMetrics={facMetrics} list={list} />
                  ))}
              </tbody>
            </table>

            <AddButton id='add-a-metric-button' className='btn btn-success' onClick={addFacilityMetric}>
              Add a Metric
            </AddButton>
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const facilityID = ownProps.id;

  const organisation = state?.organisation?.details;
  const metrics = state?.metric?.list;
  const industries = state?.organisation?.industries;
  const orgIndustry = _.find(industries, { id: organisation?.industry });

  const facMetrics = {};

  if (metrics && organisation) {
    facMetrics.metric1 = metrics?.find((item) => item?.name === 'm2');
    facMetrics.metric2 = metrics?.find((item) => item?.name === 'FTE');

    if (orgIndustry?.intensityRatioMetric1ID) {
      const metric = metrics?.find((item) => item?.id === orgIndustry?.intensityRatioMetric1ID);

      if (!['currency', 'm2', 'FTE'].includes(metric?.name)) {
        facMetrics.metric3 = metric;
      }
    }
  }

  return {
    branding: state?.branding,
    list: _.uniqBy(
      state?.facility?.metrics?.filter((item) => item?.facility === facilityID),
      'id'
    ),
    metrics: state?.facility?.metrics?.filter((item) => item?.facility === facilityID),
    facMetrics,
    currentOrganisation: state.currentOrganisation,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  addFacilityMetric: () => dispatch(Actions.addFacilityMetric(ownProps?.id)),
  getFacilityMetrics: () => dispatch(Actions.getFacilityMetrics()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilityMetrics);
