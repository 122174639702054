import React from 'react';
import { connect } from 'react-redux';
import { Modal } from '@mui/material';
import _ from 'lodash';
import { AddButton } from '../../../styledComponents/GlobalStyle';

const propsToRemove = [
  'id',
  'pandaDocId',
  'pandaDocLinkId',
  'active',
  'status',
  'created',
  'createdBy',
  'expectedStartDate',
  'expectedDeliveryDate',
  'modified',
  'modifiedBy',
];

const ExistingContractModal = ({ setShowModal, showModal, setEditedState, adminOrgs }) => {
  const { showModal: show, existingContract } = showModal || {};

  const handleClose = () => {
    setShowModal(() => ({ showModal: false, existingContract: null }));
  };

  return (
    <div>
      <Modal open={show} onClose={handleClose}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button onClick={handleClose} className='close'>
                x
              </button>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4 className='modal-title'>Contract</h4>
              </div>
            </div>
            <div className='modal-body' style={{ overflow: 'auto' }}>
              <div style={{ maxHeight: '75vh' }}>
                {existingContract ? (
                  <>
                    <p>
                      We have found an active contract for this organisation. Would you like to load the contract in the form
                      or would you like to start from fresh?
                    </p>
                    <div style={{ marginTop: 20 }}>
                      <AddButton
                        style={{ marginRight: 20 }}
                        onClick={() => {
                          setShowModal({ showModal: false, existingContract: false });
                          const updatedObject = Object.keys(existingContract).reduce((acc, key) => {
                            if (!propsToRemove.includes(key)) {
                              acc[key] = existingContract[key];
                            }
                            return acc;
                          }, {});
                          setEditedState((prevState) => ({
                            ...prevState,
                            ...updatedObject,
                          }));
                        }}>
                        Load Contract
                      </AddButton>
                      <AddButton
                        onClick={() => {
                          setShowModal({ showModal: false, existingContract: false });
                          const orgDetails = _.find(adminOrgs, { id: existingContract.organisation });
                          setEditedState((prevState) => ({
                            ...prevState,
                            customerOrganisationName: orgDetails?.name,
                            organisation: orgDetails?.id,
                          }));
                        }}>
                        Start New
                      </AddButton>
                    </div>
                  </>
                ) : (
                  <p>
                    No active contracts have been detected in the system. Please close the popup and input the details in the
                    boxes below.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    adminOrgs: state.admin?.organisations,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ExistingContractModal);
