import { Tooltip } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Select from 'react-select';
import * as yup from 'yup';
import Breadcrumb from '../components/Common/Breadcrumb';
import DeleteFacilityModal from '../components/Facilities/DeleteFacilityModal';
import FacilityMetrics from '../components/Facilities/FacilityMetrics';
import Modal from '../components/Facilities/Modal';
import { capitalizeFirstLetter, itemToOptions } from '../components/utils/GlobalFunctions';
import { flexBetweenCenter, selectStyles } from '../components/utils/ReactSelectStyles';
import { Actions as FACILITYACTIONS } from '../store/actions/facilityActions';

const Facility = ({
  facilities,
  saveFacility,
  showFacilityModal,
  facilityDataModal,
  facilityDataDeleteModal,
  branding,
  facilityOptions,
  selectedOptions,
}) => {
  const { id } = useParams();
  const { primaryColor, secondaryColor } = branding.colors;
  const [editing, setEditing] = useState(id === 'new');
  const [editedState, setEditedState] = useState(null);
  const [validationError, setValidationError] = useState({});
  const { headers } = branding.fonts?.[0].font;
  const history = useHistory();
  const facility = facilities?.find((item) => item.id === id);
  const checkingForPrimaryFacility = facilities?.some((facility) => facility?.isPrimary);

  let selectedItem = {};
  selectedOptions &&
    Object.keys(selectedOptions).map(
      (x) =>
        (selectedItem[x] =
          selectedOptions[x] &&
          selectedOptions[x].find((item) => {
            return editedState && editedState?.[x] && item.value === editedState?.[x];
          }))
    );

  const addFacilityValidation = yup.object().shape({
    name: yup.string().required('Company name is required'),
    facilityTypeId: yup.string().required('Facility Type Id is required'),
    isPrimary: yup.string().required('Last name is required'),
    country: yup.string().required('Country is required'),
    postCode: yup.string().required('Post Code is required'),
  });

  const onConfirm = () => {
    const primaryFacility = facilities.find((item) => item.isPrimary);
    saveFacility({ ...primaryFacility, isPrimary: false });
    saveFacility({ ...facility, ...editedState }, history);
  };

  useEffect(() => {
    if (id === 'new') {
      setEditing(true);
      setEditedState({
        name: '',
        postCode: '',
        externalId: '',
        facilityTypeId: null,
        isPrimary: false,
        country: null,
        excludeFromReports: false,
      });
    }
  }, [id]);

  const updateField = (e) => {
    const newEditedState = { ...editedState };
    newEditedState[e.target.name] = e.target.value;
    setEditedState(newEditedState);
  };

  if (!facility && id !== 'new') {
    return (
      <div className='row' style={{ fontFamily: headers }}>
        <div className='col-lg-12'>
          <section className='panel'>
            <header className='panel-heading'>Facility not Found</header>
          </section>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Breadcrumb
        items={[
          { label: 'Facilities', link: '/facilities' },
          {
            label: facility ? facility.name : 'New',
            link: `/facilities/${facility ? facility.id : 'new'}`,
          },
        ]}
      />

      <div className='row' style={{ fontFamily: headers }}>
        <div className='col-lg-12'>
          <section className='panel'>
            <aside className='profile-nav alt'>
              <section className='panel' id='facilityDetails'>
                <header className='panel-heading' id='facilitiesHeading'>
                  Your facilities - {facility?.name || editedState?.name}
                  {editing ? (
                    <i
                      style={{ cursor: 'pointer', float: 'right', fontSize: 25 }}
                      className='far fa-save fa-lg'
                      id='save-icon'
                      onClick={async () => {
                        await addFacilityValidation
                          .validate(
                            {
                              name: editedState['name'],
                              facilityTypeId: editedState['facilityTypeId'],
                              isPrimary: editedState['isPrimary'],
                              country: editedState['country'],
                              postCode: editedState['postCode'],
                            },
                            { abortEarly: false }
                          )
                          .then(async () => {
                            try {
                              if (checkingForPrimaryFacility && editedState?.['isPrimary'] && !facility?.isPrimary)
                                showFacilityModal(true);
                              else {
                                await saveFacility({ ...facility, ...editedState }, history);
                                setEditing(false);
                                if (validationError) setValidationError({}); // Reset the State
                              }
                            } catch (error) {
                              console.log('error', error);
                            }
                          })
                          .catch((error) => {
                            setValidationError(error.inner);
                          });
                      }}
                    />
                  ) : (
                    <i
                      style={{ cursor: 'pointer', float: 'right', fontSize: 25 }}
                      onClick={() => {
                        setEditing(true);
                        setEditedState({
                          name: facility.name,
                          postCode: facility.postCode,
                          externalId: facility.externalId,
                          facilityTypeId: facility.facilityTypeId,
                          isPrimary: facility.isPrimary,
                          country: facility.country,
                          excludeFromReports: facility.excludeFromReports,
                        });
                      }}
                      id='edit-facility'
                      className='fa fa-pencil fa-lg'
                    />
                  )}
                </header>
                <ul id='facility-table' className='nav nav-pills nav-stacked'>
                  {Object.keys(facilityOptions).map((key) => {
                    const { type, name, placeholder } = facilityOptions[key] || {};
                    const { show: showTooltip, content } = facilityOptions[key]?.tooltip || {};
                    let value;

                    // Handle Logic to show values on the UI when not on editing mode
                    if (!editing) {
                      if (type === 'select') {
                        value = _.find(selectedOptions?.[key], { value: facility?.[key] })?.label || 'N/A';
                      } else if (type === 'text') {
                        value = capitalizeFirstLetter(facility?.[key]) || 'N/A';
                      }
                    }
                    return (
                      <li className={key} key={key}>
                        <div
                          style={{
                            ...flexBetweenCenter,
                            border: _.find(validationError, { path: key })?.message ? '3px solid red' : 'none',
                            padding: '10px 15px',
                          }}>
                          <div style={{ minWidth: 150, display: 'flex', alignItems: 'center' }}>
                            <i className={facilityOptions[key].icon} style={{ paddingRight: 20 }} />
                            {facilityOptions[key].label}
                            {showTooltip && (
                              <Tooltip
                                title={<span style={{ fontSize: 12 }}>{content}</span>}
                                placement='right'
                                componentsProps={{ tooltip: { sx: { bgcolor: secondaryColor } } }}>
                                <i
                                  className='fa fa-question-circle'
                                  style={{ fontSize: 18, color: primaryColor, marginLeft: 10 }}
                                />
                              </Tooltip>
                            )}
                          </div>
                          {editing ? (
                            <>
                              {type === 'text' && (
                                <input
                                  type='text'
                                  name={name}
                                  placeholder={placeholder}
                                  value={editedState?.[name] || ''}
                                  onChange={updateField}
                                  className='form_input'
                                />
                              )}
                              {type === 'select' && (
                                <Select
                                  className={key}
                                  value={selectedItem?.[key] || value}
                                  options={facilityOptions?.[key].options}
                                  styles={selectStyles}
                                  isClearable
                                  menuPlacement={'auto'}
                                  onChange={(selected) => {
                                    const newEditedState = { ...editedState };
                                    newEditedState[key] = selected ? selected?.value : null;
                                    setEditedState(newEditedState);
                                  }}
                                />
                              )}
                            </>
                          ) : (
                            <span style={{ float: 'right' }}>{value}</span>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </section>
            </aside>
          </section>

          {facility?.id && <FacilityMetrics id={id} />}
        </div>
      </div>

      {facilityDataModal && (
        <Modal facility={{ ...facility, ...editedState }} onConfirm={onConfirm} setEditing={setEditing} />
      )}
      {facilityDataDeleteModal?.status && <DeleteFacilityModal />}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const facilityTypeOptions = _.sortBy(state?.facility?.types?.map(itemToOptions), ['label']);
  const countryOptions = _.sortBy(state.organisation?.countries?.map(itemToOptions), ['label']);
  const primaryFacilityOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const excludeFromReportsOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const selectedOptions = {
    facilityTypeId: facilityTypeOptions,
    country: countryOptions,
    isPrimary: primaryFacilityOptions,
    excludeFromReports: excludeFromReportsOptions,
  };

  const facilityOptions = {};
  facilityOptions.name = {
    label: 'Facility Name',
    name: 'name',
    type: 'text',
    icon: 'fa fa-cogs',
    placeholder: '',
  };
  facilityOptions.facilityTypeId = {
    label: 'Facility Type',
    name: 'facilityTypeId',
    type: 'select',
    icon: 'fa-regular fa-building',
    placeholder: 'Facility Type',
    options: facilityTypeOptions,
  };
  facilityOptions.country = {
    label: 'Country',
    name: 'country',
    type: 'select',
    icon: 'fa-solid fa-globe',
    placeholder: 'Country',
    options: countryOptions,
  };
  facilityOptions.postCode = {
    label: 'Post Code',
    name: 'postCode',
    type: 'text',
    icon: 'fa-solid fa-signs-post',
    placeholder: '',
  };
  facilityOptions.externalId = {
    label: 'External ID',
    name: 'externalId',
    type: 'text',
    icon: 'fa fa-cogs',
    placeholder: '',
  };
  facilityOptions.isPrimary = {
    label: 'Primary Facility',
    name: 'isPrimary',
    type: 'select',
    icon: 'fa-solid fa-house',
    placeholder: 'Primary Facility',
    options: primaryFacilityOptions,
  };
  facilityOptions.excludeFromReports = {
    label: 'Exclude from Organisation Footprint',
    name: 'excludeFromReports',
    type: 'select',
    icon: 'fa-solid fa-filter-circle-xmark',
    tooltip: {
      show: true,
      content: `Change to 'exclude' if this facility is not part of the company's consumption data and 
      should not be included in the company carbon footprint, 
      for example if this facility and data is a what-if scenario.`,
    },
    placeholder: 'Exclude from Organisation Footprint',
    options: excludeFromReportsOptions,
  };

  return {
    facilities: state?.facility?.list,
    facilityDataModal: state.facilityDataModal,
    facilityDataDeleteModal: state.facility && state.facility.facilityDataDeleteModal,
    branding: state.branding,
    facilityOptions,
    selectedOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveFacility: (facility, history) => dispatch(FACILITYACTIONS.saveFacility(facility, history)),
  showFacilityModal: (status) => dispatch(FACILITYACTIONS.showFacilityModal(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Facility);
