import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Actions as ORGANISATIONACTIONS } from '../../store/actions/organisationActions';
import { Actions as AUTHENTICATION_ACTIONS } from '../../store/actions/authenticationActions';
import { Actions as PAYMENTACTIONS } from '../../store/actions/paymentActions';

const SubscriptionSuccessful = ({ branding, loading, processing, sendUserOrderForm }) => {
  const { primaryColor } = branding.colors;

  const history = useHistory();

  const sendOrderForm = async (orgId) => {
    try {
      loading(true);
      await sendUserOrderForm(orgId);
      history.push('/');
      window.location.reload();
    } catch {
      loading(false);
    }
  };

  const orgId = localStorage.getItem('Organisation');

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.8)' }}>
      <div className='modal-dialog' style={{ height: '90vh', overflow: 'auto', fontSize: '12px', margin: '100px auto' }}>
        <div className='modal-content'>
          <div className='modal-header'>
            <h4 className='modal-title'>Congratulations!</h4>
          </div>

          <div className='modal-body' style={{ textAlign: 'center', marginTop: '1em' }}>
            <h4>Your subscription is confirmed!</h4>

            <div className='row block buttons' style={{ marginTop: '2em', display: 'flex', justifyContent: 'center' }}>
              <button
                disabled={processing ? true : false}
                className='btn btn-lg btn-login btn-block succesful-subscription'
                onClick={async () => await sendOrderForm(orgId)}
                style={{ width: processing ? '45%' : '40%', backgroundColor: primaryColor, color: 'white' }}>
                {processing && <i className='fa fa-spin fa-spinner icon-spinner' />}
                Take me to my account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    bacsPayment: window.location.pathname.includes('success'),
    branding: state.branding,
    processing: state.authentication.processing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loading: (saving) => dispatch(AUTHENTICATION_ACTIONS.loggingIn(saving)),
  toggleModal: (status, item) => dispatch(ORGANISATIONACTIONS.toggleModal(status, item)),
  showSubscriptionModal: (status) => dispatch(PAYMENTACTIONS.showSubscriptionModal(status)),
  sendUserOrderForm: (orgId) => dispatch(PAYMENTACTIONS.sendUserOrderForm(orgId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSuccessful);
