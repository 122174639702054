import React from 'react';
import { connect } from 'react-redux';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { AddButton } from '../../styledComponents/GlobalStyle';

const DeleteUsageDataModal = ({ usageDataDeleteModal, showUsageDataDeleteModal, deleteUsageData, branding }) => {
  if (!usageDataDeleteModal?.status) return null;
  const { dashboardSecondIcon, cancelBtn } = branding.colors;
  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <button onClick={() => showUsageDataDeleteModal(false, null)} className='close'>
              x
            </button>
            <h4 className='modal-title'>Are you sure you wish to delete this usage data?</h4>
          </div>
          <div className='modal-body'>
            <p>This action cannot be undone.</p>
            <div>
              <AddButton
                onClick={() => deleteUsageData([usageDataDeleteModal.item])}
                className='btn btn-danger'
                style={{ marginRight: '10px' }}>
                Yes, please delete this data
              </AddButton>
              <AddButton
                id='cancel-usage-deletion'
                style={{ backgroundColor: cancelBtn || dashboardSecondIcon }}
                onClick={() => showUsageDataDeleteModal(false, null)}
                className='btn btn-success'>
                Cancel (do not delete)
              </AddButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    usageDataDeleteModal: state.usageDataDeleteModal,
    branding: state.branding,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteUsageData: (items) => dispatch(USAGEACTIONS.deleteUsageData(items)),
  showUsageDataDeleteModal: (status, item) => dispatch(USAGEACTIONS.showUsageDataDeleteModal(status, item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUsageDataModal);
