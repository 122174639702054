const createUnsignedRequest = async (method, endpoint, data = null, headers) => {
  // For Local Testing
  if (process.env.REACT_APP_LOCAL_BACK_TEST) {
    const splitEndpoint = endpoint.split(':3001')[1];
    if (['/payment'].some((x) => endpoint.includes(x))) {
      // Run Payment Lambda Local
      endpoint = `http://localhost:3002${splitEndpoint}`;
    } else if (['/registration', '/authentication'].some((x) => endpoint.includes(x))) {
      // Run Auth Lambda Local
      endpoint = `http://localhost:3003${splitEndpoint}`;
    } else {
      // Run xeroAPI Lambda Local
      endpoint = `http://localhost:3001${splitEndpoint}`;
    }
  }
  const unsignedRequest = await fetch(endpoint, {
    method: method,
    body: data,
    headers: { Accept: 'application/json', 'Content-Type': 'application/json', ...headers },
  });

  return unsignedRequest;
};

export default createUnsignedRequest;
