import React, { useState } from 'react';
import { connect } from 'react-redux';
import { format, addYears, addDays } from 'date-fns';
import Select from 'react-select';
import { Actions } from '../../store/actions/facilityActions';
import NumberFormat from 'react-number-format';

const FacilityMetricRow = ({
  metric,
  reportingPeriods,
  facMetrics,
  saveFacilityMetric,
  showFacilityMetricDeleteModal,
  list,
}) => {
  //Filtering the ReportingPeriodsOptions based on the facility metrics list
  const facilityMetricDates = list.map((date) => date.startDate);
  const filteredReportingPeriods = reportingPeriods.filter((report) => !facilityMetricDates.includes(report.startDate));
  const [editing, setEditing] = useState(metric.id === metric.uuid);
  const [editedState, setEditedState] = useState(null);

  const updateField = (e) => {
    const newEditedState = { ...editedState };
    newEditedState[e.target.name] = e.target.value.replace(/,(?=\d{3})/g, '');
    setEditedState(newEditedState);
  };

  if (editing) {
    const selectedPeriod = reportingPeriods.find((period) => {
      if (editedState && editedState.startDate) {
        return metric.startDate === editedState.startDate;
      } else if (metric.startDate) {
        return metric.startDate === period.startDate;
      }
      return null;
    });

    return (
      <tr id='saveFacilityMetric-Row'>
        <td style={{ textAlign: 'left' }}>
          <Select
            value={selectedPeriod}
            options={filteredReportingPeriods}
            onChange={(selected) => {
              const newEditedState = { ...editedState };

              newEditedState['year'] = selected.value;
              newEditedState['startDate'] = selected.startDate;
              newEditedState['endDate'] = selected.endDate;

              setEditedState(newEditedState);
            }}
          />
        </td>
        {facMetrics?.metric1 && (
          <td>
            <NumberFormat
              thousandSeparator={true}
              className='form-control'
              inputMode='numeric'
              onChange={updateField}
              defaultValue={metric.metric1}
              name='metric1'
            />
          </td>
        )}
        {facMetrics?.metric2 && (
          <td>
            <NumberFormat
              thousandSeparator={true}
              className='form-control'
              inputMode='numeric'
              onChange={updateField}
              defaultValue={metric.metric2}
              name='metric2'
            />
          </td>
        )}
        {facMetrics?.metric3 && (
          <td>
            <NumberFormat
              thousandSeparator={true}
              className='form-control'
              inputMode='numeric'
              onChange={updateField}
              defaultValue={metric.metric3}
              name='metric3'
            />
          </td>
        )}

        <td>
          <i
            id='save-fac-metric'
            className='fa fa-save fa-lg'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              saveFacilityMetric({ ...metric, ...editedState, status: 'Active' });
              setEditedState(null);
              setEditing(false);
            }}
          />
          {metric.id !== metric.uuid && (
            <i
              id='cancel-save-fac-metric'
              className='fa fa-times fa-lg'
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={() => {
                setEditedState(null);
                setEditing(false);
              }}
            />
          )}
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td style={{ textAlign: 'left' }}>
        {metric?.startDate && format(new Date(metric?.startDate), 'do MMM yyyy')} -{' '}
        {metric?.endDate && format(new Date(metric?.endDate), 'do MMM yyyy')}
      </td>
      {facMetrics.metric1 && (
        <td>{metric && metric.metric1 ? metric.metric1.replace(/(?<!\.\d*)(?<=\d)(?=(?:\d{3})+(?!\d))/g, ',') : 'N/A'}</td>
      )}
      {facMetrics.metric2 && (
        <td>{metric && metric.metric2 ? metric.metric2.replace(/(?<!\.\d*)(?<=\d)(?=(?:\d{3})+(?!\d))/g, ',') : 'N/A'}</td>
      )}
      {facMetrics.metric3 && (
        <td>{metric && metric.metric3 ? metric.metric3.replace(/(?<!\.\d*)(?<=\d)(?=(?:\d{3})+(?!\d))/g, ',') : 'N/A'}</td>
      )}
      <td>
        <i
          id='edit-fac-metric'
          style={{ cursor: 'pointer' }}
          onClick={() => setEditing(true)}
          className='fa fa-pencil fa-lg'
        />
        <i
          style={{ cursor: 'pointer', marginLeft: 10 }}
          onClick={() => showFacilityMetricDeleteModal(true, metric)}
          className='fa fa-trash-o fa-lg'
          id='delete-fac-metric'
        />
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => {
  const organisation =
    state.organisation.details || state.organisation.list.find((item) => item.id === state.currentOrganisation);
  const thisYear = format(new Date(), 'yyyy');
  const reportingYearEndMonth = organisation?.reportingMonth || organisation?.reportingYearEndMonth; // property name changes depending on Symph or Dynamo
  const reportingYearEndDay = organisation?.reportingDay || organisation?.reportingYearEndDay; // property name changes depending on Symph or Dynamo

  const lastYear =
    format(new Date(), 'MM-dd') >= `${reportingYearEndMonth}-${reportingYearEndDay}` ? +thisYear + 1 : thisYear;

  const reportingPeriods = [];

  if (organisation && reportingYearEndMonth && reportingYearEndDay) {
    for (var i = lastYear; i >= 2014; i--) {
      const endDate = new Date(`${i}-${reportingYearEndMonth}-${reportingYearEndDay}`);
      const startDate = addDays(addYears(endDate, -1), 1);

      reportingPeriods.push({
        year: i,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),

        label: `${format(startDate, 'do MMM yyyy')} - ${format(endDate, 'do MMM yyyy')}`,
        value: i,
      });
    }
  }

  return {
    reportingPeriods,
    facilityModal: state?.facility?.facilityDataDeleteModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveFacilityMetric: (facilityMetric) => dispatch(Actions.saveFacilityMetric(facilityMetric)),
  showFacilityMetricDeleteModal: (status, item) => dispatch(Actions.showFacilityMetricDeleteModal(status, item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilityMetricRow);
