const googleAPIKeyTooltipContent = (wlSettings) => {
  const { supportEmail } = wlSettings || {};
  return (
    <>
      <p>
        This field is for you to add your API key in order to upload data by importing 'freight without distance' template.
        You would need to first set up a Google account to enable calls to DistanceMatric API. Then, an API 'key' will be
        provided to you to start importing freight. You will be billed directly by Google for setting up API keys.
      </p>
      <p>
        Should you need any assistance in setting up an account and obtain a 'key' to start importing freight without
        distance data, please do not hesitate to contact us at{' '}
        <a style={{ color: 'white' }} href={`mailto:${supportEmail}`}>
          {' '}
          {supportEmail}{' '}
        </a>
        .
      </p>
    </>
  );
};

const bulkAddOptionsToolTipContent = (wlSettings) => {
  const { supportEmail } = wlSettings || {};
  return (
    <>
      <p>Please note, by clicking Bulk Add, you will create a CYF tracking option for all listed spend entities.</p>
      <p>
        For example under the Advertising Spend Entity, an "Advertising CYF" Tracking Option will be created and so on...
      </p>
      <p>
        Should you have more questions, please do not hesitate to contact us at{' '}
        <a style={{ color: 'white' }} href={`mailto:${supportEmail}`}>
          {' '}
          {supportEmail}{' '}
        </a>
        .
      </p>
    </>
  );
};

const orgIndustryTooltipContent = (wlSettings) => {
  const { supportEmail } = wlSettings || {};
  return (
    <>
      <p>
        Our industries are gathered from the International Standard Industrial Classification of All Economic Activities
        (ISIC).
      </p>
      <p>
        To find a relevant industry, search using key words (e.g. construction) and scroll down the options. If your industry
        doesn’t appear to be listed and/or you are unsure of what industry to select for your organisation, we can help you
        to identify one. Email us:{' '}
        <a style={{ color: 'white' }} href={`mailto:${supportEmail}`}>
          {' '}
          {supportEmail}{' '}
        </a>
        .
      </p>
    </>
  );
};

const reportingYearEndIssueTooltipContent = () => {
  return (
    <span>
      Changes to reporting year end are disabled once minimum organisation criteria have been saved. Please contact support
      if you wish to change your reporting year.
    </span>
  );
};

const excludeFromReportsTooltipContent = () => {
  return (
    <span>
      Change to 'exclude' if this facility is not part of the company's consumption data and should not be included in the company carbon footprint, for example if this facility and data is a what-if scenario.
    </span>
  );
};

export {
  googleAPIKeyTooltipContent,
  reportingYearEndIssueTooltipContent,
  orgIndustryTooltipContent,
  bulkAddOptionsToolTipContent,
  excludeFromReportsTooltipContent
};
