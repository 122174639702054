import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Actions as HELPACTIONS } from '../../store/actions/helpActions';
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

const HelpSidebar = ({ show, fetchHelp, helpItems }) => {
  const [currentHelp, setCurrentHelp] = useState({ content: '<h1>No Help Available</h1>' });

  let location = useLocation();

  useEffect(() => {
    fetchHelp();
  }, [fetchHelp]);

  React.useEffect(() => {
    if (helpItems) {
      switch (location.pathname) {
        case '/':
          setCurrentHelp(helpItems?.find((i) => i.handle === 'home'));
          break;
        default:
          const firstSlash = location.pathname.substr(1).indexOf('/');
          const level1Path = location.pathname.substr(1, firstSlash === -1 ? location.pathname.length : firstSlash);
          if (level1Path === 'report') {
            setCurrentHelp(helpItems?.find((i) => i.handle === 'report-html'));
          } else {
            const helpItem = helpItems?.find((i) => i.handle === level1Path);
            if (helpItem) {
              setCurrentHelp(helpItem);
            }
          }
      }
    }
  }, [location, helpItems]);

  const sidebarClass = `right-sidebar ${show && `open-right-bar`}`;

  return (
    <div className={sidebarClass}>
      <ReactMarkdown remarkPlugins={[gfm]} children={currentHelp.content} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    show: state.help?.show,
    helpItems: state.help?.list,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchHelp: () => dispatch(HELPACTIONS.fetchHelp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpSidebar);
