import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

const formatNumber = (value) => parseFloat(value || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 });

const EmissionsBySourceRow = ({ entity, subentity, option, usageDetail, facility, report }) => {
  if (!report) return null;

  const year = report.year;

  const hasMarketFactors = option[year] && (option[year].co2e_market || option[year].market_mix);

  return [
    <tr
      key={crypto.randomUUID()}
      className={`table-values ${facility ? `facility-values toggle-facility-${facility.id}` : ''} ${
        hasMarketFactors ? `toggle-location` : ''
      } ${`title-subrow-${entity?.name?.split(' ').join('').replace(':', '-')}`}`}
      style={{ background: 'rgba(0,0,0,0.0)' }}>
      <td style={{ textAlign: 'left' }}>
        {subentity.name} <span className='usage-detail'>{usageDetail}</span>
      </td>
      <td>{formatNumber(option[year] ? _.sum(Object.values(option[year].scope)) : 0)}</td>
      <td>{formatNumber(option[year] ? option[year].scope[1] : 0)}</td>
      <td>{formatNumber(option[year] ? option[year].scope[2] : 0)}</td>
      <td>{formatNumber(option[year] ? option[year].scope[3] : 0)}</td>
    </tr>,

    hasMarketFactors ? (
      <tr
        key={crypto.randomUUID()}
        className={`table-values ${facility ? `facility-values toggle-facility-${facility.id}` : ''} toggle-market`}
        style={{ background: 'rgba(0,0,0,0.0)' }}>
        <td style={{ textAlign: 'left' }}>
          {subentity.name} <span className='usage-detail'>{usageDetail}</span>
        </td>
        <td>
          {formatNumber(option[year] && option[year].scopeMarket ? _.sum(Object.values(option[year].scopeMarket)) : 0)}
        </td>
        <td>{formatNumber(option[year] && option[year].scopeMarket ? option[year].scopeMarket[1] : 0)}</td>
        <td>{formatNumber(option[year] && option[year].scopeMarket ? option[year].scopeMarket[2] : 0)}</td>
        <td>{formatNumber(option[year] && option[year].scopeMarket ? option[year].scopeMarket[3] : 0)}</td>
      </tr>
    ) : null,
  ];
};

const mapStateToProps = (state) => {
  const { years } = state.consumption;

  const { organisation, metric, report } = state;

  return {
    years: years || [],
    organisationDetails: organisation?.details,
    metricValues: organisation?.metrics ? organisation?.metrics : null,
    metrics: metric?.list,
    report: report?.details,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsBySourceRow);
