import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Actions as PROFILEACTIONS } from '../store/actions/profileActions';
import { Actions as ADMINACTIONS } from '../store/actions/adminActions';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { AddButton } from '../styledComponents/GlobalStyle';
import Breadcrumb from '../components/Common/Breadcrumb';
import { FormGroup, FormControlLabel, IconButton, Popover, Typography, Switch } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import PromoterAgreement from '../components/Modals/PromoterAgreement';
import ListPaymentMethods from '../components/Payments/ListPaymentMethods';

const Profile = ({
  branding,
  orgName,
  stripeAccountId,
  connectStripeAccount,
  profileDetails,
  saving,
  showToggleBtn,
  referralCode,
  registrationLink,
  showStripeConnectBtn,
  showPaymentMethods,
  showBillingPageBtn,
}) => {
  const { register, handleSubmit } = useForm();
  const history = useHistory();

  const [showPromoterAgreement, setShowPromoterAgreement] = useState(false);
  const [popover, setPopover] = useState('');
  const [loading, setLoading] = useState(false);

  const { headers } = branding.fonts?.[0].font || {};
  const { primaryButton, primaryColor } = branding.colors || {};
  const { firstName, lastName, email } = profileDetails;

  const onSubmit = async (data) => {
    if (saving) return;

    /* const item = {
      firstName: data.firstName,
      lastName: data.lastName,
      company: data.company,
      email: data.email,
    }; */

    //saveProfile(cleanDeep(item))
  };

  const open = Boolean(popover);
  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
      <Breadcrumb items={[{ label: 'Profile', link: '/profile' }]} />

      <div className='row' style={{ fontFamily: headers }}>
        <div className='col-lg-12'>
          <section className='panel'>
            <header className='panel-heading'>Profile</header>
            {showBillingPageBtn && (
              // Billing Page is visible for CYF Admins, WL admins, and Consultant Admins
              <AddButton
                className='btn btn-success'
                style={{ position: 'absolute', top: 10, right: 20 }}
                onClick={() => history.push('/billing')}>
                <i className='fa fa-credit-card' style={{ marginRight: 5 }} />
                Go to Billing Page
              </AddButton>
            )}
            <div className='panel-body'>
              <div className='position-center'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='form-group'>
                    <label>
                      First name <em>*</em>
                    </label>
                    <input
                      id='firstName'
                      name='firstName'
                      type='text'
                      className='form-control'
                      disabled
                      defaultValue={firstName}
                      ref={register({ required: true })}
                    />
                  </div>
                  <div className='form-group'>
                    <label>
                      Last name <em>*</em>
                    </label>
                    <input
                      id='lastName'
                      name='lastName'
                      type='text'
                      className='form-control'
                      defaultValue={lastName}
                      disabled
                      ref={register({ required: true })}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Organisation</label>
                    <input
                      id='company'
                      name='company'
                      type='text'
                      className='form-control'
                      defaultValue={orgName}
                      disabled
                      ref={register({ required: true })}
                    />
                  </div>

                  <div className='form-group'>
                    <label>
                      Email address<em>*</em>
                    </label>
                    <input type='text' disabled='disabled' className='form-control' required='required' value={email} />
                  </div>

                  <div className='form-group' style={{ display: showToggleBtn ? 'block' : 'none' }}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch checked={showPromoterAgreement} />}
                        label='Show Promoter Agreement'
                        onChange={(e) => setShowPromoterAgreement(!showPromoterAgreement)}
                      />
                    </FormGroup>
                  </div>
                  <div className='form-group' style={{ display: referralCode ? 'block' : 'none' }}>
                    <label>
                      Registration Link<em>*</em>
                    </label>
                    <div style={{ display: '-webkit-box' }}>
                      <input
                        type='text'
                        disabled='disabled'
                        className='form-control'
                        required='required'
                        value={registrationLink}
                      />
                      <IconButton
                        sx={{ position: 'relative', right: 35 }}
                        onClick={(e) => {
                          navigator.clipboard.writeText(registrationLink);
                          setPopover(e.currentTarget);
                          setTimeout(() => setPopover(false), 1000);
                        }}>
                        <ContentCopy />
                      </IconButton>
                      <Popover id={id} open={open} anchorEl={popover} anchorOrigin={{ vertical: -10, horizontal: 40 }}>
                        <Typography sx={{ p: 2, background: primaryColor, color: 'white', fontWeight: 'bold' }}>
                          Copied!
                        </Typography>
                      </Popover>
                    </div>
                  </div>
                  <div className='form-group' style={{ display: showStripeConnectBtn ? 'block' : 'none' }}>
                    <div style={{ display: '-webkit-box' }}>
                      <button
                        className='btn btn-success'
                        onClick={async () => {
                          setLoading(true);
                          await connectStripeAccount();
                          setLoading(false);
                        }}
                        style={{ backgroundColor: primaryButton, border: 'none' }}
                        disabled={stripeAccountId || loading ? true : false}>
                        {loading && <i className='fa fa-spin fa-spinner' style={{ marginRight: 10 }} />}
                        {stripeAccountId ? 'Account connected to stripe' : 'Connect Stripe Account'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
      {showPromoterAgreement && <PromoterAgreement setShowPromoterAgreement={setShowPromoterAgreement} />}
      {showPaymentMethods && <ListPaymentMethods />}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const isLocalDevelopment = state.isLocalDevelopment;
  const { details: org, list } = state.organisation || {};
  const { liveDomain, hasPaymentSystem, enableConsultants, stripeAccountId } = state.whiteLabelAdmin || {};
  const { cyfAdmin, whiteLabelAdmin, consultantAdmin, ambassadorAdmin, ...profileDetails } = state.profile?.details;
  const { stripeCustomerId, stripeSubscriptions } = state.payment.stripe;
  // Show Payment Methods on Profile page for direct users and white label clients with payment system
  const showPaymentMethods = cyfAdmin || (!whiteLabelAdmin && !consultantAdmin && hasPaymentSystem);
  const adminUsers = cyfAdmin || whiteLabelAdmin || consultantAdmin || ambassadorAdmin;
  const consultantClients = list?.filter((x) => x.consultantId !== 'NONE');
  const directUsersWithPaymentSystem = !adminUsers && hasPaymentSystem && !consultantClients?.length;
  // Billing Page should be shown to all admins, and to direct users with payment system
  const showBillingPageBtn =
    adminUsers || (directUsersWithPaymentSystem && stripeSubscriptions?.length > 0 && stripeCustomerId);

  // Just for CYF and WLs w/ payment system, and it's shown just for admins
  const showStripeConnectBtn = hasPaymentSystem && (whiteLabelAdmin || cyfAdmin);

  const referralCode = state.promoter?.referralCode;
  // Shows Just for Integra and its clients
  const showToggleBtn = enableConsultants && org?.consultantId === 'NONE' && !referralCode;

  const registrationLink = isLocalDevelopment
    ? `https://localhost:3000/register?referralCode=${referralCode}`
    : `https://${liveDomain}/register?referralCode=${referralCode}`;

  return {
    adminPortalModal: state.admin?.modal?.status,
    branding: state.branding,
    currentOrganisation: state.currentOrganisation,
    cyfAdmin,
    orgName: org?.name,
    profileDetails,
    registrationLink,
    saving: state.profile?.saving,
    referralCode,
    showToggleBtn,
    stripeAccountId,
    whiteLabelAdmin,
    showStripeConnectBtn,
    showPaymentMethods,
    showBillingPageBtn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setDomainEmail: (domain) => dispatch(PROFILEACTIONS.setDomainEmail(domain)),
  setWhiteLabelAppDomain: (domain) => dispatch(PROFILEACTIONS.setWhiteLabelAppDomain(domain)),
  connectStripeAccount: () => dispatch(PROFILEACTIONS.connectStripeAccount()),
  getStepFunctionStatus: (importArn) => dispatch(PROFILEACTIONS.getStepFunctionStatus(importArn)),
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
