import React from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { parseDateToTz } from '../utils/GlobalFunctions';

import { RowBtn } from '../../styledComponents/GlobalStyle';

const ReportRow = ({ reportDetails, currentOrganisation }) => {
  const { startDate, endDate } = reportDetails || null;
  return (
    <tr>
      <td width='20%' style={{ textAlign: 'left' }}>
        {reportDetails.name}
      </td>
      <td width='20%'>
        {startDate && endDate
          ? `${format(parseDateToTz(startDate, 'yyyy-MM-dd'), 'dd/MM/yyyy')} -
            ${format(parseDateToTz(endDate, 'yyyy-MM-dd'), 'dd/MM/yyyy')}`
          : 'N/A'}
      </td>
      <td width='20%'>{reportDetails.status}</td>
      <td width='20%'>N/A</td>
      <td>
        <RowBtn to={`/report/${currentOrganisation}/${reportDetails.id}`}>
          <i className='fa fa-search' />
        </RowBtn>
      </td>
    </tr>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    msg: 'Nothing',
    currentOrganisation: state.currentOrganisation,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ReportRow);
