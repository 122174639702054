import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

const formatNumber = (value) => parseFloat(value || 0).toLocaleString(navigator.language, { minimumFractionDigits: 2 });

const EmissionsDisaggregatedByCustomCategoryRow = ({ customCategory, customCategoryData }) => {
  if (!customCategoryData) {
    return ``;
  }

  return (
    <tr>
      <td width='20%' style={{ textAlign: 'left' }}>
        {customCategory}
      </td>

      <td width='20%'>
        <span className='toggle-location'>
          {customCategoryData.co2e && customCategoryData.co2e ? customCategoryData.co2e.toFixed(3) : '0.00'}
        </span>
        <span className='toggle-market'>
          {formatNumber(
            customCategoryData && customCategoryData.marketScope ? _.sum(Object.values(customCategoryData.marketScope)) : 0
          )}
        </span>
      </td>

      <td width='20%'>
        <span className='toggle-location'>
          {customCategoryData.scope && customCategoryData.scope[1] ? customCategoryData.scope[1].toFixed(3) : '0.00'}
        </span>
        <span className='toggle-market'>
          {customCategoryData.marketScope && customCategoryData.marketScope[1]
            ? customCategoryData.marketScope[1].toFixed(3)
            : customCategoryData.scope[1]
            ? customCategoryData.scope[1].toFixed(3)
            : '0.00'}
        </span>
      </td>

      <td width='20%'>
        <span className='toggle-location'>
          {customCategoryData.scope && customCategoryData.scope[2] ? customCategoryData.scope[2].toFixed(3) : '0.00'}
        </span>
        <span className='toggle-market'>
          {customCategoryData.marketScope && customCategoryData.marketScope[2]
            ? customCategoryData.marketScope[2].toFixed(3)
            : '0.00'}
        </span>
      </td>

      <td width='20%'>
        <span className='toggle-location'>
          {customCategoryData.scope && customCategoryData.scope[3] ? customCategoryData.scope[3].toFixed(3) : '0.00'}
        </span>
        <span className='toggle-market'>
          {customCategoryData.marketScope && customCategoryData.marketScope[3]
            ? customCategoryData.marketScope[3].toFixed(3)
            : '0.00'}
        </span>
      </td>
    </tr>
  );
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsDisaggregatedByCustomCategoryRow);
