import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import EmissionsBySourceSubentity from './EmissionsBySourceSubentity';

import { CategoryTableTitle } from '../../styledComponents/GlobalStyle';

const EmissionsBySourceEntity = ({ entity, subentities }) => {
  return (
    <>
      <tr className={`title-row-${entity?.name?.split(' ').join('').replace(':', '-')}`} style={{ textAlign: 'left' }}>
        <CategoryTableTitle className='table-title' colSpan='9'>
          {entity.name}
        </CategoryTableTitle>
      </tr>

      {subentities?.map((subentity, index) => (
        <EmissionsBySourceSubentity key={index} entity={entity} subentity={subentity} />
      ))}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { data } = state.consumption;

  const { subentities, entities } = state;

  const { entityID } = ownProps;

  const entitySubentities = data?.[entityID] && Object.keys(data[entityID]);

  const entity = _.find(entities, { id: entityID });

  const filteredSub = subentities && subentities.filter(({ id }) => entitySubentities?.includes(String(id)));

  const neededSubentities = subentities && _.orderBy(filteredSub, ['name']);

  return {
    entity,
    subentities: neededSubentities,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EmissionsBySourceEntity);
