import React from 'react';
import { connect } from 'react-redux';
import RegisterPage from './RegisterPage';

const Register = ({ setShowPaymentModal, branding }) => {
  const { colors } = branding || {};

  if (colors) return <RegisterPage setShowPaymentModal={setShowPaymentModal} />;
  else return null;
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => {
  return {
    branding: state.branding,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
