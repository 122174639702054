import React from 'react';
import { connect } from 'react-redux';
import UsersListTable from '../components/UsersList/UsersListTable';
import Breadcrumb from '../components/Common/Breadcrumb';

const UsersList = ({ showAlertMessage, alertMessage }) => {
  return (
    <React.Fragment>
      <Breadcrumb
        items={[
          {
            label: 'Users',
            link: '/users',
          },
        ]}
      />

      <UsersListTable />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showAlertMessage: state.alertMessage?.status,
    alertMessage: state.alertMessage?.message,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
