import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import { AutoSizer, Table, Column } from 'react-virtualized';
import _ from 'lodash';
import { Tooltip, TextField, Alert, AlertTitle, LinearProgress } from '@mui/material';
import { Link } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { adminPortalSelect } from '../../utils/ReactSelectStyles';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { parseISO, format, isBefore, isAfter, isWithinInterval } from 'date-fns';
import { CalendarBtn } from '../../../styledComponents/GlobalStyle';

const ContractsListTable = ({ branding, getAllItemsFromDB, contracts, tableName, statusOptions }) => {
  const { headers } = branding?.fonts[0]?.font;
  const history = useHistory();

  const [data, setData] = useState([]);
  const [filtering, setFiltering] = useState('');
  const [status, setStatus] = useState('');
  const [filterByDate, setFilterByDate] = useState({ from: '', to: '' });

  useEffect(() => {
    getAllItemsFromDB(tableName);
  }, [getAllItemsFromDB, tableName]);

  useEffect(() => {
    filterContracts();
    // eslint-disable-next-line
  }, [contracts, filtering, status, filterByDate]);

  function filterContracts() {
    const checkIfAnyDateFilterIsSet = _.filter(Object.values(filterByDate), (s) => s.length > 0).length;
    const checkIfDateIsAfter = (date) => isAfter(parseISO(date), parseISO(filterByDate.from));
    const checkIfDateIsBefore = (date) => isBefore(parseISO(date), parseISO(filterByDate.to));
    const filteringContracts = contracts?.filter(
      (c) =>
        (!filtering ||
          c?.customerOrganisationName?.toLowerCase().includes(filtering.toLowerCase()) ||
          c?.customerEmail?.toLowerCase().includes(filtering.toLowerCase()) ||
          c?.id?.toLowerCase().includes(filtering.toLowerCase())) &&
        (!status || c?.status?.toLowerCase().includes(status.toLowerCase())) &&
        (!checkIfAnyDateFilterIsSet ||
          (filterByDate.from &&
            filterByDate.to &&
            // If both dates are set, check if contract is within interval
            isWithinInterval(parseISO(c?.expectedStartDate), {
              start: parseISO(filterByDate.from),
              end: parseISO(filterByDate.to),
            })) ||
          // If from date is set, check if contract is after date
          (!filterByDate.to &&
            (checkIfDateIsAfter(c?.expectedStartDate) || c?.expectedStartDate.includes(filterByDate.from))) ||
          // If to date is set, check if contract is before date
          (!filterByDate.from &&
            (checkIfDateIsBefore(c?.expectedStartDate) || c?.expectedStartDate.includes(filterByDate.to))))
    );

    setData(_.orderBy(filteringContracts, (item) => item.created && new Date(item.created), 'desc'));
  }

  const formatCellValue = ({ cellData, dataKey, rowData }) => {
    if (dataKey === 'actions') {
      return (
        <div>
          <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{'Go to Contract Page'}</p>}>
            <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
              <Link onClick={() => history.push(`/admin/sales/contract/${rowData.id}`)} />
            </div>
          </Tooltip>
        </div>
      );
    } else if (cellData) {
      let formatCellData = cellData;
      if (dataKey === 'type' || dataKey === 'status') {
        // Capitalize first letter for Status and Type
        formatCellData = cellData.charAt(0).toUpperCase() + cellData.slice(1);
      } else if (dataKey === 'expectedStartDate') {
        formatCellData = format(parseISO(cellData), 'dd-MM-yyyy');
      }

      return (
        <div>
          <Tooltip sx={{ fontSize: 20 }} title={<p style={{ fontSize: 12, marginBottom: 0 }}>{formatCellData}</p>}>
            <div style={{ cursor: 'pointer' }} className='admin_portal_cellData_description'>
              {formatCellData}
            </div>
          </Tooltip>
        </div>
      );
    }
  };

  if (!contracts?.length > 0 && !data?.length > 0) {
    return <LinearProgress color='inherit' />;
  }

  return (
    <section className='panel' style={{ fontFamily: headers }}>
      <header
        className='panel-heading'
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        Contracts
        <div style={{ display: 'flex' }}>
          {['from', 'to'].map((item, index) => (
            <label
              key={index}
              className='input-group date datepicker'
              style={{ fontWeight: 'normal', marginLeft: 10, width: '20vh' }}>
              <DatePicker
                showMonthDropdown
                showYearDropdown
                dateFormat='dd/MM/yyyy'
                placeholderText={!index ? 'From' : 'To'}
                className='form-control startDate'
                maxDate={new Date()}
                selected={filterByDate[item] ? parseISO(filterByDate[item]) : null}
                onChange={(date) => {
                  if (!date) setFilterByDate((prevDate) => ({ ...prevDate, [item]: '' }));
                  else setFilterByDate((prevDate) => ({ ...prevDate, [item]: format(date, 'yyyy-MM-dd') }));
                }}
                popperPlacement='auto'
                isClearable={true}
              />
              <span className='input-group-btn'>
                <CalendarBtn className='btn btn-primary date-set'>
                  <i className='fa fa-calendar' />
                </CalendarBtn>
              </span>
            </label>
          ))}
          <Select
            id='contract-status-select'
            options={statusOptions}
            onChange={(s) => setStatus(s?.value)}
            isOptionDisabled={!data ? true : false}
            styles={adminPortalSelect}
            isClearable={true}
            placeholder={'Status'}
            menuPlacement='auto'
            menuPosition='fixed'
          />
          <TextField
            InputProps={{ endAdornment: <SearchIcon /> }}
            id='outlined-basic'
            label='Search'
            variant='outlined'
            size='small'
            sx={{ marginLeft: 5 }}
            onChange={(e) => setFiltering(e.target.value)}
          />
        </div>
      </header>
      <div id='contract-not-found' className='panel-body' style={{ height: '100vh' }}>
        {!data?.length > 0 && (
          <Alert severity='warning' sx={{ fontSize: 15, width: '50%' }}>
            <AlertTitle>No Match Found</AlertTitle>
            There are no search results!
          </Alert>
        )}

        <div style={{ minWidth: '100%', height: '100%', display: data?.length ? 'block' : 'none' }}>
          {data?.length && (
            <AutoSizer>
              {({ width, height }) => (
                <Table
                  width={width}
                  height={height}
                  rowHeight={50}
                  headerHeight={60}
                  headerStyle={{ verticalAlign: 'bottom', width: '100%', display: 'flex', justifyContent: 'center' }}
                  rowStyle={{ textAlign: 'center' }}
                  headerClassName='admin_portal_column_headers'
                  rowCount={data && data.length}
                  rowGetter={({ index }) => data[index]}>
                  <Column label='Document Id' dataKey='id' width={400} cellRenderer={formatCellValue} />
                  <Column
                    label='Organisation Name'
                    dataKey='customerOrganisationName'
                    width={400}
                    cellRenderer={formatCellValue}
                  />
                  <Column label='Type' dataKey='type' width={400} cellRenderer={formatCellValue} />
                  <Column
                    label='Contract Start Date'
                    dataKey='expectedStartDate'
                    width={400}
                    cellRenderer={formatCellValue}
                  />
                  <Column label='Signatory Email' dataKey='customerEmail' width={170} cellRenderer={formatCellValue} />
                  <Column label='Status' dataKey='status' width={170} cellRenderer={formatCellValue} />
                  <Column label='Actions' dataKey='actions' width={170} cellRenderer={formatCellValue} />
                </Table>
              )}
            </AutoSizer>
          )}
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  const tableName = 'contracts';

  const statusOptions = [
    { label: 'Archived', value: 'archived' },
    { label: 'Complete', value: 'complete' },
    { label: 'Draft', value: 'draft' },
    { label: 'Pending', value: 'pending' },
  ];

  return {
    contracts: state?.admin?.adminPortal?.[tableName],
    branding: state.branding,
    tableName,
    statusOptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractsListTable);
