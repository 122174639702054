import axios from 'axios';
import { itemToOptions } from '../../components/utils/GlobalFunctions.js';
import {
  getStepFunctionStatus,
  processTableID,
  processingStepFunctionStatus,
  showAnalysisModal,
  showLoader,
  toggleAlertMessage,
} from '../action.js';
import createSignedRequest from './createSignedRequest';
import { Actions as ORGANISATION_ACTIONS } from './organisationActions';

const { apiURL } = require('./apiURL');

const Types = {
  MODAL: 'ADMIN_PROFILE_MODAL',
  ADMIN_GETTING: 'ADMIN_GETTING',
  ADMIN_ORGANISATIONS: 'ADMIN_ORGANISATIONS',
  ADMIN_GET_CONVERSION_FACTORS: 'ADMIN_GET_CONVERSION_FACTORS',
  ADMIN_GET_ALL_ITEMS: 'ADMIN_GET_ALL_ITEMS',
  SHOW_UPLOAD_LOGO_MODAL: 'ADMIN_SHOW_UPLOAD_LOGO_MODAL',
  ADMIN_SAVE_CONVERSION_FACTOR: 'ADMIN_SAVE_CONVERSION_FACTOR',
  ADMIN_UPDATE_CONVERSION_FACTOR: 'ADMIN_UPDATE_CONVERSION_FACTOR',
  ADMIN_SET_CHECKBOXES: 'ADMIN_SET_CHECKBOXES',
  ADMIN_UPDATE_ENTITY_BOX: 'ADMIN_UPDATE_ENTITY_BOX',
  ADMIN_UPDATE_USAGE_TOTAL_BOX: 'ADMIN_UPDATE_USAGE_TOTAL_BOX',
  ADMIN_UPDATE_USAGE_DATA_BOX: 'ADMIN_UPDATE_USAGE_DATA_BOX',
  ADMIN_EXPORT_USAGE_DATA: 'ADMIN_EXPORT_USAGE_DATA',
  ADMIN_SUBMIT_BULK_USAGE_DATA: 'ADMIN_SUBMIT_BULK_USAGE_DATA',
  ADMIN_PAYMENT_ORGANISATION: 'ADMIN_PAYMENT_ORGANISATION',
  ADMIN_UPDATE_STEP_FUNCTION_STATUS: 'ADMIN_UPDATE_STEP_FUNCTION_STATUS',
  ADMIN_INDEX_RANGE_ITEMS: 'ADMIN_INDEX_RANGE_ITEMS',
  ADMIN_INDEX_TERMS_ITEMS: 'ADMIN_INDEX_TERMS_ITEMS',
  ADD_OR_UPDATE_ADMIN_PORTAL_ITEMS: 'ADMIN_ADD_OR_UPDATE_ADMIN_PORTAL_ITEMS',
  DELETE_ADMIN_PORTAL_ITEMS: 'ADMIN_DELETE_ADMIN_PORTAL_ITEMS',
  ADMIN_UPDATE_CHECKED_ITEMS: 'ADMIN_UPDATE_CHECKED_ITEMS',
  ADMIN_TOGGLE_SELECT_ALL_CHECKED: 'ADMIN_TOGGLE_SELECT_ALL_CHECKED',
  ADMIN_SET_OS_TERMS: 'ADMIN_SET_OS_TERMS',
  LIST_CONTRACTS: 'ADMIN_LIST_CONTRACTS',
  UPDATE_CONTRACTS: 'ADMIN_UPDATE_CONTRACTS',
};

const updateCheckedItems = (itemID) => ({
  type: Types.ADMIN_UPDATE_CHECKED_ITEMS,
  payload: { itemID },
});

const toggleSelectAllChecked = (checked) => ({
  type: Types.ADMIN_TOGGLE_SELECT_ALL_CHECKED,
  payload: { checked },
});

const setOpenSearchTerms = (openSearchTerms) => ({ type: Types.ADMIN_SET_OS_TERMS, payload: openSearchTerms });

export const toggleModal = (status, action, item, notAutoClose, openSearchTerms) => ({
  type: Types.MODAL,
  status,
  action,
  item,
  notAutoClose,
  openSearchTerms,
});

const initializeCheckBoxes = () => ({
  type: Types.ADMIN_SET_CHECKBOXES,
});

const updateEntityCheckBox = (entity) => ({
  type: Types.ADMIN_UPDATE_ENTITY_BOX,
  entity: entity,
});

const submitBulkEditForEntity = (entity) => ({
  type: Types.ADMIN_SUBMIT_BULK_USAGE_DATA,
  entity: entity,
});

const updateUsageTotalCheckBox = (usageTotalId) => ({
  type: Types.ADMIN_UPDATE_USAGE_TOTAL_BOX,
  usageTotalId: usageTotalId,
});

const updateUsageDataCheckBox = (usageItem) => ({
  type: Types.ADMIN_UPDATE_USAGE_DATA_BOX,
  usageItem: usageItem,
});

const updateAdminOrganisations = (response) => ({
  type: Types.ADMIN_ORGANISATIONS,
  payload: response,
});

export const getAdminOrganisations = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const { cyfAdmin, greenElementUser } = state.profile.details || {};
    const path = cyfAdmin || greenElementUser ? `/my/admin/organisations` : `/my/whitelabel/organisations`;

    try {
      const response = await axios(await createSignedRequest('GET', apiURL + path, null));

      console.log('getAdminOrganisations', response);
      dispatch(updateAdminOrganisations(response.data));
    } catch (e) {
      console.log('getAdminOrganisations Error:', e);
    }
  };
};

const adminPortalItems = (response, table) => ({
  type: Types.ADMIN_GET_ALL_ITEMS,
  payload: response,
  table,
});

export const updateDefaultValues = (type) => {
  //Used for Usage Type Labels Modal
  return async (dispatch, getState) => {
    const state = getState();
    //get all values from usageTypes tables and filter based on type
    const usageTypes = state?.admin?.adminPortal?.usageTypes;
    const usageTypeFiltered = usageTypes?.filter((ut) => ut?.type === type);
    usageTypeFiltered?.map(itemToOptions);
    dispatch(adminPortalItems({ items: usageTypeFiltered }, 'defaultValues'));
  };
};

export const getAllItemsFromDB = (table, filter) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { consultantAdmin, whiteLabelAdmin } = state.profile?.details;
    dispatch(showLoader(true));

    let endPoint;

    if (whiteLabelAdmin) {
      endPoint = `/admin/portal/whiteLabelAdmin/${table}`;
    } else if (consultantAdmin) {
      endPoint = `/admin/portal/consultantAdmin/${table}`;
    } else {
      endPoint = `/admin/portal/${table}${filter ? `?page=${filter.page}&size=${filter.size}` : ''}`;
    }

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + endPoint, null, { Organisation: String(state.currentOrganisation) })
      );

      console.log('getAllItemsFromDB', response.data);
      dispatch(adminPortalItems(response.data, table));
      dispatch(showLoader(false));
      return response;
    } catch (e) {
      dispatch(showLoader(false));
      console.log('getAllItemsFromDB Error:', e);
    }
  };
};

const addOrUpdateAdminPortalItems = (item, table, id) => ({
  type: Types.ADD_OR_UPDATE_ADMIN_PORTAL_ITEMS,
  item,
  table,
  id,
});

const updateOrAddToDB = (table, item) => {
  return async (dispatch, getState) => {
    const state = getState();

    console.log('ITEM', item);

    // Mapping conversionFactor data to keep null values.
    if (table === 'conversionFactors') {
      item = item.map((o) => {
        return {
          id: o?.id?.toString() || null,
          ch4: o?.ch4 || '0.00',
          co2: o?.co2 || '0.00',
          co2e: o?.co2e || '0.00',
          fuel: o?.fuel || null,
          load: o?.load || null,
          price: o?.price || null,
          originlocation: o?.originlocation || null,
          finallocation: o?.finallocation || null,
          factorprovider: o?.factorprovider || null,
          metric: o?.metric,
          n2o: o?.n2o || '0.00',
          production: o?.production || null,
          scope: o?.scope,
          seat: o?.seat || null,
          subentity: o?.subentity,
          vehicle: o?.vehicle || null,
          waste: o?.waste || null,
          wtt: o?.wtt || '0.00',
          year: o?.year,
          outsideOfScopes: o?.outsideOfScopes || '0.00',
          source: o?.source || null,
          addOns: o?.addOns || null,
          creationDate: o?.id ? o?.creationDate : new Date().toString(),
          modificationDate: new Date().toString(),
          discontinuedDate: o?.discontinuedDate || null,
          userId: state?.profile?.details?.id || null,
        };
      });
    }

    /*
    if (table === 'marketFactors') {
      item = item.map((o) => {
        return {
          id: o?.id?.toString() || null,
          factorSource: o?.factorSource || null,
          kgco2e: o?.kgco2e || null,
          kgco2eWTT: o?.kgco2eWTT || null,
          subentity: o?.subentity || null,
          TDWTT: o?.TDWTT || null,
          TD: o?.TD || null,
          year: o?.year || null,
          outsideOfScopes: o?.outsideOfScopes || null,
          radioactiveWaste: o?.radioactiveWaste || null,
          renewable: o?.renewable || null,
          source: o?.source || null,
          creationDate: o?.id ? o?.creationDate : formatISO(new Date()),
          modificationDate: formatISO(new Date()),
          price: o?.price || null,
          originlocation: o?.originlocation || null,
          finallocation: o?.finallocation || null,
          factorprovider: o?.factorprovider || null,
        };
      });
    } */

    if (table === 'integrationMappings') {
      item = item.map((o) => {
        return {
          id: o?.id?.toString() || null,
          entityId: o?.entityId || null,
          subentityId: o?.subentityId || null,
          factorprovider: o?.factorprovider || null,
          finallocation: o?.finallocation || null,
          originlocation: o?.originlocation || null,
          fuel: o?.fuel || null,
          vehicle: o?.vehicle || null,
          load: o?.load || null,
          price: o?.price || null,
          production: o?.production || null,
          seat: o?.seat || null,
          waste: o?.waste || null,
        };
      });
    }

    try {
      const response = await axios(
        await createSignedRequest('PUT', apiURL + `/admin/portal/${table}`, JSON.stringify(item), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('updateOrAddToDB', response.data);
      const { message, id, success } = response.data || {};

      if (!success && item.length >= 2) {
        dispatch(toggleAlertMessage(true, message, 'error'));
        return;
      } else if (!success) {
        dispatch(toggleAlertMessage(true, response.data.updatedItem.message, 'error'));
        return;
      }

      dispatch(toggleAlertMessage(true, message, 'success'));

      if (table === 'conversionFactors') {
        const { filters, page, tableSize } = state?.admin?.openSearchTerms;
        setTimeout(async () => {
          // This triggers the fetch of the new/edited CF from the CF admin page
          dispatch(getItemsByTermsFromIndex(table, filters, page, tableSize));
        }, 1500);
      } else {
        dispatch(addOrUpdateAdminPortalItems(item, table, id));
      }
    } catch (e) {
      console.log('updateOrAddToDB Error:', e);
    }
  };
};

const toggleCognitoUserStatus = (table, item) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/admin/portal/${table}/cognitoUser`, JSON.stringify({ item }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('UserFromCognito', response.data);

      if (!response.data.upatedUserStatus?.success) {
        dispatch(toggleAlertMessage(true, response.data.upatedUserStatus.message, 'error'));
        return;
      }

      dispatch(toggleAlertMessage(true, response.data.upatedUserStatus?.message, 'success'));
      dispatch(getAllItemsFromDB(table));
    } catch (e) {
      console.log('UserFromCognito Error:', e);
    }
  };
};

const deleteAdminPortalItems = (item, table) => ({
  type: Types.DELETE_ADMIN_PORTAL_ITEMS,
  item,
  table,
});

const deleteItemFromDB = (table, items) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('DELETE', apiURL + `/admin/portal/${table}`, JSON.stringify(items), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('deleteItemFromDB', response);

      const { message, success } = response.data || {};

      if (table === 'reports' && !success) {
        // Show message, that the report cannot be deleted
        dispatch(toggleModal(true, 'reports-delete-not-allowed'));
        return;
      } else if (!success) {
        dispatch(toggleAlertMessage(true, message, 'error'));
      } else {
        dispatch(toggleAlertMessage(true, message, 'success'));
      }

      if (table === 'conversionFactors') {
        const { filters, page, tableSize } = state?.admin?.openSearchTerms;
        setTimeout(async () => {
          // This triggers the fetch of the new/edited CF from the CF admin page
          dispatch(getItemsByTermsFromIndex(table, filters, page, tableSize));
        }, 1500);
      } else {
        // Updates the State with the new list of items
        dispatch(deleteAdminPortalItems(items, table));
      }
    } catch (e) {
      console.log('deleteItemsFromDB Error:', e);
    }
  };
};

const getRangeFromIndex = (index, size, from) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/admin/portal/range/${index}?size=${size}&page=${from}`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getRangeFromIndex', response);
      dispatch(listItems(response.data.items, index, response.data.totalCount));
    } catch (e) {
      console.log('getRangeFromIndex Error', e);
    }
  };
};

export const listItems = (items, index, totalCount) => ({
  type: Types.ADMIN_INDEX_RANGE_ITEMS,
  items,
  index,
  totalCount,
});

export const getItemsByTermsFromIndex = (index, filters, page, size) => {
  return async (dispatch) => {
    dispatch(showLoader(true));
    const filterToString = JSON.stringify(filters);

    try {
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + `/admin/portal/itemsByTerms/${index}?page=${page}&size=${size}`,
          filterToString,
          null
        )
      );

      console.log('getItemsByTermsFromIndex', response);

      dispatch(listTermsItems(response.data.items, index, response.data.totalCount));
      dispatch(setOpenSearchTerms({ index, filters, page, tableSize: size }));
      dispatch(showLoader(false));
    } catch (e) {
      console.log('getItemsByTermsFromIndex Error', e);
      dispatch(showLoader(false));
    }
  };
};

const getItemsByTermsFromIndexExport = (index, filters, page, size) => {
  return async (dispatch) => {
    const filterToString = JSON.stringify(filters);

    try {
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + `/admin/portal/itemsByTerms/${index}${page ? `?page=${page}` : ''}${size ? `&size=${size}` : ''}`,
          filterToString,
          null
        )
      );

      const signedURL = response?.data;
      if (signedURL) {
        const popup = window.open(signedURL);
        if (!popup || popup.closed || typeof popup.closed === 'undefined') {
          const message = 'Popup Blocker is enabled! Please add this site to your exception list.';
          alert(message);
        }
      }
    } catch (e) {
      dispatch(toggleAlertMessage(true, 'There was an error while exporting data!', 'error', 2000));
      console.log('getItemsByTermsFromIndexExport Error', e);
    }
  };
};

export const listTermsItems = (items, index, totalCount) => ({
  type: Types.ADMIN_INDEX_TERMS_ITEMS,
  items,
  index,
  totalCount,
});

const updateStepFunctionStatus = (processingReport, status, reportFiletype) => ({
  type: Types.ADMIN_UPDATE_STEP_FUNCTION_STATUS,
  status,
  reportFiletype,
  processingReport,
});

const getAdminReports = (tableName, fileType) => {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch(updateStepFunctionStatus(null, true, fileType));

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/admin/generateReport/${tableName}`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getAdminReports', response);
      dispatch(updateStepFunctionStatus(response.data, true, fileType));
    } catch (e) {
      console.log('getAdminReports Error', e);
      dispatch(updateStepFunctionStatus(null, false, fileType));
    }
  };
};

let reportDownloaded = '';
const getAdminReportsStepFunctionStatus = (importArn) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + '/my/stepFunctionStatus/' + importArn, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      dispatch(processingStepFunctionStatus(response.data));

      //Step Function Status is Succeeded
      if (response.data?.data?.status === 'SUCCEEDED') {
        const signedURL = JSON.parse(response.data.data.output);
        const reportType = JSON.parse(response.data.data.input)?.type;
        if (reportDownloaded !== signedURL.signedURL) {
          reportDownloaded = signedURL.signedURL;
          //const popup = window.open(signedURL.signedURL);
          //if (!popup || popup.closed || typeof popup.closed == 'undefined') {
          //alert('Popup Blocker is enabled! Please add this site to your exception list.');
          //}
        }
        dispatch(updateStepFunctionStatus(false, false, reportType));
      }

      //Step Function Status Failed
      if (response.data?.data?.status === 'FAILED') {
        const reportType = JSON.parse(response.data.data.input)?.type;
        dispatch(updateStepFunctionStatus(false, false, reportType));
        alert('Popup Blocker is enabled! Please add this site to your exception list.');
      }
    } catch (e) {
      console.log('getReportStepFunctionStatus Error:', e);
    }
  };
};

const showUploadLogoModal = (status, item) => ({
  type: Types.SHOW_UPLOAD_LOGO_MODAL,
  status,
  item,
});

const paymentOrganistion = (organisationType) => ({
  type: Types.ADMIN_PAYMENT_ORGANISATION,
  organisationType: organisationType,
});

const recalculateUsage = (id) => {
  return async (dispatch, getState) => {
    const state = getState();

    let field;
    if (id === 'recalcAllUsage') {
      field = id;
    } else {
      switch (window.location.pathname.split('/')[2]) {
        case 'organisations':
          field = 'organisation';
          break;
        case 'subEntities':
          field = 'subentity';
          break;
        default:
      }
    }

    try {
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + `/admin/recalculateUsage?field=${field}`,
          id === 'recalcAllUsage' ? '{}' : JSON.stringify({ id }),
          {
            Organisation: String(state.currentOrganisation),
          }
        )
      );

      console.log('recalculateUsage', response);
      if (field === 'recalcAllUsage') {
        const message = 'ALL usage is being recalculated, please check your email in about an hour';
        dispatch(toggleAlertMessage(true, message, 'success', 300000));
      } else {
        dispatch(getStepFunctionStatus(response.data.data.executionArn));
        //dispatch(updateStepFunctionStatus(response.data));
      }
    } catch (e) {
      console.log('recalculateUsage Error', e);
    }
  };
};

const getRecalcUsageStepFunctionStatus = (importArn) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + '/my/stepFunctionStatus/' + importArn, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      dispatch(processingStepFunctionStatus(response.data));

      console.log('response', response.data);

      //Step Function Status is Succeeded
      if (response.data?.data?.status === 'SUCCEEDED') {
        const data = JSON.parse(response.data.data.output);
        console.log('response data', data);
        dispatch(updateStepFunctionStatus({ ...response.data.data, ...data }));
        if (data.message !== 'No usage data to be recalculated!' && data.type === 'recalculate') {
          // Just show the analysis modal when needed
          dispatch(showAnalysisModal(true, 'recalculateUsage'));
          dispatch(toggleAlertMessage(false));
        }
        return;
      }

      //Step Function Status Failed
      if (response.data?.data?.status === 'FAILED') {
        dispatch(toggleAlertMessage(true, 'There was an error while recalculating Usage!', 'success'));
        return;
      }

      dispatch(toggleAlertMessage(true, 'The usage is being recalculated!', 'success', 600000));
    } catch (e) {
      console.log('getReportStepFunctionStatus Error:', e);
    }
  };
};

const transferOrg = (transferObj) => {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch(showLoader(true));

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/admin/transferPortal`, JSON.stringify({ transferObj }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('transferOrg', response);

      const { id: tableID, newOrg } = response.data || {};

      const transfPortalParamsObj = { state, dispatch, type: 'transferPortal' };
      await processTableID(tableID, transfPortalParamsObj);

      dispatch(showLoader(false));
      if (newOrg) {
        dispatch(ORGANISATION_ACTIONS.saveTransferredOrg(newOrg));
      }
    } catch (e) {
      console.log('transferOrg Error', e);
      dispatch(toggleAlertMessage(true, e.message, 'error', 2000));
      dispatch(showLoader(false));
    }
  };
};

const updateContracts = (contract) => ({
  type: Types.UPDATE_CONTRACTS,
  contract,
});

const saveContract = (contract, history) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/my/contract`, JSON.stringify({ contract }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('saveContract', response);

      const { success, message, contract: savedContract } = response.data || {};

      if (!success) {
        dispatch(showLoader(false));
        // if message comes as undefined it meas that user is not authorised to create new contract
        const errorMsg = message ? message : 'User not authorised';
        return dispatch(toggleAlertMessage(true, errorMsg, 'error', 5000));
      } else {
        dispatch(showLoader(false));
        dispatch(toggleAlertMessage(true, message, 'success', 5000));
        // New contract saved, push user to contract list page
        if (history) history.push('/admin/sales/contracts');
        else dispatch(updateContracts(savedContract)); // Editing contract, update state
      }
    } catch (e) {
      console.log('saveContract Error', e);
      dispatch(showLoader(false));
      dispatch(toggleAlertMessage(true, e.message, 'error', 5000));
    }
  };
};

const migrateContract = (contract) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/my/contract/migrateOrgData`, JSON.stringify(contract), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('migrateContract', response);

      const { success, message } = response.data || {};

      if (!success) {
        return dispatch(toggleAlertMessage(true, message, 'error', 5000));
      }

      dispatch(toggleAlertMessage(true, message, 'success', 5000));
    } catch (e) {
      console.log('migrateContract Error', e);
      dispatch(toggleAlertMessage(true, e.message, 'error', 5000));
    }
  };
};

const listContract = (action) => ({
  type: Types.LIST_CONTRACTS,
  action,
});

const getContract = (id) => {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch(listContract({ contracts: false, loader: true }));

    try {
      const response = await axios(
        await createSignedRequest('GET', apiURL + `/my/contract/${id}`, null, {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('getContract', response);

      const { success, message, contract } = response.data || {};

      if (!success) {
        dispatch(listContract({ contracts: false, loader: false }));
        const errorMsg = message ? message : 'User not authorised';
        return dispatch(toggleAlertMessage(true, errorMsg, 'error', 5000));
      } else {
        dispatch(listContract({ contracts: contract, loader: false }));
        return response;
      }
    } catch (e) {
      console.log('getContract Error', e);
      dispatch(listContract({ contracts: false, loader: false }));
      dispatch(toggleAlertMessage(true, e.message, 'error', 5000));
    }
  };
};

const executeContract = (contract) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/my/executeContract`, JSON.stringify({ contract }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('executeContract', response);
      const { success, message } = response.data || {};

      if (!success) {
        const errorMsg = message ? message : 'User not authorised';
        dispatch(toggleAlertMessage(true, errorMsg, 'error', 3000));
      } else {
        dispatch(toggleAlertMessage(true, message, 'success', 3000));
        dispatch(getContract(contract.id));
      }
    } catch (err) {
      console.log('executeContract Error', err.message);
      dispatch(toggleAlertMessage(true, err.message, 'error', 3000));
    }
  };
};

const saveWLSettings = (settings) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest('POST', apiURL + `/admin/portal/whiteLabelSettings`, JSON.stringify({ settings }), {
          Organisation: String(state.currentOrganisation),
        })
      );

      console.log('saveWLSettings', response);
      dispatch(toggleAlertMessage(true, response.data.message, 'success', 2000));
    } catch (e) {
      console.log('saveWLSettings Error', e);
      dispatch(toggleAlertMessage(true, e.message, 'error', 2000));
    }
  };
};

const linkStripeAccount = (stripeAccountId, organisationId) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + `/admin/portal/attachStripeAccount`,
          JSON.stringify({ stripeAccountId, organisationId }),
          {
            Organisation: String(state.currentOrganisation),
          }
        )
      );

      console.log('linkStripeAccount', response);
      dispatch(toggleAlertMessage(true, response.data.message, 'success', 4000));
    } catch (e) {
      console.log('linkStripeAccount Error', e);
      dispatch(toggleAlertMessage(true, e.message, 'error', 5000));
    }
  };
};

const unlinkStripeAccount = (stripeAccountId, organisationId, subEndDate) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios(
        await createSignedRequest(
          'POST',
          apiURL + '/admin/portal/unlinkStripeAccount',
          JSON.stringify({ stripeAccountId, organisationId, subEndDate }),
          {
            Organisation: String(state.currentOrganisation),
          }
        )
      );

      console.log('unlinkStripeAccount', response);
      dispatch(toggleAlertMessage(true, response.data.message, 'success', 4000));
    } catch (e) {
      console.log('unlinkStripeAccount Error', e);
      dispatch(toggleAlertMessage(true, e?.stack, 'error', 5000));
    }
  };
};

const Actions = {
  toggleModal,
  getAdminOrganisations,
  getAllItemsFromDB,
  updateOrAddToDB,
  toggleCognitoUserStatus,
  showUploadLogoModal,
  deleteItemFromDB,
  initializeCheckBoxes,
  updateEntityCheckBox,
  updateUsageTotalCheckBox,
  updateUsageDataCheckBox,
  submitBulkEditForEntity,
  paymentOrganistion,
  getAdminReports,
  getAdminReportsStepFunctionStatus,
  getRangeFromIndex,
  getItemsByTermsFromIndex,
  getItemsByTermsFromIndexExport,
  recalculateUsage,
  getRecalcUsageStepFunctionStatus,
  transferOrg,
  saveContract,
  updateCheckedItems,
  toggleSelectAllChecked,
  setOpenSearchTerms,
  getContract,
  executeContract,
  updateDefaultValues,
  migrateContract,
  saveWLSettings,
  linkStripeAccount,
  unlinkStripeAccount,
};

export { Actions, Types };
