import React from 'react';
import { connect } from 'react-redux';
import LoginPage from './LoginPage';

const Login = ({ branding }) => {
  const { colors } = branding || {};

  if (colors) return <LoginPage />;
  else return null;
};

const mapStateToProps = (state) => {
  return {
    branding: state.branding,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
