import { Alert, Modal } from '@mui/material';
import { addDays, addYears, format, setDate, setMonth, setYear } from 'date-fns';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';
import { Actions as ACTIONS } from '../../store/actions/reportActions';
import { AddButton } from '../../styledComponents/GlobalStyle';
import { customStyles } from '../UsageData/codeStyles';
import WarningModal from './WarningModal';

const ConfirmModal = ({
  openModal,
  setOpenModal,
  reportingDates,
  addReport,
  showReportModal,
  subDetails,
  setReportingDates,
}) => {
  const [loading, setLoading] = useState(false);
  const [cardDeclined, setCardDeclined] = useState(false);
  const history = useHistory();
  const handleClose = () => {
    showReportModal(null);
    setOpenModal(false);
    if (cardDeclined) setCardDeclined(false);
  };

  return (
    <div>
      <Modal open={openModal} onClose={handleClose}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button onClick={handleClose} className='close'>
                x
              </button>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4 className='modal-title'>Please confirm the billing</h4>
              </div>
            </div>
            <div className='modal-body' style={{ overflow: 'auto' }}>
              <p style={{ fontWeight: 'bold' }}>
                Please click "confirm" to verify that your payment method on file will be charged {subDetails?.subPrice}{' '}
                {subDetails?.curr} to generate a new report, and that your subscription start will be re-set to today's date.
              </p>
              {cardDeclined && (
                <Alert severity='error' onClose={() => null} style={{ marginBottom: '10px' }}>
                  <span style={{ color: 'red' }}>
                    <b>
                      Your card on file cannot be processed, please update your card details{' '}
                      <button
                        style={{ cursor: 'pointer', border: 0, background: 'inherit' }}
                        onClick={() => history.push('/profile')}>
                        here
                      </button>
                      , and re-run the report.
                    </b>
                  </span>
                </Alert>
              )}
              <div>
                <AddButton
                  className='btn btn-success'
                  disabled={!reportingDates || loading || cardDeclined ? true : false}
                  onClick={async () => {
                    try {
                      setLoading(true);
                      const { error } = (await addReport(reportingDates)) || {};
                      console.log('error', error);
                      if (error?.includes('card')) setCardDeclined(true);
                      else handleClose();
                    } catch (err) {
                      console.log('err', err);
                    } finally {
                      setLoading(false);
                      setReportingDates(null);
                    }
                  }}>
                  {loading && <i className='fa fa-spin fa-spinner' style={{ marginRight: 5 }} />}
                  Confirm
                </AddButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const NonStripeModal = ({ openModal, setOpenModal, showReportModal, supportEmail }) => {
  const handleClose = () => {
    showReportModal(null);
    setOpenModal(false);
  };

  return (
    <div>
      <Modal open={openModal} onClose={handleClose}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button onClick={handleClose} className='close'>
                x
              </button>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h4 className='modal-title'>Please contact support</h4>
              </div>
            </div>
            <div className='modal-body' style={{ overflow: 'auto' }}>
              <p style={{ fontWeight: 'bold' }}>
                Please contact support to update your subscription to generate a new report. This means that your
                subscription start date will be re-set to the payment's date.
              </p>
              <div>
                <AddButton
                  className='btn btn-success'
                  onClick={async () => {
                    window.open(`mailto:${supportEmail}`);
                    handleClose();
                  }}>
                  Email Support
                </AddButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const AddReport = ({
  addReport,
  hideAddReportModal,
  showModal,
  filteredReportOptions,
  noExtraReport,
  updateSubscriptionToGenerateReport,
  showReportModal,
  nonStripeUser,
  supportEmail,
  admin,
  consultant,
  directUser,
}) => {
  const [subDetails, setSubDetails] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [reportingDates, setReportingDates] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showNonStripeModal, setShowNonStripeModal] = useState(false);

  if (!showModal) return null;

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog' style={{ height: '90vh', overflow: 'auto', fontSize: '12px' }}>
        <div className='modal-content'>
          <div className='modal-header'>
            <button
              onClick={() => {
                hideAddReportModal();
                setReportingDates(null);
              }}
              className='close'>
              x
            </button>
            <h4 className='modal-title'>Add Report</h4>
          </div>
          <div className='modal-body'>
            <div className='form-group' style={{ display: noExtraReport ? 'none' : 'block' }}>
              <label>Reporting Dates</label>
              <Select
                id='reportDatesSelect'
                onChange={setReportingDates}
                options={filteredReportOptions}
                isClearable={false}
                maxMenuHeight={5}
                styles={{
                  ...customStyles,
                  container: (provided, state) => {
                    const inheritedStyles = customStyles.container(provided, state);
                    return { ...inheritedStyles, zIndex: 3 };
                  },
                }}
              />
            </div>

            {noExtraReport && (
              <p style={{ fontSize: 14 }}>
                Please contact TerraVerde at
                <a href='mailto:hello@terraverde-solutions.com'>{''} hello@terraverde-solutions.com</a>.
              </p>
            )}

            {reportingDates?.financialReportDate && (
              <div className='alert alert-warning'>
                <p>
                  When your reporting period is not a calendar year, we will create an extra report for free to cover the
                  calendar year {format(reportingDates.calendarReportDate.from, 'do MMM yyyy')} to{' '}
                  {format(reportingDates.calendarReportDate.to, 'do MMM yyyy')} - should you need to report on both.
                </p>
              </div>
            )}

            {updateSubscriptionToGenerateReport && directUser && !nonStripeUser && (
              <WarningModal setSubDetails={setSubDetails} subDetails={subDetails} />
            )}

            {showConfirmModal && (
              <ConfirmModal
                openModal={showConfirmModal}
                setOpenModal={setShowConfirmModal}
                reportingDates={reportingDates}
                setReportingDates={setReportingDates}
                addReport={addReport}
                showReportModal={showReportModal}
                subDetails={subDetails}
              />
            )}

            {showNonStripeModal && (
              <NonStripeModal
                openModal={showNonStripeModal}
                setOpenModal={setShowNonStripeModal}
                showReportModal={showReportModal}
                supportEmail={supportEmail}
              />
            )}

            {!noExtraReport && (
              <AddButton
                id='report-add-btn'
                className='btn btn-success'
                disabled={!reportingDates || disableBtn ? true : false}
                onClick={async () => {
                  if (nonStripeUser && directUser && !admin) {
                    setShowNonStripeModal(true);
                  } else if (updateSubscriptionToGenerateReport && directUser && !nonStripeUser) {
                    setShowConfirmModal(true);
                  } else {
                    setDisableBtn(true);
                    await addReport(reportingDates);
                    setDisableBtn(false);
                  }
                }}>
                {updateSubscriptionToGenerateReport && directUser && !nonStripeUser
                  ? 'Update Subscription & Add a Report'
                  : 'Add a Report'}
              </AddButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  let noExtraReport = false;

  const showModal = state.report?.modal;
  const updateSubscriptionToGenerateReport = state.report?.updateSubscription;
  const reportList = state.report?.list;
  const { whiteLabelAdmin } = state.profile?.details || {};
  const consultantId = state.organisation?.details?.consultantId;

  if (reportList.error) return;

  const calendarYearReports = state.report?.list?.filter(
    (report) => report.endDate.includes(report.year) && report.startDate.includes(report.year)
  );
  const orgDetails = state.organisation?.details;
  const whiteLabelId = state.organisation?.details?.whiteLabelId;

  if (whiteLabelId === '263247' && calendarYearReports?.length >= 2) {
    // Each client for TerraVerde can only add two reports. Only TerraVerde admins can add extra reports
    noExtraReport = whiteLabelAdmin === '263247' ? false : true;
  }

  if (!orgDetails) return { showModal: false };

  const maxReportingStartDate = addDays(
    setDate(setMonth(new Date(), (orgDetails.reportingYearEndMonth || 12) - 1), orgDetails.reportingYearEndDay || 31),
    1
  );

  const maxYear = parseInt(format(maxReportingStartDate, 'yyyy'));
  const financialReportDates = [];
  const calendarReportDates = [];

  for (var year = maxYear; year >= 2014; year--) {
    let calendarYear = year;
    if (orgDetails.reportingYearEndMonth > 6 && orgDetails.reportingYearEndMonth !== 12) {
      // calendar year to be less 1 to make sense
      calendarYear += 1;
    }
    calendarReportDates.push({
      from: new Date(`${calendarYear}-01-01`),
      to: new Date(`${calendarYear}-12-31`),
    });

    if (orgDetails.reportingYearEndMonth !== '12') {
      const financialYearStart = setYear(maxReportingStartDate, year);

      financialReportDates.push({
        from: financialYearStart,
        to: addDays(addYears(financialYearStart, 1), -1),
      });
    }
  }

  const reportOptions = [];

  calendarReportDates.forEach((calendarReportDate, index) => {
    const financialReportDate = financialReportDates[index];
    const dateForString = financialReportDate || calendarReportDate;
    const dateString = `${format(dateForString.from, 'do MMM yyyy')} to ${format(dateForString.to, 'do MMM yyyy')}`;

    reportOptions.push({
      calendarReportDate,
      financialReportDate,
      label: dateString,
      value: dateString,
    });
  });

  const reportListDates = Array.isArray(reportList) && reportList?.map((date) => new Date(date.startDate).toISOString());
  const filteredReportOptions =
    reportListDates &&
    reportOptions?.filter((report) => !reportListDates.includes(report.calendarReportDate.from.toISOString()));

  const customerId = state.payment?.stripe?.stripeCustomerId;
  const nonStripeUser = customerId === '' ? true : false;

  const supportEmail = state.whiteLabelAdmin?.supportEmail;
  const admin = state.profile?.details?.cyfAdmin || false;
  const accountType = state.organisation?.details?.accountType;

  let consultant;
  if (consultantId && consultantId != null && consultantId != '' && consultantId != 'NONE') {
    consultant = true;
  } else {
    consultant = false;
  }

  let whiteLabel;
  if (whiteLabelId && whiteLabelId != '0') {
    whiteLabel = true;
  } else {
    whiteLabel = false;
  }

  let directUser;
  if (accountType && accountType == 'Direct User') {
    directUser = true;
  } else {
    directUser = false;
  }

  return {
    showModal,
    reportList,
    filteredReportOptions,
    noExtraReport,
    updateSubscriptionToGenerateReport,
    nonStripeUser,
    supportEmail,
    admin: admin,
    consultant,
    whiteLabel,
    directUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addReport: (dates) => dispatch(ACTIONS.addReport(dates)),
  hideAddReportModal: () => dispatch(ACTIONS.setAddReportModal(false)),
  showReportModal: (status) => dispatch(ACTIONS.setAddReportModal(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddReport);
