import React from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';
import { toggleModal } from '../../store/actions/adminActions';

const SaveSettings = ({
  tenant,
  method,
  saveOrganisationSettings,
  selectedAccounts,
  selectedTrackingCategory,
  selectedTrackingOptions,
  toggleModal,
  savedMethod,
  savedCategory,
  showLoader,
}) => {
  const saveClick = () => {
    if (method === 'accountcodes') {
      saveOrganisationSettings({ selectedAccounts, method, type: 'xero' });
    } else {
      saveOrganisationSettings({
        selectedTrackingCategory,
        selectedTrackingOptions,
        method,
        type: 'xero',
      });
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 60 }}>
      <div
        id='saveXeroSettings'
        className='btn btn-success'
        style={{ marginLeft: 'auto' }}
        disabled={
          (method === 'accountcodes' && !selectedAccounts) ||
          (method === 'trackingcategories' && (!selectedTrackingCategory || !selectedTrackingOptions)) ||
          !method ||
          showLoader
        }
        onClick={() => {
          if (
            (savedMethod && savedMethod !== method) ||
            (savedCategory && savedCategory !== selectedTrackingCategory?.label)
          ) {
            toggleModal(true, 'saveXero-settings', { tenant: tenant }, null);
          } else {
            saveClick();
          }
        }}>
        Save Xero Settings
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedAccounts: state.selectedAccounts,
    selectedTrackingCategory: state.selectedTrackingCategory,
    selectedTrackingOptions: state?.selectedTrackingOptions,
    method: state.method,
    savedMethod: state?.savedMethod, //to check if overwriting other method settings.
    savedCategory: state?.savedCategory?.label, // to check if overwriting other category saved
    tenant: state.tenant,
    showLoader: state?.showLoader,
    tenants: state?.tenants,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveOrganisationSettings: (settings) => dispatch(ACTIONS.saveOrganisationSettings(settings)),
  toggleModal: (status, action, item, modalProps) => dispatch(toggleModal(status, action, item, modalProps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveSettings);
