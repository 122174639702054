import { Assessment, DoneOutline } from '@mui/icons-material';
import { Checkbox, FormControlLabel, LinearProgress, Radio, RadioGroup, Switch, Tooltip } from '@mui/material';
import { addWeeks, format, lastDayOfWeek, parseISO } from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import * as yup from 'yup';
import { Actions as ADMINACTIONS } from '../../../store/actions/adminActions';
import { AddButton, CalendarBtn } from '../../../styledComponents/GlobalStyle';
import Breadcrumb from '../../Common/Breadcrumb';
import { itemToOptions, removeCamelCase } from '../../utils/GlobalFunctions';
import { selectStyles } from '../../utils/ReactSelectStyles';
import ValidationMessage from '../../utils/ValidationMessage';
import ContractPreview from './ContractPreview';
import PricingBandModal from './PricingBandModal';

const breadCrumbs = [{ label: 'Contracts List', link: '/admin/sales/contracts' }];

const schema = yup.object().shape({
  type: yup.string().required('Account type is required'),
  customerFirstName: yup.string().required('First name is required'),
  customerLastName: yup.string().required('Last name is required'),
  customerOrganisationName: yup.string().required('Organisation name is required'),
  customerBillingName: yup.string().required('Billing name is required'),
  customerEmail: yup.string().email('Invalid Email Address').required('Email is required'),
  customerRegisteredAddress: yup.string().required('Address is required'),
  customerCompanyNumber: yup.string().required('Company Number is required'),
  customerRegisteredCountry: yup.string().required('Country is required'),
  billingCurrency: yup.string().when('type', {
    is: (val) => val !== 'Ambassador',
    then: yup.string().required('Currency is required'),
  }),
  billingType: yup.string().when('type', {
    is: (val) => val !== 'Ambassador',
    then: yup.string().required('Billing Type is required'),
  }),
  perReportFee: yup.string().when('type', {
    is: (val) => val !== 'Ambassador',
    then: yup.string().required('Report Fee is required'),
  }),
  licenseFee: yup.string().when('type', {
    is: (val) => val !== 'Ambassador',
    then: yup.string().required('License Fee is required'),
  }),
  expectedStartDate: yup.string().when('type', {
    is: (val) => val === 'White Label',
    then: yup.string().required('Expected start date is required'),
  }),
  expectedDeliveryDate: yup.string().when('type', {
    is: (val) => val === 'White Label',
    then: yup.string().required('Expected delivery date is required'),
  }),
  wlDevelopmentFee: yup.string().when('type', {
    is: (val) => val === 'White Label',
    then: yup.string().required('Development Fee is required'),
  }),
  ambassadorCommissionRate: yup.string().when('type', {
    is: (val) => val === 'Ambassador',
    then: yup.string().required('Ambassador Commission Rate is required'),
  }),
});

const Contract = ({
  editContract,
  viewContract,
  saveContract,
  loader,
  getContract,
  currentOrg,
  contract,
  getAllItemsFromDB,
  fixedRates,
  pricingBands,
  contractApprover,
  toggleModal,
  showMigrateBtn,
}) => {
  const [accountType, setAccountType] = useState('');
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [showRangesModal, setShowRangesModal] = useState(false);
  const [editedState, setEditedState] = useState({});
  const [options, setOptions] = useState(viewContract);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDocument, setShowDocument] = useState({ show: false, recall: false });
  // eslint-disable-next-line
  const [executingContract, setExecutingContract] = useState(false);
  const [defaultFees, setDefaultFees] = useState({});
  const { id } = useParams();
  const showExecuteButton = ['draft', 'Pending Internal Approval'].includes(contract?.status);
  const showRecallButton = ['Pending Customer Signature'].includes(contract?.status);
  const showEditButton = ['draft', 'Pending Internal Approval'].includes(contract?.status);
  const doNotShowBtns = !contractApprover && contract?.type !== 'Ambassador';

  useEffect(() => {
    if (currentOrg) {
      (async () => {
        // Once org is set, fetch contract and fixed rates, save them on local state
        const { data: { contract } = {} } = await getContract(id);
        const { data: { items } = {} } = await getAllItemsFromDB('fixedRates');
        //const { data: { pricingBands } = {} } = await getAllItemsFromDB('pricingBands');
        setDefaultFees(items);
        setAccountType(contract.type);
        setEditedState(contract);
      })();
    }
  }, [id, currentOrg, getContract, getAllItemsFromDB]);

  useEffect(() => {
    if (edit) {
      // If edit is true, set the options to the editContract, which is the contract that will be edited
      setOptions(editContract);
    } else {
      // If edit is false, set the options to the viewContract, which is the contract that is being viewed
      setOptions(viewContract);
    }
  }, [edit, contract, editContract, viewContract]);

  const updateField = (e) => {
    const perReportFee = ['customRangePrice', 'customPrice', '200'];
    const ambassadorFee = ['custom', '10', '20'];
    const wlDevelopmentFee = ['2000', '3000', 'custom'];
    const licenseFee = ['300', 'custom'];
    const { type, name, value, checked } = e.target || {};
    const newEditedState = { ...editedState };

    let defaultFee = null;
    if (defaultFees) {
      defaultFee = fixedRates.find(({ type, currency }) => type === accountType && currency === editedState.billingCurrency);
      if (defaultFee) {
        // Add default fixed rates to the above arrays, so we can access the below if clauses
        perReportFee.push(defaultFee?.['perReportFee']); // add perReportFee fixed rate to its array
        wlDevelopmentFee.push(defaultFee?.['wlDevelopmentFee']); // add wlDevelopmentFee fixed rate to its array
        licenseFee.push(defaultFee?.['licenseFee']); // add licenseFee fixed rate to its array
      }
    }

    // Handle the radio changes for ambassadorFee, wlDevelopmentFee and licenseFee values
    if (ambassadorFee.concat(wlDevelopmentFee).concat(licenseFee).includes(value) && type === 'radio') {
      // Show the input to enter the custom price
      if (value === 'custom') setShowCustomInput({ ...showCustomInput, [name]: true });
      // Show the input to enter the custom price
      else setShowCustomInput((prevState) => ({ ...prevState, [name]: false }));

      newEditedState[`${name}Val`] = value.includes('custom') ? '' : value;
    }

    // Handle the radio changes for perReportFee values
    if (perReportFee.includes(value) && type === 'radio' && name === 'perReportFee') {
      if (value !== 'customPrice') {
        // If the custom input is showing, close it as we don't need it
        if (showCustomInput) setShowCustomInput({ ...showCustomInput, [name]: false });
        // Show the modal to display the pricing bands
        if (value === 'customRangePrice') setShowRangesModal(true);
      } else {
        setShowCustomInput({ ...showCustomInput, [name]: true }); // Show the input to enter the custom price
      }

      // If fixed 200 or default fixed rate is selected, set the value to its value otherwise reset the value so user can type in the new value
      newEditedState[`${name}Val`] = ['200', defaultFee?.['perReportFee']].includes(value) ? value : '';
    }

    // billDevFeeOnce is a checkbox, so we need to set the value to true or false
    newEditedState[name] = name === 'billDevFeeOnce' ? checked : value;
    setEditedState(newEditedState);
  };

  const handleSave = async (contract) => {
    setLoading(true);
    try {
      const contractFields = { ...contract, type: accountType };
      await schema.validate(contractFields, { abortEarly: false });
      // Send fields and history as params, so if contracts are successfuly saved, we can redirect to the contract list page
      await saveContract(contractFields);
      await getContract(id); // to refresh  the page
      setEdit(false);
      setLoading(false);
      setError([]);
    } catch (error) {
      if (error.inner) {
        // If here, it means there are validation errors
        setError(error.inner);
      } else console.log('Error', error);
      setLoading(false);
    }
  };

  return (
    <>
      <Breadcrumb items={breadCrumbs} />
      <div className='row'>
        <div className='col-lg-12'>
          <section className='panel'>
            <aside className='profile-nav alt'>
              <section className='panel'>
                <header
                  className='panel-heading'
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  {`Account Type ${(contract && `- ${contract?.type}`) || ''}`}
                  {!doNotShowBtns && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {contract && !edit ? (
                        <>
                          <AddButton
                            className='btn btn-success'
                            style={{ display: showExecuteButton ? 'flex' : 'none' }}
                            onClick={async () => {
                              if (executingContract) return;
                              else {
                                toggleModal(true, 'confirm-contract-execution', contract);
                              }
                            }}>
                            {executingContract ? (
                              <i className='fa fa-spin fa-spinner' style={{ marginRight: 10 }} />
                            ) : (
                              <DoneOutline sx={{ marginRight: 0.5 }} />
                            )}
                            {contract.status === 'Pending Internal Approval' ? 'Approve' : 'Execute'}
                          </AddButton>
                          {showMigrateBtn && (
                            <AddButton
                              onClick={async () => {
                                toggleModal(true, 'confirm-migrate-to-whiteLabel', contract);
                              }}>
                              {executingContract ? (
                                <i className='fa fa-spin fa-spinner' style={{ marginRight: 10 }} />
                              ) : (
                                <i className='fa-solid fa-down-left-and-up-right-to-center' style={{ marginRight: 10 }} />
                              )}
                              Migrate to White Label
                            </AddButton>
                          )}
                          {showRecallButton && (
                            <>
                              <Tooltip
                                title={
                                  <p style={{ fontSize: 12 }}>
                                    {' '}
                                    Clicking the recall button allows you to edit contract information prior to client
                                    signature. Edits you make will be reflected in the same link you generated when you
                                    initially executed the document.
                                  </p>
                                }>
                                <i className='fas fa-info-circle' style={{ marginLeft: 10 }} />
                              </Tooltip>
                              <AddButton
                                className='btn btn-success'
                                style={{ display: 'flex', marginLeft: 20, alignItems: 'center' }}
                                onClick={() => setShowDocument((prev) => ({ ...prev, show: true, recall: true }))}>
                                <i className='fa fa-pencil' style={{ fontSize: 15, marginRight: 10 }} />
                                Recall Executed Contract
                              </AddButton>
                            </>
                          )}
                          {showEditButton && (
                            <i
                              className='fa fa-pencil'
                              style={{ marginLeft: 40, fontSize: 30 }}
                              onClick={() => setEdit(true)}
                            />
                          )}
                        </>
                      ) : (
                        <i
                          className={loading ? 'fa fa-spin fa-spinner' : 'far fa-save'}
                          style={{ marginLeft: 20, fontSize: 30 }}
                          onClick={() => (loading ? null : handleSave(editedState))}
                        />
                      )}
                    </div>
                  )}
                </header>
                {loader && <LinearProgress />}
                <ul className='nav nav-pills nav-stacked'>
                  {options &&
                    Object.keys(options)
                      .filter((o) => !options[o]?.exclusiveFor || options[o]?.exclusiveFor.includes(accountType))
                      .map((key, index) => {
                        const { expectedDeliveryDate, expectedStartDate, billingCurrency } = editedState || {};
                        const type = options[key].type;
                        const styles = options[key].style;
                        let formatValue = '';
                        if (!edit) {
                          // Format Data to show it properly on viewing Mode
                          if (typeof editedState?.[key] == 'string') {
                            if (key === 'customerRegisteredCountry') {
                              // Country is saved as an Id, find the id and show the country name
                              formatValue = _.find(options[key].options, { value: editedState?.[key] })?.label;
                            } else if (editedState?.[key] === 'customRangePrice') {
                              // Show Pricing Band Name
                              formatValue = _.find(pricingBands, { id: editedState?.['perReportFeeVal'] })?.name;
                            } else if (!options[key].doNotFormat) {
                              // Remove the camel case and capitalize first letter
                              formatValue = removeCamelCase(editedState?.[key]);
                              formatValue = formatValue.charAt(0).toUpperCase() + formatValue.slice(1);
                            }
                          } else if (typeof editedState?.[key] == 'boolean') {
                            // Show Yes/No instead of true/false
                            formatValue = editedState?.[key] ? 'Yes' : 'No';
                          }
                        }
                        return (
                          <li key={index}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '10px 15px',
                              }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                {typeof options[key].icon == 'string' ? (
                                  // Fontawesome icons
                                  <i className={options[key].icon} style={{ paddingRight: 20 }} />
                                ) : (
                                  // MUI icons
                                  options[key].icon
                                )}
                                {options[key].title}
                                {options[key].tooltipText && (
                                  // In case options has some tooltip to show
                                  <Tooltip title={<p style={{ fontSize: 12 }}>{options[key].tooltipText}</p>}>
                                    <i className='fas fa-info-circle' style={{ marginLeft: 10 }} />
                                  </Tooltip>
                                )}
                              </div>
                              {['text', 'email', 'password'].includes(type) && (
                                <input
                                  data-lpignore='true'
                                  className='form-control'
                                  name={key}
                                  value={editedState?.[key] || ''}
                                  style={{ width: '25vw', height: 48, padding: 8 }}
                                  onChange={updateField}
                                  type={type}
                                />
                              )}
                              {type === 'select' && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Select
                                    styles={{ ...selectStyles, container: (provided) => ({ ...provided, ...styles }) }}
                                    options={options[key]?.options}
                                    isClearable={true}
                                    menuPlacement={'auto'}
                                    value={_.find(options[key]?.options, { value: editedState?.[key] }) || null}
                                    isDisabled={key.includes('expectedDeliveryDate') && !expectedStartDate ? true : false}
                                    onChange={(selected) => {
                                      const newEditedState = { ...editedState };
                                      if (!selected) newEditedState[key] = '';
                                      else if (key.includes('expectedDeliveryDate') && expectedStartDate) {
                                        const startWeek = { weekStartsOn: 6 };
                                        const addWeeksToESD = addWeeks(parseISO(expectedStartDate), selected.value);
                                        // ESD should be expected start date + selected value and should be last day of the week
                                        newEditedState[key] = format(lastDayOfWeek(addWeeksToESD, startWeek), 'yyyy-MM-dd');
                                      } else {
                                        if (!selected.value && key === 'hasPaymentSystem') {
                                          // If hasPaymentSystem is not selected, then we should reset the stripeFee
                                          if (newEditedState['stripeFee']) newEditedState['stripeFee'] = '';
                                        }
                                        if (key === 'billingCurrency') {
                                          const { value } = selected;
                                          const defaultFees = fixedRates.find(
                                            ({ type, currency }) => type === accountType && currency === value
                                          );
                                          if (defaultFees) {
                                            // Default Fee that matches accountType and value found, update the state
                                            Object.keys(defaultFees).forEach((x) => {
                                              if (['perReportFee', 'licenseFee', 'wlDevelopmentFee'].includes(x)) {
                                                newEditedState[x] = defaultFees?.[x];
                                                newEditedState[`${x}Val`] = defaultFees?.[x];
                                              }
                                            });
                                          } else {
                                            // Default Fee that matches accountType and value not found, remove it from state
                                            ['perReportFee', 'licenseFee', 'wlDevelopmentFee'].forEach((y) => {
                                              if (editedState.hasOwnProperty(y)) {
                                                delete newEditedState[y];
                                                delete newEditedState[`${y}Val`];
                                              }
                                            });
                                          }
                                        }
                                        newEditedState[key] = selected?.value;
                                      }
                                      setEditedState(newEditedState);
                                    }}
                                  />
                                  {key.includes('expectedDeliveryDate') && expectedStartDate && expectedDeliveryDate && (
                                    <input
                                      className='form-control'
                                      name={key}
                                      value={format(parseISO(expectedDeliveryDate), 'dd/MM/yyyy') || ''}
                                      style={{ width: '10vw', height: 48, marginLeft: 8 }}
                                      disabled
                                    />
                                  )}
                                  {key.includes('hasPaymentSystem') && editedState[key] && (
                                    <input
                                      type='number'
                                      className='form-control'
                                      name={'stripeFee'}
                                      onChange={updateField}
                                      value={editedState['stripeFee'] || ''}
                                      style={{ width: '10vw', height: 48, marginLeft: 8 }}
                                    />
                                  )}
                                </div>
                              )}
                              {type === 'toggle' && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <RadioGroup row name={key} value={editedState?.[key] || ''} onChange={updateField}>
                                    {options[key]?.options?.map((opt, index) => {
                                      let label = opt?.title || opt;
                                      let value = opt?.value || opt;
                                      if (label === 'Custom Range & Price' && editedState?.['perReportFeeVal']) {
                                        // When selected 'Custom Range & Price' show the pricing band name
                                        const pricingBandName = _.find(pricingBands, {
                                          id: editedState?.['perReportFeeVal'],
                                        })?.name;
                                        label = pricingBandName || 'Custom Range & Price';
                                      }
                                      const defaultFees = _.find(
                                        fixedRates,
                                        (x) => x.currency === billingCurrency && x.type === accountType
                                      );
                                      if (defaultFees?.[opt.defaultValue]) {
                                        label = `${billingCurrency} ${defaultFees?.[opt.defaultValue]}`;
                                        value = defaultFees?.[opt.defaultValue];
                                      }
                                      return (
                                        <div key={index}>
                                          <FormControlLabel
                                            style={{ marginTop: '0.75vh' }}
                                            control={<Radio />}
                                            value={_.camelCase(value)}
                                            label={label}
                                          />
                                        </div>
                                      );
                                    })}
                                  </RadioGroup>
                                  {showCustomInput && (showCustomInput[key] ? true : false) && (
                                    <input
                                      className='form-control'
                                      name={`${key}Val`}
                                      type={'number'}
                                      value={editedState?.[`${key}Val`] || ''}
                                      onChange={updateField}
                                      style={{ width: '10vw', height: 48, marginLeft: 8 }}
                                    />
                                  )}
                                </div>
                              )}
                              {type === 'date' && (
                                <label
                                  className='input-group date datepicker'
                                  style={{ fontWeight: 'normal', marginLeft: 10, width: '25vw' }}>
                                  <DatePicker
                                    dateFormat='dd/MM/yyyy'
                                    placeholderText={options[key].title}
                                    className='form-control new-contract-start-date'
                                    selected={
                                      editedState?.[`${_.camelCase(options[key].title)}`]
                                        ? parseISO(editedState?.[`${_.camelCase(options[key].title)}`])
                                        : null
                                    }
                                    disabled={accountType !== 'White Label' ? true : false}
                                    isClearable={true}
                                    onChange={(date) => {
                                      if (!date) {
                                        setEditedState((prevState) => ({ ...prevState, [key]: '' }));
                                        if (expectedDeliveryDate && key.includes('expectedStartDate')) {
                                          // Reset the expected delivery date, when clearing ESD
                                          setEditedState((prevState) => ({ ...prevState, expectedDeliveryDate: '' }));
                                        }
                                      } else
                                        setEditedState((prevState) => ({
                                          ...prevState,
                                          [key]: format(date, 'yyyy-MM-dd'),
                                        }));
                                    }}
                                  />
                                  <span className='input-group-btn'>
                                    <CalendarBtn className='btn btn-primary date-set' style={{ height: 48 }}>
                                      <i className='fa fa-calendar' style={{ position: 'relative', top: 6 }} />
                                    </CalendarBtn>
                                  </span>
                                </label>
                              )}
                              {type === 'checkbox' && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Checkbox
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                    onChange={updateField}
                                    checked={editedState?.[key] || false}
                                    name={key}
                                  />
                                </div>
                              )}
                              {type === 'span' && (
                                // For Viewing Mode
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <span>{formatValue || editedState?.[key]}</span>
                                </div>
                              )}
                              {type === 'switch' && (
                                // For Viewing Mode
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Switch
                                    onClick={() => setShowDocument((prev) => ({ ...prev, show: true, recall: false }))}
                                    checked={showDocument?.show}
                                  />
                                </div>
                              )}
                            </div>
                            {error.length > 0 && (
                              <ValidationMessage
                                message={_.find(error, { path: key })?.message}
                                style={{ fontSize: 12 }}
                                wrapperStyle={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}
                              />
                            )}
                          </li>
                        );
                      })}
                </ul>
              </section>
            </aside>
          </section>
        </div>
        {showRangesModal && (
          <PricingBandModal
            selectPriceBand={showRangesModal}
            showModal={showRangesModal}
            setShowModal={setShowRangesModal}
            editedState={editedState}
            setEditedState={setEditedState}
          />
        )}
        {showDocument.show && (
          <ContractPreview contract={contract} setShow={setShowDocument} showDoc={showDocument} setEdit={setEdit} />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { countries } = state?.organisation || {};
  const { pricingBands, fixedRates } = state.admin?.adminPortal || {};
  const { contractApprover, ambassadorCreator } = state.profile?.details || {};
  const { contracts: contract, loader } = state.admin.contracts || {};
  const adminOrgs = state.admin?.organisations;
  const deliveryWeeks = [];
  for (let i = 5; i <= 12; i++) deliveryWeeks.push({ value: i, label: `${i} weeks` });

  let showMigrateBtn = false;
  const whiteLabelContract = contract?.type === 'White Label';
  const completedContract = contract?.status === 'complete';
  const notWhiteLabelOrg = _.find(adminOrgs, { id: contract?.organisation })?.accountType !== 'White Label';

  if (whiteLabelContract && completedContract && notWhiteLabelOrg) {
    showMigrateBtn = true;
  }

  const billingCurrencyOptions = [
    { id: '£', name: '£' },
    { id: '$', name: '$' },
    { id: '€', name: '€' },
    { id: 'AUD', name: 'AUD' },
  ].map(itemToOptions);

  const countryOptions = _.sortBy(
    countries?.map((item) => ({ label: item.name, code: item.code, value: item.id })),
    ['label']
  );

  const contractOptions = {};
  contractOptions.customerFirstName = { type: 'text', icon: 'fa fa-user', title: 'Customer First Name' };
  contractOptions.customerLastName = { type: 'text', icon: 'fa fa-user', title: 'Customer Last Name' };
  contractOptions.customerTitle = { type: 'text', icon: 'fa fa-user', title: 'Customer Title' };
  contractOptions.customerOrganisationName = {
    type: 'text',
    title: 'Customer Organisation Name',
    icon: 'fa fa-cogs',
    doNotFormat: true,
  };
  contractOptions.customerBillingName = {
    type: 'text',
    title: 'Customer Company Billing Name',
    icon: 'fa fa-wallet',
    doNotFormat: true,
  };
  contractOptions.customerEmail = { type: 'email', title: 'Customer Email', icon: 'fa fa-at', doNotFormat: true };
  contractOptions.customerRegisteredCountry = {
    type: 'select',
    title: 'Customer Registered Country',
    options: countryOptions,
    icon: 'fa fa-globe-europe',
    style: { width: '25vw' },
  };
  contractOptions.customerRegisteredAddress = {
    type: 'text',
    title: 'Customer Registered Address',
    icon: 'fa fa-address-card',
  };
  contractOptions.customerCompanyNumber = { type: 'text', title: 'Customer Company Number', icon: 'fa fa-registered' };
  contractOptions.billingCurrency = {
    type: 'select',
    title: 'Billing Currency',
    options: billingCurrencyOptions,
    icon: 'fa fa-coins',
    exclusiveFor: ['White Label', 'Consultant'],
    style: { width: '25vw' },
  };
  contractOptions.billingType = {
    type: 'toggle',
    title: 'Billing Type',
    options: ['Monthly', 'Instant Billing'],
    exclusiveFor: ['White Label', 'Consultant'],
    icon: 'fa fa-calendar',
  };
  contractOptions.expectedStartDate = {
    type: 'date',
    title: 'Expected Start Date',
    icon: 'fa fa-calendar',
    tooltipText: 'This field is automatically populated when the customer signs the contract.',
  };
  contractOptions.expectedDeliveryDate = {
    type: 'select',
    title: 'Expected Delivery Date',
    icon: 'fa fa-calendar',
    exclusiveFor: ['White Label'],
    options: deliveryWeeks,
    style: { width: '15vw' },
  };
  contractOptions.perReportFee = {
    type: 'toggle',
    title: 'Report Fee',
    icon: <Assessment sx={{ marginRight: '20px' }} />,
    exclusiveFor: ['White Label', 'Consultant'],
    options: [
      { title: <i className='fa fa-spin fa-spinner' />, value: '', defaultValue: 'perReportFee' },
      { title: 'Custom Range & Price', value: 'Custom Range & Price' },
      { title: 'Custom Price', value: 'Custom Price' },
    ],
    style: { width: '15vw' },
  };
  contractOptions.ambassadorCommissionRate = {
    type: 'toggle',
    title: 'Ambassador Commission Rate',
    icon: 'fa fa-credit-card',
    exclusiveFor: ['Ambassador'],
    options: [
      { title: '10%', value: '10' },
      { title: '20%', value: '20' },
      { title: 'Custom', value: 'custom' },
    ],
    style: { width: '15vw' },
  };
  contractOptions.wlDevelopmentFee = {
    type: 'toggle',
    title: 'White Label Development Fee',
    icon: 'fa fa-credit-card',
    exclusiveFor: ['White Label'],
    options: [
      { title: <i className='fa fa-spin fa-spinner' />, value: '', defaultValue: 'wlDevelopmentFee' },
      { title: '£ 3000', value: '3000' },
      { title: 'Custom', value: 'custom' },
    ],
    style: { width: '15vw' },
  };
  contractOptions.billDevFeeOnce = {
    type: 'checkbox',
    title: 'Do Not Split Invoice',
    icon: 'fa fa-credit-card',
    exclusiveFor: ['White Label'],
    tooltipText:
      'Default is to bill 50% at Expected Start Date, 2nd 50% at Delivery date. Check this box to bill the whole amount at start date.',
  };
  contractOptions.licenseFee = {
    type: 'toggle',
    title: 'License Fee',
    icon: 'fa fa-credit-card',
    exclusiveFor: ['White Label', 'Consultant'],
    options: [
      { title: <i className='fa fa-spin fa-spinner' />, value: '', defaultValue: 'licenseFee' },
      { title: 'Custom', value: 'custom' },
    ],
    style: { width: '15vw' },
  };

  contractOptions.hasPaymentSystem = {
    type: 'select',
    title: 'Has Payment Sytem',
    icon: 'fa fa-shopping-cart',
    exclusiveFor: ['White Label'],
    options: ['Yes', 'No'].map((x, i) => ({ label: x, value: i ? false : true })),
    style: { width: '15vw' },
  };

  // Convert all contractOptions types to span, for viewing mode
  // Get Object entries, it gives an array use reduce to convert it on a new object
  let viewContract = Object.entries(contractOptions).reduce((obj, [key, value]) => {
    obj[key] = { ...value, type: 'span' };
    return obj;
  }, {});

  viewContract.previewDocument = { type: 'switch', title: 'Preview Document', icon: 'fa fa-eye' };

  return {
    editContract: contractOptions,
    viewContract,
    currentOrg: state.currentOrganisation,
    loader,
    contract,
    branding: state?.branding,
    pricingBands,
    fixedRates,
    accountTypeOptions: [
      { label: 'White Label', value: 'White Label' },
      { label: 'Consultant', value: 'Consultant' },
      { label: 'Ambassador', value: 'Ambassador' },
    ],
    contractApprover,
    ambassadorCreator,
    showMigrateBtn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
  saveContract: (contract, history) => dispatch(ADMINACTIONS.saveContract(contract, history)),
  getContract: (id) => dispatch(ADMINACTIONS.getContract(id)),
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
  executeContract: (contract) => dispatch(ADMINACTIONS.executeContract(contract)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
