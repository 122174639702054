import React from 'react';

const ValidationMessage = ({ message, color, style, wrapperStyle }) => {
  return (
    <div className='validation-message' style={{ display: message ? 'block' : 'none', ...wrapperStyle }}>
      <p
        style={{
          textAlign: 'left',
          color: color ? color : 'red',
          fontSize: '11px',
          fontWeight: 700,
          marginTop: '4px',
          ...style,
        }}>
        {message}
      </p>
    </div>
  );
};

export default ValidationMessage;
