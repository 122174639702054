import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ACTIONS from '../../store/action';
import Select from 'react-select';
import Explainer from './Explainer';
import LinearProgress from '@mui/material/LinearProgress';
import _ from 'lodash';
import { xeroAccountCodeSelectStyle } from '../utils/ReactSelectStyles';

const AccountCodes = ({ getAccountCodes, accounts, selectedAccounts, setAccountCode, method, tenant, entities }) => {
  useEffect(() => {
    if (method === 'accountcodes') {
      getAccountCodes();
    }
  }, [method, tenant, getAccountCodes]);

  const handleAccountCodeChange = (name, selectedOption, e) => {
    setAccountCode(name, selectedOption, e?.removedValue);
  };

  return [
    accounts && entities ? (
      entities.map((item) => (
        <li id={`spendEntity-${item.id}`} key={item.id} style={{ padding: '10px 15px' }}>
          Select your{' '}
          <Explainer id={`spendEntity-${item.id}`} description={item.description}>
            {item.name}{' '}
          </Explainer>{' '}
          Nominal Code
          {(!selectedAccounts || !selectedAccounts[item.id]) && (
            <span className='btn btn-success pull-right'>
              <i className='fa fa-plus' />
            </span>
          )}
          <span
            className='pull-right'
            style={{
              marginRight: selectedAccounts?.[item.id] ? '47px' : '10px',
              lineHeight: '0',
            }}>
            <Select
              className='spendEntitySelect'
              id={`spendEntity-${item.id}-select`}
              isMulti={true}
              value={selectedAccounts && selectedAccounts[item.id]}
              onChange={(value, e) => {
                handleAccountCodeChange(item.id, value, e);
              }}
              options={accounts}
              styles={xeroAccountCodeSelectStyle}
              isClearable={true}
              isSearchable={true}
            />
          </span>
        </li>
      ))
    ) : (
      <LinearProgress color='inherit' />
    ),
  ];
};

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant,
    method: state.method,
    accounts: _.sortBy(state.accounts, ['label']),
    selectedAccounts: state.selectedAccounts,
    entities: _.sortBy(state.spendEntities, ['name']),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAccountCodes: () => dispatch(ACTIONS.getAccountCodes()),
  setAccountCode: (account, option, removedValue) => dispatch(ACTIONS.setAccountCode(account, option, removedValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountCodes);
