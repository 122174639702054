import React from 'react';
import { connect } from 'react-redux';
import { Actions as FACILITYACTIONS } from '../../store/actions/facilityActions';
import { UploadFileBtn, StartImportBtn } from '../../styledComponents/GlobalStyle';

const Modal = ({ facilities, facility, showFacilityModal, facilityDataModal, onConfirm, setEditing }) => {
  const primaryFacility = facilities?.find((item) => item.isPrimary === 'yes')?.name;

  if (!facilityDataModal.status) return null;

  if (facilityDataModal.status) {
    return (
      <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
        <div className='modal-dialog' style={{ height: '90vh', overflow: 'auto', fontSize: '12px' }}>
          <div className='modal-content'>
            <div className='modal-header'>
              <button onClick={() => showFacilityModal(false)} className='close'>
                x
              </button>
              <h4 className='modal-title'>Primary Facility</h4>
            </div>
            <div className='modal-body'>
              <div className='form-group'>
                <h5 style={{ lineHeight: '25px', fontSize: '1.5em' }}>
                  <strong>{primaryFacility}</strong> is already assigned as your PrimaryFacility, do you want to change it
                  for <strong>{facility ? facility.name : 'this facility'}</strong>?
                </h5>
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div>
                  <UploadFileBtn
                    className='btn btn-success'
                    onClick={() => {
                      onConfirm();
                      setEditing(false);
                      showFacilityModal(false);
                    }}>
                    Yes
                  </UploadFileBtn>
                </div>
                <div>
                  <StartImportBtn
                    style={{ marginLeft: '2em' }}
                    className='btn btn-success'
                    onClick={() => showFacilityModal(false)}>
                    No
                  </StartImportBtn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    facilityDataModal: state.facilityDataModal,
    facilities: state.facility?.list,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showFacilityModal: (status) => dispatch(FACILITYACTIONS.showFacilityModal(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
