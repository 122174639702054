import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import cleanDeep from 'clean-deep';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Actions as ADMINACTIONS } from '../../store/actions/adminActions';
import { AddButton, DownloadCSVExcel } from '../../styledComponents/GlobalStyle.js';
import Breadcrumb from '../Common/Breadcrumb';
import { convertToTitleCase } from '../utils/GlobalFunctions';
import AdminPortalListTable from './AdminPortalListTable';
import { getTableDetails } from './utils/adminPortalHelpers.js';

const AdminPortalList = ({ toggleModal, tableOptions, profile, getAdminReports, canEditAdminPortal, stateVariables }) => {
  const [loading, setLoading] = useState(false);
  const { table, actions } = tableOptions || {};
  const items = [{ label: convertToTitleCase(table), link: `/admin/${table}` }];

  // Table Options are not loaded yet, show Loader
  if (!Object.keys(cleanDeep(tableOptions)).length) {
    return <LinearProgress color='inherit' />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Breadcrumb items={items} />
      </Box>

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', marginBottom: 10 }}>
        {actions?.map((action, index) => {
          let optionType = null;
          if (table === 'usageTypes') {
            // Need to get the type from the url path, for this specific table
            optionType = {};
            optionType.type = window.location.pathname.split('/')[2].replace('Type', '');
          }
          return (
            <div key={index}>
              {action === 'downloadFile' && (
                <DownloadCSVExcel
                  style={{ marginRight: '10px' }}
                  onClick={async () => {
                    setLoading(true);
                    await getAdminReports(table, table);
                    setLoading(false);
                  }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <i className={loading ? 'fa fa-spin fa-spinner' : 'fa fa-file-excel-o'} />
                    <div style={{ paddingLeft: 10 }}>Download</div>
                  </div>
                </DownloadCSVExcel>
              )}
              {action === 'add' && (
                <AddButton
                  onClick={() => {
                    const action = optionType ? `${optionType.type}` : table;
                    const defaultState = tableOptions?.defaultStateValue || null; // In case the table needs a default state when adding new items
                    toggleModal(true, `${action}-table-add`, optionType || defaultState, false);
                  }}
                  style={{ display: canEditAdminPortal ? 'block' : 'none' }}>
                  Add an Entry
                </AddButton>
              )}
            </div>
          );
        })}
      </div>

      <AdminPortalListTable tableDetails={tableOptions} profile={profile} stateVariables={stateVariables} />
    </>
  );
};

const mapStateToProps = (state) => {
  // Get tableName from the url path -> path must match the table name in DynamoDb
  const table = window.location.pathname?.split('/')[2];
  const profile = state.profile?.details;

  // State variabales that are used to show the data in the table
  const stateVariables = {
    adminOrgs: state?.admin?.organisations,
    metric: state?.metric?.list,
    scope_3_cat: state?.metric?.scope3Categories,
    industries: state?.organisation?.industries,
    entities: state.entities,
    subentities: state.subentities,
    facilityTypes: state.facility?.types,
    countries: state.organisation.countries,
    ...state.admin.adminPortal,
  };

  state?.metric?.types &&
    Object.keys(state?.metric?.types).forEach((type) => (stateVariables[type] = state?.metric?.types[type]));

  // Load the table once the state variables are loaded
  const tableDetails = Object.keys(cleanDeep(stateVariables)).length > 8 && getTableDetails(table, profile, stateVariables);
  return {
    tableOptions: { table: tableDetails?.tableName, ...tableDetails },
    canEditAdminPortal: profile?.canEditAdminPortal,
    stateVariables,
    profile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleModal: (status, action, item, notAutoClose) =>
    dispatch(ADMINACTIONS.toggleModal(status, action, item, notAutoClose)),
  getAdminReports: (tableName, fileType) => dispatch(ADMINACTIONS.getAdminReports(tableName, fileType)),
  getAdminReportsStepFunctionStatus: (importArn) => dispatch(ADMINACTIONS.getAdminReportsStepFunctionStatus(importArn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPortalList);
