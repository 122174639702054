import { Types } from '../actions/metricActions';
import _ from 'lodash';

const defaultState = {
  my: {},
};

const metricReducers = (state = defaultState, action) => {
  switch (action.type) {
    case Types.GET: {
      let newState = _.cloneDeep(state);

      newState.metric = state.metric ? { ...state.metric } : {};
      newState.metric.list = _.sortBy(action.payload, ['name']);

      return newState;
    }
    case Types.GET_TYPES: {
      let newState = _.cloneDeep(state);

      newState.metric = state.metric ? { ...state.metric } : {};
      newState.metric.types = action.payload;

      return newState;
    }
    case Types.LIST_SCOPE3_CATEGORIES: {
      let newState = _.cloneDeep(state);

      newState.metric = state.metric ? { ...state.metric } : {};
      newState.metric.scope3Categories = _.sortBy(action.payload, ['name']);

      return newState;
    }

    default:
      return state;
  }
};

export default metricReducers;
