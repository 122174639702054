import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import * as yup from 'yup';
import { Actions as ORGANISATION_ACTIONS } from '../../store/actions/organisationActions';
import { Actions as SIDEBARACTIONS } from '../../store/actions/sidebarActions';
import LogoSide from '../Register/LogoSide';
import OrderForm from '../Register/OrderForm';
import { paymentMethodSelectStyles } from '../utils/ReactSelectStyles';
import ValidationMessage from '../utils/ValidationMessage';
import SubscribingUser from './SubscribingUser';

const paymentMethodOptions = [
  //{ label: 'SEPA direct debit', value: 'sepa_debit' },
  { label: 'BACS direct debit', value: 'bacs_debit' },
  { label: 'Pay with card', value: 'card' },
];

const validationSchema = yup.object().shape({
  orderFormAccepted: yup.boolean().oneOf([true], 'Accepting the Order Form is required').required(),
});

const ChoosePaymentMethods = ({
  branding,
  setShowPaymentModal,
  subscriptionError,
  toggleModal,
  renewOrgSub,
  isWhiteLabelApp,
  wlAdmin,
  stripeKey,
}) => {
  const { signInFormBg, primaryHeaderText, primaryHeaderBackground } = branding.colors;

  let stripePromise;

  const [orderFormAccepted, setOrderFormAccepted] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(paymentMethodOptions[1]);
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);

  if (isWhiteLabelApp && wlAdmin) {
    //For white label payments, load stripe and pass wlAdmin accountId
    stripePromise = loadStripe(stripeKey, { stripeAccount: wlAdmin.stripeAccountId });
  } else {
    stripePromise = loadStripe(stripeKey);
  }

  return (
    <div className='modal' style={{ display: 'flex', background: 'rgba(0,0,0,0.8)', overflow: 'auto' }}>
      <div
        className='login-body'
        style={{ height: '100vh', width: '100%', display: 'flex', background: renewOrgSub && 'transparent' }}>
        <div
          className='close-modal-btn close'
          style={{ display: renewOrgSub ? 'block' : 'none' }}
          onClick={() => {
            setShowPaymentModal(false);
            toggleModal(false, null);
          }}>
          <i className='fa fa-times' />
        </div>
        {!renewOrgSub && <LogoSide />}
        <div
          className={renewOrgSub ? '' : 'signInSide'}
          style={{ background: renewOrgSub ? 'transparent' : signInFormBg, width: renewOrgSub && '100%' }}>
          <div
            className={renewOrgSub ? 'form-signin' : 'signInFields'}
            style={{ width: '35vw', margin: renewOrgSub && '70px auto', maxWidth: '600px' }}>
            {renewOrgSub ? (
              <h2
                className='form-signin-heading'
                style={{ background: primaryHeaderBackground, fontSize: '2em', fontWeight: '700' }}>
                Payment
              </h2>
            ) : (
              <h1 style={{ marginBottom: '1.5vh', color: primaryHeaderText }}>Payment</h1>
            )}

            <div style={{ display: subscriptionError ? 'block' : 'none' }} className='col-xs-12 alert-danger alert'>
              <h5 style={{ textAlign: 'center', fontSize: 12 }}>Error: {subscriptionError}</h5>
              <h5 style={{ textAlign: 'center', fontSize: 12 }}>
                Please contact <a href={`mailto:${branding.email}`}>{wlAdmin.supportEmail}.</a>
              </h5>
            </div>

            <div className='signUp-orderForm'>
              <OrderForm />
            </div>

            <div
              className={renewOrgSub && 'login-wrap'}
              style={{ display: 'flex', flexDirection: 'column', fontSize: '12px', color: '#333', marginTop: '1.5vh' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  id='accept-orderForm'
                  type='checkbox'
                  style={{ marginBottom: 10 }}
                  onChange={() => setOrderFormAccepted(!orderFormAccepted)}
                />
                <label style={{ fontWeight: 400, marginBottom: 7, marginLeft: 5 }}>
                  By clicking you are accepting to our Order Form.
                </label>
              </div>
              <ValidationMessage
                message={validationError && _.find(validationError, { path: 'orderFormAccepted' })?.message}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: `${renewOrgSub ? '0px' : '20px'} 15px`,
              }}>
              <h6 style={{ color: primaryHeaderText, textAlign: 'center', marginBottom: 15, fontWeight: 'bold' }}>
                Choose your payment method:
              </h6>
              <Select
                styles={paymentMethodSelectStyles}
                options={paymentMethodOptions}
                placeholder='Choose  your payment method'
                isSearchable={false}
                value={paymentMethod}
                onChange={(paymentMethod) => setPaymentMethod(paymentMethod)}
              />
            </div>

            {stripePromise && (
              <div>
                <Elements stripe={stripePromise}>
                  <SubscribingUser
                    orderFormAccepted={orderFormAccepted}
                    validationSchema={validationSchema}
                    setValidationError={setValidationError}
                    paymentMethod={paymentMethod}
                    setPaymentMethod={setPaymentMethod}
                    setShowPaymentMethods={setShowPaymentMethods}
                    showPaymentMethods={showPaymentMethods}
                    renewOrgSub={renewOrgSub}
                  />
                </Elements>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    branding: state.branding,
    stripeKey: state.payment?.stripe?.stripeKey,
    subscriptionError: state.payment?.stripe?.intent?.subscription?.message || state.payment?.stripe?.intent?.message,
    whiteLabelAdminOrgs: state.admin.whiteLabelAdminOrgs,
    isWhiteLabelApp: state.isWhiteLabelApp,
    wlAdmin: state.whiteLabelAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showBillingDetails: (status) => dispatch(SIDEBARACTIONS.showBillingDetails(status)),
    toggleModal: (status, item) => dispatch(ORGANISATION_ACTIONS.toggleModal(status, item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePaymentMethods);
